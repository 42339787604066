import { UserDTO } from "../../../../../../types/dtos/user";
import * as _ from "lodash";
import { PaymentMethod } from "../../../../../../types/enums/payment-method";

export const initialUser: UserDTO = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    oldPassword: "",
    employeeId: "",
    paymentMethod: PaymentMethod.Unknown,
    id: "",
    creationDate: "0001-01-01",
    modificationDate: "0001-01-01",
    hasSeenKitchenClosedModal: false,
};

export function getInitialUser() {
    return _.cloneDeep(initialUser);
}

export interface UserActionReducer {
    type: "serverChanged" | "userChanged";
    target: string;
    payload: any;
}

export const reducer = (state: UserDTO, action: UserActionReducer) => {
    switch (action.type) {
        case "userChanged":
            return {
                ...state,
                [action.target]: action.payload,
            };
        case "serverChanged":
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
