import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import Paragraph from "../../../../components/paragraph";
import Title from "../../../../components/title";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import useQueryString from "../../../../hooks/useQueryString";
import ResetPasswordService from "../../../../services/ResetPasswordService";
import { ResetPasswordDTO } from "../../../../types/dtos/resetPassword";
import { errorMapper } from "../../../../mappers/message-errors";
import { ErrorType } from "../../../../types/enums/error-type";

function NewPassword() {
    // Attributes
    const history = useHistory();
    const query = useQueryString();
    const { translate } = useTranslation();
    const [newPassword, setNewPassword] = useState("");

    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [disabled, setDisabled] = useState(false);

    const email = query.get("email");
    const token = query.get("token");

    // Functions
    async function handleResetPassword() {
        // Validate inputs
        if (!email || !token || !newPassword || !newPasswordConfirmation) {
            errorMapper(
                ErrorType.RESET_PASSWORD,
                "missingInfoResetPasswordError"
            );
            return;
        }

        if (newPassword !== newPasswordConfirmation) {
            errorMapper(
                ErrorType.RESET_PASSWORD,
                "passwordMatchingSignUpError"
            );
            return;
        }

        setDisabled(true);
        const resetPasswordDTO: ResetPasswordDTO = {
            email,
            token,
            newPassword,
            newPasswordConfirmation,
        };
        const response = await ResetPasswordService.post(
            resetPasswordDTO
        ).catch(() => {
            errorMapper(
                ErrorType.RESET_PASSWORD,
                "missingInfoResetPasswordError"
            );
            setDisabled(false);
            return;
        });

        if (response as any) {
            history.replace(`/reset-password/success`);
        }
        setDisabled(false);
    }

    function handleKeyDown(key: any) {
        if (key.key === "Enter" && newPassword && newPasswordConfirmation) {
            handleResetPassword();
        }
    }

    // Render
    return (
        <>
            <div>
                <Title className="text-3xl! mb-2!">ResetYourPassword</Title>
                <Paragraph className="text-4">
                    {translate("ResetYourPasswordDescription")}
                </Paragraph>
            </div>

            <div className="mt-4">
                <div className="my-6">
                    <div className="mb-4">
                        <Input
                            placeholder={translate("InsertNewPassword")}
                            type={"password"}
                            value={newPassword}
                            label={translate("NewPassword")}
                            onChange={setNewPassword}
                            onKeyDown={(key) => handleKeyDown(key)}
                        />
                    </div>
                    <Input
                        placeholder={translate("ConfirmNewPassword")}
                        type={"password"}
                        value={newPasswordConfirmation}
                        label={translate("ConfirmNewPassword")}
                        onChange={setNewPasswordConfirmation}
                        onKeyDown={(key) => handleKeyDown(key)}
                    />
                </div>
            </div>

            <div
                onClick={handleResetPassword}
                className="flex justify-start mt-12"
            >
                <Button disabled={disabled} className="min-w-full">
                    Confirm
                </Button>
            </div>
        </>
    );
}

export default NewPassword;
