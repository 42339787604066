import React from "react";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useParams } from "react-router";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { Capacitor } from "@capacitor/core";
import useMedia from "../../../../../../hooks/useMedia";

function CompanyHeader() {
    //Attributes
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: company } = useOneCompanyQuery(companyName);
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );
    const plat = Capacitor.platform;

    //Render
    return (
        <div
            style={{ paddingTop: plat == "ios" ? 0 : 0 }}
            className="md:max-h-company-header flex justify-between md:pt-0! pb-2 items-center overflow-hidden"
        >
            <img
                src={
                    currentMediaQuery === 1
                        ? company?.logo
                        : company?.mobileLogo
                }
                style={{ width: "100%" }}
            />
        </div>
    );
}

export default CompanyHeader;
