import InputProps from "./props";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { HTMLInputTypeAttribute, useState } from "react";
import { ReactComponent as Eye } from "./assets/eye.svg";
import { ReactComponent as ClosedEye } from "./assets/closed-eye.svg";

function Input({
    label,
    onChange,
    className = "",
    isSearch = false,
    isClearable = false,
    handleClearFunction,
    labelColorClassName = "text-add-button",
    type,
    ...rest
}: InputProps) {
    const [inputType, setInputType] = useState<
        HTMLInputTypeAttribute | undefined
    >(type);

    return (
        <div className={className}>
            {label && (
                <label
                    className={`text-xs font-poppins font-semibold uppercase ${
                        labelColorClassName ?? ""
                    }`}
                >
                    {label}
                </label>
            )}
            <div className="flex relative">
                <input
                    {...rest}
                    type={inputType}
                    onChange={(event) => onChange?.(event.target.value)}
                    className={
                        `w-full h-11 font-poppins p-4 ${
                            isSearch &&
                            "bg-search-input-color md:bg-white placeholder-profile-panel-label md:placeholder-placeholder-search"
                        } rounded-lg shadow-input border font-light text-sm ${
                            isSearch
                                ? "border-transparent"
                                : "border-input-border-color"
                        } ${label && "mt-2"}` + className
                    }
                />
                {type === "password" && (
                    <>
                        {inputType === "password" ? (
                            <Eye
                                className="absolute top-0 right-0 m-4 cursor-pointer"
                                onClick={() => setInputType("text")}
                            />
                        ) : (
                            <ClosedEye
                                className="absolute top-0 right-0 m-4 cursor-pointer"
                                onClick={() => setInputType("password")}
                            />
                        )}
                    </>
                )}

                {isClearable && (
                    <div
                        onClick={handleClearFunction}
                        className="absolute cursor-pointer"
                        style={{ right: "12px", top: "18px" }}
                    >
                        <CrossIcon />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Input;
