import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import React, { createContext, useContext, useEffect, useState } from "react";

interface SafeAreaContextInterface {
    statusBarHeight: number | undefined;
    insets:
        | { top: number; bottom: number; left: number; right: number }
        | undefined;
}

const SafeAreaContext = createContext<SafeAreaContextInterface>({
    statusBarHeight: undefined,
    insets: undefined,
});

const SafeAreaContextProvider = ({ children }: any) => {
    //Attributes
    const platform = Capacitor.getPlatform();
    const SafeAreaProvider = SafeAreaContext.Provider;
    // Attributes
    const [insets, setInsets] = useState<{
        top: number;
        bottom: number;
        left: number;
        right: number;
    }>({ top: 0, bottom: 0, left: 0, right: 0 });

    const [statusBarHeight, setStatusBarHeight] = useState<
        number | undefined
    >();

    // Effects
    useEffect(() => {
        async function getStatusBarDimensions() {
            try {
                const dimensions = await SafeArea.getStatusBarHeight();
                setStatusBarHeight(dimensions.statusBarHeight);
            } catch (error) {
                console.warn(error);
            }
        }

        if (platform !== "web") {
            getStatusBarDimensions();
        }
    }, [platform]);

    useEffect(() => {
        async function getInsets() {
            try {
                const safeArea = await SafeArea.getSafeAreaInsets();
                setInsets(safeArea.insets);
            } catch (error) {
                console.warn(error);
            }
        }

        if (platform !== "web") {
            getInsets();
        }
    }, [platform]);

    return (
        <SafeAreaProvider value={{ statusBarHeight, insets }}>
            {children}
        </SafeAreaProvider>
    );
};

export function useSafeArea() {
    return useContext(SafeAreaContext);
}

export default SafeAreaContextProvider;
