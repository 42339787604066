import { useQuery } from "react-query";
import CompanyService from "../services/CompanyService";
import { QueryKey } from "../types/enums/query-key";

export function useOneCompanyQuery(companyId: string, clicked: boolean = true) {
    return useQuery(
        [QueryKey.Companies, { id: companyId }],
        () => CompanyService.get(companyId).then((res) => res.data),
        { enabled: !!companyId && clicked }
    );
}

export function useAllCompanyQuery() {
    return useQuery(
        [QueryKey.Companies],
        () => CompanyService.getAll().then((res) => res.data),
        { enabled: true }
    );
}
