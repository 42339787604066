import { PromoCodeDTO } from "../types/dtos/promo-code";
import Service from "./Service";

function post(
    companyId: string,
    employeeId: string,
    basketId: string,
    promoCodeDTO: PromoCodeDTO
) {
    return Service.api.post<PromoCodeDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/promo-codes`,
        promoCodeDTO
    );
}

function put(
    companyId: string,
    employeeId: string,
    basketId: string,
    promoCodeDTO: PromoCodeDTO
) {
    return Service.api.put<PromoCodeDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/promo-codes`,
        promoCodeDTO
    );
}

function deleteBasketPromoCode(
    companyId: string,
    employeeId: string,
    basketId: string,
    promoCodeId: string
) {
    return Service.api.delete<PromoCodeDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/promo-codes/${promoCodeId}`
    );
}

const exported = { post, deleteBasketPromoCode };

export default exported;
