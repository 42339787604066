import Button from "../../../../../../components/button";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import DishDescription from "../../../basket/components/dish-description";
import DishQuantity from "../../../basket/components/dish-quantity";
import CardOrderDetail from "./components/card-order-detail";
import OrderDetailProps from "./props";
import { ReactComponent as Arrow } from "./components/card-order-detail/assets/arrow.svg";

export default function OrderDetail({
    isReOrderDisplayed,
    order,
    handleCreateReOrder,
    priceToDisplay,
    isReOrderLoading,
}: OrderDetailProps) {
    //Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <div className="flex flex-col h-full justify-between">
            <div>
                {order &&
                    order.basketDTO.basketProducts.map((elt) => (
                        <CardOrderDetail
                            pictureUrl={elt.product?.pictureUrl!}
                            onClickDisabled
                            firstContent={
                                <DishDescription
                                    basketProduct={elt}
                                    isReOrderDisplayed={isReOrderDisplayed}
                                />
                            }
                            secondContent={
                                <DishQuantity quantity={elt.quantity} />
                            }
                        />
                    ))}
            </div>
            {handleCreateReOrder && (
                <div className="w-full">
                    <Button
                        disabled={isReOrderLoading}
                        className="w-full bg-event-banner-bg "
                        onClick={() => handleCreateReOrder(order?.id)}
                    >
                        <div className="flex justify-between items-center">
                            <p className="font-semibold font-poppins text-xs">
                                {priceToDisplay?.toFixed(2) + "€"}
                            </p>
                            <div className="flex flex-row items-center gap-5">
                                <p className="text-xs font-poppins font-semibold">
                                    {translate("confirmAndPay")}
                                </p>
                                <Arrow />
                            </div>
                        </div>
                    </Button>
                </div>
            )}
        </div>
    );
}
