import { BasketProductDTO } from "../types/dtos/basket-product";
import Service from "./Service";

function getAll(companyId: string, employeeId: string, basketId: string) {
    return Service.api.get<Array<BasketProductDTO>>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/products`
    );
}

function post(
    companyId: string,
    employeeId: string,
    basketId: string,
    basketProductDTO: BasketProductDTO
) {
    return Service.api.post<BasketProductDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/products`,
        basketProductDTO
    );
}

function put(
    companyId: string,
    employeeId: string,
    basketId: string,
    basketProductDTO: BasketProductDTO
) {
    return Service.api.put<BasketProductDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/products`,
        basketProductDTO
    );
}

function deleteBasketProduct(
    companyId: string,
    employeeId: string,
    basketId: string,
    basketProductId: string
) {
    return Service.api.delete<null>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/products/${basketProductId}`
    );
}

const exported = { getAll, post, put, deleteBasketProduct };

export default exported;
