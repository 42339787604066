import { useRef, useEffect } from "react";
import bodymovin from "lottie-web";

const Lottie = (props: any) => {
    // Attributes
    const lottieRef = useRef<HTMLDivElement>(null);

    // Effects
    useEffect(() => {
        if (lottieRef && lottieRef?.current) {
            bodymovin.loadAnimation({
                container: lottieRef.current, // Required
                ...props.options,
            });
        }
    }, [lottieRef, props.options]);

    // Render
    return (
        <div
            style={{ height: props.height, width: props.width }}
            ref={lottieRef}
        />
    );
};
export default Lottie;
