import { useEffect, useState } from "react";
import { useOrder } from "../../../../../../contexts/order";
import OrderInformationCard from "../order-information-card";
import { useProfile } from "../../../../../../contexts/profile";
import { ReactComponent as SELECTED } from "./assets/selected.svg";
import { ReactComponent as Payment } from "../../assets/payment.svg";
import { ReactComponent as BANCONTACT } from "./assets/bancontact.svg";
import { ReactComponent as EDENRED } from "./assets/edenred.svg";
import { ReactComponent as MONIZZE } from "./assets/monizze.svg";
import { ReactComponent as SODEXO } from "./assets/sodexo.svg";
import BusyIndicator from "../../../../../../components/busy-indicator";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { useAllUserPaymentMethodQuery } from "../../../../../../queries/userPaymentMethod";
import PaymentCard from "../../../../components/payment-card";
import { PaymentMethod } from "../../../../../../types/enums/payment-method";
import { PaymentMethodDTO } from "../../../../../../types/dtos/paymentMethod";
import UserService from "../../../../../../services/UserService";
import Paragraph from "../../../../../../components/paragraph";
import * as Sentry from "@sentry/react";
import { displayMinimalAmount } from "../../../../../../utils/minimalAmount";
import { IPaymentMethod } from "./type";

export default function PaymentMethodCard() {
    //Attributes
    const { translate } = useTranslation();
    const { user, dispatch: dispatchUser } = useProfile();
    const { order, dispatch } = useOrder();
    const userPaymentMethodQuery = useAllUserPaymentMethodQuery(user.id);
    const [preRegisterCCIsSet, setPreRegisterCCIsSet] = useState(
        user.paymentMethod === PaymentMethod.CreditCard
    );
    const [currentPaymentMethod, setCurrentPaymentMethod] =
        useState<PaymentMethod>(user.paymentMethod);
    const paymentMethods: IPaymentMethod[] = [
        {
            name: "Bancontact",
            paymentMethod: PaymentMethod.Bancontact,
            logo: <BANCONTACT />,
        },
        {
            name: "Edenred",
            paymentMethod: PaymentMethod.Edenred,
            logo: <EDENRED />,
        },
        {
            name: "Monizze",
            paymentMethod: PaymentMethod.Monizze,
            logo: <MONIZZE />,
        },
        {
            name: "Sodexo",
            paymentMethod: PaymentMethod.Sodexo,
            logo: <SODEXO />,
        },
    ];

    //Functions
    async function handleOnClick(paymentMethod: PaymentMethodDTO) {
        //To indicate that the payment is going to be done with a pre-registered creditcard
        try {
            setCurrentPaymentMethod(PaymentMethod.CreditCard);
            setPreRegisterCCIsSet(true);
            dispatch({
                payload: paymentMethod.id,
                target: "paymentMethodId",
                type: "userChanged",
            });
        } catch (error: any) {
            Sentry.captureException(
                `An error occured when updating the payment method from user : ${user.id} and payment method : ${paymentMethod.id}`,
                error
            );
        }
    }

    async function handleOnClickOtherPaymentMethods(
        paymentMethodType: PaymentMethod
    ) {
        switch (paymentMethodType) {
            case PaymentMethod.Bancontact:
                setCurrentPaymentMethod(PaymentMethod.Bancontact);
                break;
            case PaymentMethod.Edenred:
                setCurrentPaymentMethod(PaymentMethod.Edenred);
                window.location.replace(
                    process.env.REACT_APP_EDENRED_AUTHENTIFICATION_URL ?? "/"
                );
                break;
            case PaymentMethod.NewCard:
                setCurrentPaymentMethod(PaymentMethod.NewCard);
                break;
            case PaymentMethod.Monizze:
                setCurrentPaymentMethod(PaymentMethod.Monizze);
                break;
            case PaymentMethod.Sodexo:
                setCurrentPaymentMethod(PaymentMethod.Sodexo);
                break;
            default:
                setCurrentPaymentMethod(PaymentMethod.Unknown);
                break;
        }

        setPreRegisterCCIsSet(false);
    }

    // Effects
    useEffect(() => {
        if (Object.values(PaymentMethod).includes(user.paymentMethod)) {
            handleUserPaymentMethodUpdated();
        }
    }, [user.paymentMethod]);

    useEffect(() => {
        dispatchUser({
            payload: currentPaymentMethod,
            target: "paymentMethod",
            type: "userChanged",
        });
    }, [currentPaymentMethod]);

    // set the payment method id if the user's current payment method is a credit card
    // that way users can pay with a pre-registered credit card without having to select it
    useEffect(() => {
        if (user.paymentMethod === PaymentMethod.CreditCard) {
            setPreRegisterCCIsSet(true);
            if (
                userPaymentMethodQuery.data &&
                userPaymentMethodQuery.data.length > 0
            ) {
                dispatch({
                    payload: userPaymentMethodQuery.data[0].id,
                    target: "paymentMethodId",
                    type: "userChanged",
                });
            }
        }
    }, [user.paymentMethod, userPaymentMethodQuery.data]);

    // Function
    async function handleUserPaymentMethodUpdated() {
        await UserService.put(user);
    }

    //Render
    return (
        <BusyIndicator query={userPaymentMethodQuery}>
            <OrderInformationCard
                icon={<Payment />}
                title={translate("paymentMethod")}
            >
                <div className="px-4 pb-2">
                    {/**
                     * PRE-REGISTERED CREDIT CARDS
                     */}
                    {userPaymentMethodQuery.data &&
                        userPaymentMethodQuery.data.length > 0 &&
                        userPaymentMethodQuery.data.map((elt, index) => (
                            <div
                                onClick={() => handleOnClick(elt)}
                                className="cursor-pointer flex flex-1 justify-between my-4"
                                key={`${elt.id}-${index}`}
                            >
                                <PaymentCard
                                    showDelete={false}
                                    last4={elt.card.last4}
                                    brand={elt.card.brand}
                                    paymentMethodId={elt.id}
                                    className="border-none!"
                                    isSelected={
                                        order.paymentMethodId === elt.id &&
                                        preRegisterCCIsSet
                                    }
                                />
                                <div className="flex self-center">
                                    <SELECTED
                                        className={`mr-2 ${
                                            order.paymentMethodId === elt.id &&
                                            preRegisterCCIsSet
                                                ? "opacity-100"
                                                : "opacity-0"
                                        }`}
                                    />
                                </div>
                            </div>
                        ))}

                    {/**
                     * OTHER PAYMENT METHODS
                     */}
                    {paymentMethods.map((elt, index) => (
                        <div
                            className="cursor-pointer flex flex-1 justify-between my-4"
                            onClick={() =>
                                handleOnClickOtherPaymentMethods(
                                    elt.paymentMethod
                                )
                            }
                            data-cy={elt.name}
                            key={`${elt?.name}_${index}`}
                        >
                            <div className="flex flex-row">
                                <div className="mr-4 self-center">
                                    {elt.logo}
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row ">
                                        {elt.name}
                                    </div>
                                    <div
                                        className={`text-xs text-placeholder ${
                                            user.paymentMethod ===
                                                elt.paymentMethod &&
                                            !preRegisterCCIsSet
                                                ? "text-text-subbody"
                                                : "text-text-placeholder"
                                        }`}
                                    >
                                        {displayMinimalAmount(
                                            elt.paymentMethod
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex self-center">
                                <SELECTED
                                    className={`mr-2 ${
                                        user.paymentMethod ===
                                            elt.paymentMethod &&
                                        !preRegisterCCIsSet
                                            ? "opacity-100"
                                            : "opacity-0"
                                    }`}
                                />
                            </div>
                        </div>
                    ))}

                    <hr className="mx-2 text-gray-lightest" />

                    {/**
                     * NEW CREDIT CARD METHOD
                     */}
                    <div
                        className="flex flex-row cursor-pointer my-4 mx-2"
                        onClick={() =>
                            handleOnClickOtherPaymentMethods(
                                PaymentMethod.NewCard
                            )
                        }
                    >
                        <div className="">
                            <Paragraph className="flex font-semibold text-dark-blue my-auto text-sm">
                                {translate("newCreditCard")}
                            </Paragraph>
                            <Paragraph
                                style={{ maxWidth: 242, fontSize: 12 }}
                                className="flex font-normal text-gray-DEFAULT text-left max-w-xs"
                            >
                                {translate("newCardWhenPaying")}
                            </Paragraph>
                        </div>
                        <SELECTED
                            className={`${
                                user.paymentMethod === PaymentMethod.NewCard &&
                                !preRegisterCCIsSet
                                    ? "opacity-100"
                                    : "opacity-0"
                            } flex justify-end items-center my-auto ml-auto`}
                        />
                    </div>
                </div>
            </OrderInformationCard>
        </BusyIndicator>
    );
}
