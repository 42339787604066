import { useEffect, useState } from "react";
import { useProfile } from "../../../../contexts/profile";

import isHourBetweenBeginAndEndHours from "../../../../utils/isTimeIntervalBetweenGivenDate";

import { IHomeModalsLogicInput, IHomeModalsLogicOutput } from "./types";

const useHomeModalsLogic = ({
    company,
}: IHomeModalsLogicInput): IHomeModalsLogicOutput => {
    // Attributes
    const { user, dispatch } = useProfile();
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);

    // Functions
    function handleNotificationModalClose() {
        setIsNotificationModalOpen(false);
        // set in local storage that user alreadu saw the notification
        localStorage.setItem(
            "hasSeenNotification",
            JSON.stringify({
                employeeId: user.employeeId,
                seen: true,
            })
        );
    }

    const tempKitchenClosedFrom = new Date(
        "2022-08-14T07:55:00.000Z"
    ).getTime();
    const tempKitchenClosedTo = new Date("2022-08-15T12:00:00.000Z").getTime();
    const now = new Date().getTime();
    const customClosingMsg =
        "Nous sommes fermés ce lundi 15 août, on est parti à la plage pour profiter d’une bonne petite glace et puis on est de retour pour vous préparer les meilleurs plats";
    const isTemporaryClosed =
        now >= tempKitchenClosedFrom && now <= tempKitchenClosedTo;
    const isKitchenClose =
        (now >= tempKitchenClosedFrom && now <= tempKitchenClosedTo) ||
        isHourBetweenBeginAndEndHours(
            new Date(),
            company?.newKitchenClosingHourBegin as string,
            company?.newKitchenClosingHourEnd as string
        );

    function handleModalClose() {
        dispatch({
            type: "userChanged",
            payload: true,
            target: "hasSeenKitchenClosedModal",
        });
    }

    // Effects
    useEffect(() => {
        // get from local storage if user has already seen the notification
        const hasSeenNotification = localStorage.getItem("hasSeenNotification");
        if (hasSeenNotification == null) {
            console.warn("hasSeenNotification is null");
            setIsNotificationModalOpen(true);
        }
    }, []);

    // Return
    return {
        customClosingMsg,
        isTemporaryClosed,
        isKitchenClose,
        isNotificationModalOpen,
        setIsNotificationModalOpen,
        handleModalClose,
        handleNotificationModalClose,
    };
};

export default useHomeModalsLogic;
