import { AxiosResponse } from "axios";
import { CompanyRestaurantDTO } from "../types/dtos/company-restaurant";
import Service from "./Service";

function get(
    companyId: string
): Promise<AxiosResponse<Array<CompanyRestaurantDTO>>>;

function get(
    companyId: string,
    restaurantId: string
): Promise<AxiosResponse<CompanyRestaurantDTO>>;
function get(companyId: string, restaurantId?: string) {
    if (restaurantId) {
        return Service.api.get<CompanyRestaurantDTO>(
            `/v1/api/companies/${companyId}/restaurants/${restaurantId}`
        );
    }

    return Service.api.get<Array<CompanyRestaurantDTO>>(
        `/v1/api/companies/${companyId}/restaurants`
    );
}

const exported = { get };

export default exported;
