import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { OverlayProvider } from "../../contexts/overlay";
import { CompanyRouteParams } from "../../types/params/company-route";
import Basket from "./modules/basket";
import OrderOverviewRouter from "./modules/order-overview";
import Orders from "./modules/orders";
import Profile from "./modules/profile";
import ForgotPassword from "./modules/sign/modules/forgot-password";
import SignIn from "./modules/sign/modules/sign-in";
import SignUp from "./modules/sign/modules/sign-up";
import Events from "./modules/events";
import Auth from "./modules/sign/modules/auth";
import Search from "./modules/search";
import ResetPassword from "../reset-password";
import Home from "./modules/home";
export default function Company(
    props: RouteComponentProps<CompanyRouteParams>
) {
    return (
        <OverlayProvider>
            <Switch>
                <Route path="/:companyId/profile" component={Profile} />

                <Route path="/:companyId/basket" component={Basket} />

                <Route path="/:companyId/orders" component={Orders} />

                <Route path="/:companyId/events" component={Events} />

                <Route path="/:companyId/search" component={Search} />

                <Route
                    path="/:companyId/order-overview"
                    component={OrderOverviewRouter}
                />

                <Route path="/:companyId/auth" component={Auth} />

                <Route path="/:companyId/sign-in" component={SignIn} />

                <Route path="/:companyId/sign-up" component={SignUp} />

                <Route
                    path="/:companyId/forgot-password"
                    component={ForgotPassword}
                />

                <Route path="/reset-password" component={ResetPassword} />

                <Route
                    path="/home"
                    component={() => Home({ isPublic: true })}
                />

                <Route
                    path="/:companyId"
                    component={() => Home({ isPublic: false })}
                />
            </Switch>
        </OverlayProvider>
    );
}
