import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import AddToBasketProp from "./props";
import { ReactComponent as Add } from "../../../../../../../../assets/plus.svg";
import { ReactComponent as Arrow } from "../../../../../../../../assets/arrow-right.svg";
import Button from "../../../../../../../../components/button";

const AddToBasket = ({
    productPrice,
    text,
    logoType = "plus",
    borderRadius = "rounded-lg",
    handleClick,
    isUpdateBasketLoading,
}: AddToBasketProp) => {
    //Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <Button
            className={`min-w-full min-h-11! max-h-11! text-white bg-add-button items-center px-4 cursor-pointer font-semibold text-base ${borderRadius}`}
            data-cy="addToBasket"
            onClick={handleClick}
            disabled={isUpdateBasketLoading}
        >
            <div className="flex justify-between">
                <div className="price">{productPrice}€</div>
                <div className="flex flex-row">
                    <div className="mr-4">{text ? translate(text) : ""}</div>

                    <div className="my-auto">
                        {logoType === "plus" ? <Add /> : <Arrow />}
                    </div>
                </div>
            </div>
        </Button>
    );
};

export default AddToBasket;
