import React from "react";
import { ReactComponent as KitchenTiming } from "./assets/kitchen-timing.svg";
import Button from "../../../../../../components/button";
import Popup from "../../../../../../components/popup";
import Title from "../../../../../../components/title";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { KitchenTimingModalProps } from "./props";

const KitchenTimingModal = ({
    isOpen,
    handleOnClose,
    errorMsg,
}: KitchenTimingModalProps) => {
    // Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <Popup isVisible={isOpen} handleOnClose={handleOnClose}>
            <div className="flex flex-col items-center!">
                <KitchenTiming className="mb-12" />
                {errorMsg ? (
                    <div className="text-center max-w-xs text-base! pb-4 ">
                        {errorMsg}
                    </div>
                ) : (
                    <Title className="text-center max-w-xs text-base!">
                        KitchenTimingDetail
                    </Title>
                )}
                <div className="flex flex-row ">
                    <Button
                        onClick={handleOnClose}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                    >
                        {translate("KitchenTimingConfirm")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default KitchenTimingModal;
