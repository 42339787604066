/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    BasketProductResDTO,
    BasketProductReqCreateDTO,
    BasketProductReqUpdateDTO,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getBasketProducts = (
    employeeId: string,
    basketId: string,
    signal?: AbortSignal
) => {
    return customInstance<BasketProductResDTO[]>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}/products`,
        method: "get",
        signal,
    });
};

export const getGetBasketProductsQueryKey = (
    employeeId: string,
    basketId: string
) => [`/v1/api/employees/${employeeId}/baskets/${basketId}/products`];

export type GetBasketProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getBasketProducts>>
>;
export type GetBasketProductsQueryError = unknown;

export const useGetBasketProducts = <
    TData = Awaited<ReturnType<typeof getBasketProducts>>,
    TError = unknown
>(
    employeeId: string,
    basketId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getBasketProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetBasketProductsQueryKey(employeeId, basketId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getBasketProducts>>
    > = ({ signal }) => getBasketProducts(employeeId, basketId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getBasketProducts>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!(employeeId && basketId),
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createBasketProduct = (
    employeeId: string,
    basketId: string,
    basketProductReqCreateDTO: BasketProductReqCreateDTO
) => {
    return customInstance<BasketProductResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}/products`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: basketProductReqCreateDTO,
    });
};

export type CreateBasketProductMutationResult = NonNullable<
    Awaited<ReturnType<typeof createBasketProduct>>
>;
export type CreateBasketProductMutationBody = BasketProductReqCreateDTO;
export type CreateBasketProductMutationError = unknown;

export const useCreateBasketProduct = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createBasketProduct>>,
        TError,
        {
            employeeId: string;
            basketId: string;
            data: BasketProductReqCreateDTO;
        },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createBasketProduct>>,
        {
            employeeId: string;
            basketId: string;
            data: BasketProductReqCreateDTO;
        }
    > = (props) => {
        const { employeeId, basketId, data } = props ?? {};

        return createBasketProduct(employeeId, basketId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createBasketProduct>>,
        TError,
        {
            employeeId: string;
            basketId: string;
            data: BasketProductReqCreateDTO;
        },
        TContext
    >(mutationFn, mutationOptions);
};
export const updateBasketProduct = (
    employeeId: string,
    basketId: string,
    productId: string,
    basketProductReqUpdateDTO: BasketProductReqUpdateDTO
) => {
    return customInstance<BasketProductResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}/products/${productId}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: basketProductReqUpdateDTO,
    });
};

export type UpdateBasketProductMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateBasketProduct>>
>;
export type UpdateBasketProductMutationBody = BasketProductReqUpdateDTO;
export type UpdateBasketProductMutationError = unknown;

export const useUpdateBasketProduct = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateBasketProduct>>,
        TError,
        {
            employeeId: string;
            basketId: string;
            productId: string;
            data: BasketProductReqUpdateDTO;
        },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateBasketProduct>>,
        {
            employeeId: string;
            basketId: string;
            productId: string;
            data: BasketProductReqUpdateDTO;
        }
    > = (props) => {
        const { employeeId, basketId, productId, data } = props ?? {};

        return updateBasketProduct(employeeId, basketId, productId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateBasketProduct>>,
        TError,
        {
            employeeId: string;
            basketId: string;
            productId: string;
            data: BasketProductReqUpdateDTO;
        },
        TContext
    >(mutationFn, mutationOptions);
};
export const deleteBasketProduct = (
    employeeId: string,
    basketId: string,
    productId: string
) => {
    return customInstance<BasketProductResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}/products/${productId}`,
        method: "delete",
    });
};

export type DeleteBasketProductMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteBasketProduct>>
>;

export type DeleteBasketProductMutationError = unknown;

export const useDeleteBasketProduct = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteBasketProduct>>,
        TError,
        { employeeId: string; basketId: string; productId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteBasketProduct>>,
        { employeeId: string; basketId: string; productId: string }
    > = (props) => {
        const { employeeId, basketId, productId } = props ?? {};

        return deleteBasketProduct(employeeId, basketId, productId);
    };

    return useMutation<
        Awaited<ReturnType<typeof deleteBasketProduct>>,
        TError,
        { employeeId: string; basketId: string; productId: string },
        TContext
    >(mutationFn, mutationOptions);
};
