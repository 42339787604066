import { EventDTO } from "../types/dtos/event";
import * as _ from "lodash";
import { EventTypeEnum } from "../types/enums/event-type";

export interface IEventActionReducer {
    type: "key" | "server";
    payload: any;
    target:
        | "name"
        | "creatorId"
        | "type"
        | "maxAmount"
        | "restaurantIds"
        | "_self";
}

export const initialEvent: EventDTO = {
    name: "",
    creatorId: "",
    type: EventTypeEnum.Unknown,
    maxAmount: 0,
    restaurantIds: [],
    id: "",
    creationDate: new Date().toISOString(),
    modificationDate: new Date().toISOString(),
};

export function getInitialEvent() {
    return _.cloneDeep(initialEvent);
}

export function eventReducer(state: EventDTO, action: IEventActionReducer) {
    switch (action.type) {
        case "key":
            return {
                ...state,
                [action.target]: action.payload,
            };
        case "server":
            return {
                ...action.payload,
            };
        default:
            return state;
    }
}
