/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    CompanyRestaurantResDTO,
    GetCompanyRestaurantsByCompanyIdParams,
    CompanyRestaurantReqUpdateDTO,
    GetPublicCompanyRestaurantsParams,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCompanyRestaurantsByCompanyId = (
    companyId: string,
    params?: GetCompanyRestaurantsByCompanyIdParams,
    signal?: AbortSignal
) => {
    return customInstance<CompanyRestaurantResDTO[]>({
        url: `/v1/api/companies/${companyId}/restaurants`,
        method: "get",
        params,
        signal,
    });
};

export const getGetCompanyRestaurantsByCompanyIdQueryKey = (
    companyId: string,
    params?: GetCompanyRestaurantsByCompanyIdParams
) => [
    `/v1/api/companies/${companyId}/restaurants`,
    ...(params ? [params] : []),
];

export type GetCompanyRestaurantsByCompanyIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyRestaurantsByCompanyId>>
>;
export type GetCompanyRestaurantsByCompanyIdQueryError = unknown;

export const useGetCompanyRestaurantsByCompanyId = <
    TData = Awaited<ReturnType<typeof getCompanyRestaurantsByCompanyId>>,
    TError = unknown
>(
    companyId: string,
    params?: GetCompanyRestaurantsByCompanyIdParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getCompanyRestaurantsByCompanyId>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetCompanyRestaurantsByCompanyIdQueryKey(companyId, params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getCompanyRestaurantsByCompanyId>>
    > = ({ signal }) =>
        getCompanyRestaurantsByCompanyId(companyId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getCompanyRestaurantsByCompanyId>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!companyId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const updateCompanyRestaurant = (
    companyId: string,
    restaurantId: string,
    companyRestaurantReqUpdateDTO: CompanyRestaurantReqUpdateDTO
) => {
    return customInstance<CompanyRestaurantResDTO>({
        url: `/v1/api/companies/${companyId}/restaurants/${restaurantId}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: companyRestaurantReqUpdateDTO,
    });
};

export type UpdateCompanyRestaurantMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyRestaurant>>
>;
export type UpdateCompanyRestaurantMutationBody = CompanyRestaurantReqUpdateDTO;
export type UpdateCompanyRestaurantMutationError = unknown;

export const useUpdateCompanyRestaurant = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateCompanyRestaurant>>,
        TError,
        {
            companyId: string;
            restaurantId: string;
            data: CompanyRestaurantReqUpdateDTO;
        },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateCompanyRestaurant>>,
        {
            companyId: string;
            restaurantId: string;
            data: CompanyRestaurantReqUpdateDTO;
        }
    > = (props) => {
        const { companyId, restaurantId, data } = props ?? {};

        return updateCompanyRestaurant(companyId, restaurantId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateCompanyRestaurant>>,
        TError,
        {
            companyId: string;
            restaurantId: string;
            data: CompanyRestaurantReqUpdateDTO;
        },
        TContext
    >(mutationFn, mutationOptions);
};
export const getPublicCompanyRestaurants = (
    params?: GetPublicCompanyRestaurantsParams,
    signal?: AbortSignal
) => {
    return customInstance<CompanyRestaurantResDTO[]>({
        url: `/v1/api/companies/restaurants/public`,
        method: "get",
        params,
        signal,
    });
};

export const getGetPublicCompanyRestaurantsQueryKey = (
    params?: GetPublicCompanyRestaurantsParams
) => [`/v1/api/companies/restaurants/public`, ...(params ? [params] : [])];

export type GetPublicCompanyRestaurantsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getPublicCompanyRestaurants>>
>;
export type GetPublicCompanyRestaurantsQueryError = unknown;

export const useGetPublicCompanyRestaurants = <
    TData = Awaited<ReturnType<typeof getPublicCompanyRestaurants>>,
    TError = unknown
>(
    params?: GetPublicCompanyRestaurantsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getPublicCompanyRestaurants>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetPublicCompanyRestaurantsQueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getPublicCompanyRestaurants>>
    > = ({ signal }) => getPublicCompanyRestaurants(params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getPublicCompanyRestaurants>>,
        TError,
        TData
    >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<
        TData,
        TError
    > & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};
