import { Capacitor } from "@capacitor/core";
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from "@capacitor/push-notifications";
import React from "react";
import Button from "../../../../../../../../components/button";
import Paragraph from "../../../../../../../../components/paragraph";
import Popup from "../../../../../../../../components/popup";
import { PopUpStyleType } from "../../../../../../../../components/popup/props";
import Title from "../../../../../../../../components/title";
import { useProfile } from "../../../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import DeviceService from "../../../../../../../../services/DeviceService";
import Service from "../../../../../../../../services/Service";
import { DeviceDTO } from "../../../../../../../../types/dtos/device";
import { ReactComponent as FoodIcon } from "./assets/food.svg";

interface NotificationModalProps {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    handleOnClose: () => void;
}

export default function NotificationModal({
    isVisible = false,
    setIsVisible,
    handleOnClose,
}: NotificationModalProps) {
    // Attributes
    const { translate } = useTranslation();
    const { user } = useProfile();

    // Functions
    async function handleNotificationActivation() {
        if (
            Capacitor.getPlatform() === "ios" ||
            Capacitor.getPlatform() === "android"
        ) {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then((result) => {
                if (result.receive === "granted") {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // Show some error
                }
            });

            PushNotifications.addListener(
                "registration",
                async (token: Token) => {
                    // send this token to server so we can send push notifications to this device
                    var device: DeviceDTO = {
                        token: token.value,
                        type: Capacitor.getPlatform().toLocaleUpperCase(),
                    };

                    const response = await DeviceService.registerDevice(
                        user.id,
                        device
                    );

                    if (response.status === 200) {
                        Service.success.push("DeviceRegisteredSuccessfully");
                    } else {
                        Service.errors.push("DeviceRegistrationFailed");
                    }
                }
            );

            PushNotifications.addListener(
                "registrationError",
                (error: any) => {}
            );

            PushNotifications.addListener(
                "pushNotificationReceived",
                (notification: PushNotificationSchema) => {}
            );

            PushNotifications.addListener(
                "pushNotificationActionPerformed",
                (notification: ActionPerformed) => {}
            );
        }

        handleNotificationModalClose();
    }

    function handleNotificationModalClose() {
        // set in local storage that user alreadu saw the notification
        setIsVisible(false);
        localStorage.setItem(
            "hasSeenNotification",
            JSON.stringify({
                employeeId: user.employeeId,
                seen: true,
            })
        );
    }

    // Render
    return (
        <Popup
            isVisible={isVisible}
            handleOnClose={handleOnClose}
            styleType={PopUpStyleType.FullScreen}
        >
            <div
                style={{
                    backgroundColor: "#FAF6ED",
                }}
                className="flex flex-col items-center pt-36 w-full h-full px-12"
            >
                <FoodIcon />
                <Title className="text-center max-w-xs mt-12 mb-5!">
                    enableNotifications
                </Title>
                <Paragraph className="text-lg font-normal text-center ">
                    {translate("enableNotificationsInfo")}
                </Paragraph>
                <Paragraph className="text-lg font-normal text-center ">
                    {translate("enableNotificationsSubInfo")}
                </Paragraph>

                <div className="flex flex-col w-full h-24 justify-between mt-32">
                    <Button
                        onClick={() => handleNotificationActivation()}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                    >
                        {translate("accpetNotification")}
                    </Button>
                    <Button
                        onClick={handleOnClose}
                        className="bg-cancel-background py-2 px-3 rounded-lg text-dark-blue! font-semibold md:py-4 md:px-10 "
                    >
                        {translate("refuseNotification")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
}
