import { DeviceDTO } from "./../types/dtos/device";
import Service from "./Service";

async function registerDevice(userId: string, deviceDto: DeviceDTO) {
    return await Service.goapi.post<DeviceDTO>(
        `/v1/api/users/${userId}/devices`,
        deviceDto
    );
}

const exported = {
    registerDevice,
};

export default exported;
