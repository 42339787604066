import { PaymentMethod } from "../types/enums/payment-method";

export function displayMinimalAmount(paymentMethod?: PaymentMethod) {
    switch (paymentMethod) {
        case PaymentMethod.Sodexo:
            return "Minimal amount of 1€";
        default:
            return "Minimal amount of 0.50€";
    }
}
