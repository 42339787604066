import React, { createContext, useContext, useReducer } from "react";
import {
    getInitialUser,
    reducer,
    UserActionReducer,
} from "../../modules/company/modules/profile/modules/detail/reducer";
import { UserDTO } from "../../types/dtos/user";

interface ProfileContextInterface {
    user: UserDTO;
    dispatch: (action: UserActionReducer) => void;
}

const ProfileContext = createContext<ProfileContextInterface>({
    user: getInitialUser(),
    dispatch: function (action: UserActionReducer) {},
});

export function useProfile() {
    return useContext(ProfileContext);
}

const ProfileContextProvider = ({ children }: any) => {
    // Attributes
    const AccountProvider = ProfileContext.Provider;
    const [user, dispatch] = useReducer(reducer, getInitialUser());

    return (
        <AccountProvider value={{ user, dispatch }}>{children}</AccountProvider>
    );
};

export default ProfileContextProvider;
