import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useOrder } from "../../../../../../contexts/order";
import { useProfile } from "../../../../../../contexts/profile";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import OrderService from "../../../../../../services/OrderService";
import Service from "../../../../../../services/Service";
import { OrderDTO } from "../../../../../../types/dtos/order";
import { OrderStatus } from "../../../../../../types/enums/order-status";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { loadStripe } from "@stripe/stripe-js";
import { paymentOrderDTO } from "../../../../../../types/dtos/paymentOrder";
import { PaymentMethod } from "../../../../../../types/enums/payment-method";
import SodexoOrderService from "../../../../../../services/SodexoOrderService";
import { useGetCompanyById } from "../../../../../../go-services/company/company";
import isHourBetweenBeginAndEndHours from "../../../../../../utils/isTimeIntervalBetweenGivenDate";
import { Capacitor } from "@capacitor/core";
import { useAllBasketProduct } from "../../../../../../queries/basketProduct";
import {
    getBasketProducts,
    useGetBasketProducts,
} from "../../../../../../go-services/basket-product/basket-product";
import * as Sentry from "@sentry/react";
import { displayPaymentErrors } from "../../../../../../mappers/message-errors";
import { BasketProductDTO } from "../../../../../../types/dtos/basket-product";
import BasketProductService from "../../../../../../services/BasketProductService";
import { IUseOrderBreakdownLogicProps, IUseOrderBreakdownReturn } from "./type";
import { useAnalytics } from "@segment/analytics-react";

const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY ?? "");

const useOrderBreakdownLogic = ({
    currentBasket,
    refetchCurrentBasket,
}: IUseOrderBreakdownLogicProps): IUseOrderBreakdownReturn => {
    //Attributes
    const { track } = useAnalytics();
    const history = useHistory();
    const isReorder = history.location.search.includes("reorder=true");
    const { user } = useProfile();
    const { dispatch, order } = useOrder();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const plat = Capacitor.getPlatform();
    const isMobile = plat === "ios" || plat === "android";
    const location = isMobile
        ? "https://app.foodiz.be"
        : window.location.origin;
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [listOfUnavailableProduct, setListOfUnavailableProducts] =
        useState<BasketProductDTO[]>();
    const [
        displayUnavailableProductsWarning,
        setDisplayUnavailableProductsWarning,
    ] = useState(false);
    const SODEXO_PAYMENT_METHOD = 5;
    const PAYMENT_START_AMOUNT = 0;
    const MINIMUM_AMOUNT_STANDARD = 0.5;
    const MINIMUM_AMOUNT_SODEXO = 1;
    const MISSING_AUTH_CODE_ERROR =
        "Payment method error: Missing authentication Code";
    const UNAVAILABLE_PRODUCT_IN_BASKET =
        "Basket contains unavailable products";

    //Queries
    const { data: basketProductsNotUsed, isLoading: isProductsLoading } =
        useGetBasketProducts(user?.employeeId, currentBasket?.id as string, {
            query: {
                enabled:
                    !!user?.employeeId &&
                    !!currentBasket?.id &&
                    currentBasket?.id !== "",
            },
        });
    const { data: company } = useGetCompanyById(companyId as string, {
        query: { enabled: !!companyId },
    });
    const { data: basketProducts, refetch: basketProductsRefetch } =
        useAllBasketProduct(
            companyQuery?.id as string,
            user.employeeId,
            currentBasket?.id as string
        );
    const total = currentBasket?.totalPrice ?? 0;
    const subTotal = useMemo(() => {
        let result = 0;

        basketProducts?.forEach((elt: any) => {
            if (elt?.quantity) result += elt.product?.unitPrice! * elt.quantity;
        });

        return result;
    }, [basketProducts]);
    const promoCodeAmount = subTotal - total;
    const isBasketEmpty = total === 0 && !basketProducts?.length;
    const isKitchenClose = isHourBetweenBeginAndEndHours(
        new Date(),
        company?.newKitchenClosingHourBegin as string,
        company?.newKitchenClosingHourEnd as string
    );
    const [closeKitchenPopup, setCloseKitchenPopup] = useState<boolean>(false);

    //Functions
    const handlePayment = async () => {
        setIsPaymentLoading(true);
        if (!!isReorder) {
            track("Re order confirmed", {
                user: user?.id,
            });
        }

        // check if has min 1 product in basket
        if (basketProducts?.length === 0) {
            displayPaymentErrors("displayPaymentErrors");
            Sentry.captureException(
                `An error occured due to empty basket for user ${user.id} in basket : ${currentBasket?.id}`
            );
            setIsPaymentLoading(false);
            return;
        }

        if (!isKitchenClose) {
            const orderDTO: OrderDTO = {
                ...order,
                paymentMethod: user.paymentMethod,
                basketProducts: basketProducts ?? [], // mapToBasketProductDtos(basketProducts ?? []), // !!! ATTENTION !!! QUICK FIX: Mapper from new types to old types to be compatible with old backend.
            };

            const paymentOrderDTO: paymentOrderDTO = {
                order: orderDTO,
                successRoute: `${location}/${companyName}/order-overview/${order.id}/loading`,
                cancelRoute: `${location}/${companyName}/order-overview`,
                authenticationCode:
                    window.localStorage.getItem("auth-code")?.toString() ?? "",
            };

            //#region VOUCHERS

            // vauchers can't be used when total price is 0
            if (total !== 0) {
                switch (user.paymentMethod) {
                    case PaymentMethod.Sodexo:
                        try {
                            const response =
                                await SodexoOrderService.createSodexoOrder(
                                    paymentOrderDTO
                                );
                            window.location.replace(response.data.checkoutURL);
                            window.localStorage.removeItem("auth-code");
                            return;
                        } catch (error: any) {
                            Service.errors.push("orderUpdateError");
                            Sentry.captureException(
                                `An error occured with paymentMethod : ${user.paymentMethod} for user ${user.id} in basket : ${currentBasket?.id}`,
                                error
                            );
                            setIsPaymentLoading(false);
                            return;
                        }
                    case PaymentMethod.Edenred:
                        try {
                            if (
                                window.localStorage.getItem("auth-code") ===
                                null
                            ) {
                                window.location.replace(
                                    process.env
                                        .REACT_APP_EDENRED_AUTHENTIFICATION_URL ??
                                        "/"
                                );
                                setIsPaymentLoading(false);
                                return;
                            }
                        } catch (error: any) {
                            Sentry.captureException(
                                `An error occured with paymentMethod : ${user.paymentMethod} for user ${user.id} in basket : ${currentBasket?.id}`,
                                error
                            );
                        }
                        break;
                    default:
                        break;
                }
            }

            //#endregion

            let response;

            try {
                response = await OrderService.put(
                    companyId!,
                    user.employeeId,
                    currentBasket?.id ?? "",
                    orderDTO
                );
            } catch (error: any) {
                Service.errors.push("orderUpdateError");
                Sentry.captureException(
                    `An Error occured when updating the order : ${JSON.stringify(
                        orderDTO
                    )} for the user ${user.id} for basket : ${
                        currentBasket?.id
                    } `,
                    error
                );
                setIsPaymentLoading(false);
                return;
            }

            dispatch({
                payload: OrderStatus.Waiting,
                type: "userChanged",
                target: "status",
            });

            const stripe = await stripePromise;

            let paymentResponse;
            try {
                paymentResponse = await OrderService.postPayment(
                    companyId!,
                    user.employeeId,
                    currentBasket?.id ?? "",
                    orderDTO.id,
                    paymentOrderDTO
                );
            } catch (error: any) {
                //Basket contains unavailable products
                if (error.response.data === UNAVAILABLE_PRODUCT_IN_BASKET) {
                    await handleUnavailableProductError();
                    return;
                }
                if (error.response.data === MISSING_AUTH_CODE_ERROR) {
                    return window.location.replace(
                        process.env.REACT_APP_MONIZZE_AUTHENTIFICATION_URL ??
                            "/"
                    );
                }
                window.localStorage.removeItem("auth-code");
                Service.errors.push("paymentError");
                Sentry.captureException(
                    `An Error payment occured when updating the order : ${JSON.stringify(
                        orderDTO
                    )} for the user ${user.id} using the payment method ${
                        user.paymentMethod
                    } in the basket : ${currentBasket?.id}`,
                    error
                );
                return;
            } finally {
                setIsPaymentLoading(false);
            }

            if (stripe && paymentResponse) {
                // When the customer clicks on the button, redirect them to Checkout.
                if (paymentResponse.data.sessionId) {
                    const redirectResponse = await stripe.redirectToCheckout({
                        // => redirects to loading and loading redirect if success or failure
                        sessionId: paymentResponse.data.sessionId,
                    });
                    if (redirectResponse.error) {
                        setIsPaymentLoading(false);
                        Sentry.captureException(
                            `An Error payment occured when trying to redirect user : ${user.id} to Stripe in basket : ${currentBasket?.id}`,
                            redirectResponse.error as any
                        );
                        return;
                    }
                } else {
                    history.replace(`/${companyName}/order-overview/success`);
                }
            }

            if (paymentResponse) {
                setIsPaymentLoading(false);

                try {
                    if (
                        response.status === 200 &&
                        paymentResponse.status === 200
                    ) {
                        Service.success.push("orderSuccessful");
                        history.replace(
                            `/${companyName}/order-overview/success`
                        );
                    } else {
                        Service.errors.push("orderUpdateError");
                    }
                } catch (error: any) {
                    Sentry.captureException(
                        `An Error payment occured when trying to redirect user : ${user.id}`,
                        error
                    );
                }
            }

            window.localStorage.removeItem("auth-code");
            setIsPaymentLoading(false);
        } else {
            setCloseKitchenPopup(true);
        }
    };

    /**
     * Determines whether to disable the payment button based on the chosen payment method and basket amount.
     * @param {number} paymentMethod - The chosen payment method by the user.
     * @returns {boolean} Returns true if the button should be disabled, false otherwise.
     * If the payment method is not Sodexo, the basket amount should be between 0.01€ and 0.5€. For Sodexo, it should be between 0.01€ and 1€.
     * If the basket total amount is 0 and there are products in the basket, payment is allowed. This scenario can occur with a promo code.
     */
    function shouldDisablePaymentButton(paymentMethod: PaymentMethod) {
        return (
            (paymentMethod !== SODEXO_PAYMENT_METHOD &&
                total > PAYMENT_START_AMOUNT &&
                total < MINIMUM_AMOUNT_STANDARD) ||
            (paymentMethod === SODEXO_PAYMENT_METHOD &&
                total > PAYMENT_START_AMOUNT &&
                total < MINIMUM_AMOUNT_SODEXO) ||
            isBasketEmpty
        );
    }

    async function handleUnavailableProductError() {
        await basketProductsRefetch();
        const products = await getBasketProducts(
            user?.employeeId as string,
            basketProducts?.[0]?.basketId as string
        );
        const unavailableListProducts = products?.filter(
            (elt) => !elt.product?.isAvailable
        );
        setListOfUnavailableProducts(unavailableListProducts as any);
        await handleRemoveBasketProduct(unavailableListProducts as any);
        setDisplayUnavailableProductsWarning(true);
    }

    async function handleOnPaymentClick() {
        if (
            !isPaymentLoading &&
            !shouldDisablePaymentButton(user?.paymentMethod)
        ) {
            await handlePayment();
        }
    }

    function generateErrorMessage(products: BasketProductDTO[] | undefined) {
        if (!Array.isArray(products) || products.length === 0) {
            return "No products found.";
        }

        let unavailableProducts: any = [];
        products.forEach((item) => {
            if (!item.product.isAvailable) {
                unavailableProducts.push(
                    `"<strong>${item.product.name}</strong>"`
                );
            }
        });

        if (unavailableProducts.length === 1) {
            return `The ${unavailableProducts[0]} meal is no longer available and has been removed from your cart.`;
        }

        const lastProduct = unavailableProducts.pop();
        const formattedProducts = unavailableProducts.join(", ");
        return `The ${formattedProducts} and ${lastProduct} meals are no longer available and have been removed from your cart.`;
    }

    async function handleRemoveBasketProduct(
        unavailableProducts: BasketProductDTO[]
    ) {
        if (!unavailableProducts.length) return;
        try {
            await Promise.all(
                unavailableProducts.map(async (unavailableProduct) => {
                    return await BasketProductService.deleteBasketProduct(
                        companyId as string,
                        user?.employeeId as string,
                        unavailableProduct?.basketId as string,
                        unavailableProduct?.id as string
                    );
                })
            ).then(() => {
                basketProductsRefetch();
                refetchCurrentBasket();
            });
        } catch (e) {
            Service.errors.push(
                "Something went wrong deleting unavailable items"
            );
        }
    }

    //Effect
    // When adding an item from cross-selling the basket wasn't refreshed
    // because we still need to use the old calls
    useEffect(() => {
        refetchCurrentBasket();
        basketProductsRefetch();
    }, [basketProductsNotUsed]);

    useEffect(() => {
        if (!basketProducts && !currentBasket?.id && !companyQuery?.address)
            return;

        dispatch({
            payload: currentBasket?.id,
            type: "userChanged",
            target: "basketId",
        });

        dispatch({
            payload: companyQuery?.address,
            type: "userChanged",
            target: "addressDTO",
        });

        dispatch({
            payload: basketProducts,
            type: "userChanged",
            target: "basketProducts",
        });
    }, [currentBasket, companyQuery?.address, basketProducts]);

    //Return
    return {
        basketProducts,
        closeKitchenPopup,
        company,
        displayUnavailableProductsWarning,
        generateErrorMessage,
        handleOnPaymentClick,
        isPaymentLoading,
        isProductsLoading,
        listOfUnavailableProduct,
        promoCodeAmount,
        setCloseKitchenPopup,
        shouldDisablePaymentButton,
        subTotal,
        total,
        user,
    };
};

export default useOrderBreakdownLogic;
