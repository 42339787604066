import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignPage from "../company/modules/sign/component/sign-page";
import NewPassword from "./modules/new-password";
import Success from "./modules/success";

function ResetPassword() {
    // Render
    return (
        <SignPage showBackButton={false} showProgressBar={false}>
            <Switch>
                <Route
                    path="/reset-password/new-password"
                    component={NewPassword}
                />
                <Route path="/reset-password/success" component={Success} />

                <Route
                    path="/reset-password"
                    component={() => (
                        <Redirect
                            push={false}
                            to={`/reset-password/new-password`}
                        />
                    )}
                />
            </Switch>
        </SignPage>
    );
}

export default ResetPassword;
