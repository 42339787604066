import React, { useState } from "react";
import Paragraph from "../../../../../../components/paragraph";
import ProductOverlayProps from "./props";
import { ReactComponent as Minus } from "./assets/minus.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import AddToBasket from "./components/add-to-basket";
import { useProfile } from "../../../../../../contexts/profile";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import ProductTag from "./components/product-tag";
import { useGetProductTagsByProductId } from "../../../../../../go-services/product-tags/product-tags";
import parse from "html-react-parser";
import { useSafeArea } from "../../../../../../contexts/safe-area";
import { getGetCurrentBasketQueryKey } from "../../../../../../go-services/basket/basket";
import { getGetBasketProductsQueryKey } from "../../../../../../go-services/basket-product/basket-product";
import { useQueryClient } from "react-query";
import useBasketLogic from "../../../../../../hooks/useBasketLogic";
import { Capacitor } from "@capacitor/core";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useOneBasketQuery } from "../../../../../../queries/basket";
import { QueryKey } from "../../../../../../types/enums/query-key";

const ProductOverlay = ({
    product,
    showQuantity = true,
}: ProductOverlayProps) => {
    //Attributes
    const { addProductToBasket } = useBasketLogic();
    const queryClient = useQueryClient();
    const { insets } = useSafeArea();
    const { user } = useProfile();
    const [quantity, setQuantity] = useState(1);
    const overlay = useOverlay();

    // Queries
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );

    const { data: productTags } = useGetProductTagsByProductId(
        product?.id ?? "",
        { size: 1000, status: "VISIBLE" }
    );

    //Functions
    const handleOnMinus = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            return;
        }
    };

    const handleConfirm = () => {
        addProductToBasket({
            productId: product?.id ?? "",
            quantity,
            onSuccess: handleBasketUpdateSuccess,
        });
    };

    // Success handler
    const handleBasketUpdateSuccess = async () => {
        overlay.removeCurrentOverlay();
        // Refetch basket
        await queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
        await queryClient.invalidateQueries(
            getGetBasketProductsQueryKey(
                user?.employeeId ?? "",
                currentBasket?.id ?? ""
            )
        );
    };

    //Render
    return (
        <div className="flex flex-col flex-1 bg-white md:max-h-product-overlay-panel overflow-y-auto! no-scrollbar">
            {/*  Product Image */}
            <div className="flex-none">
                <img
                    width="100%"
                    className="object-cover md:max-h-product-detail-img h-product-detail-img-mobile "
                    src={product.pictureUrl}
                    alt={`product ${product.name}`}
                />
            </div>

            {/* Product informations */}

            <div className="flex flex-col flex-1 w-full p-5 font-poppins no-scrollbar overflow-y-auto! md:min-h-restaurant-product">
                <Paragraph className="text-xl font-semibold mb-3">
                    {product?.name ?? ""}
                </Paragraph>

                <Paragraph className="text-sm font-normal">
                    {parse(product?.description ?? "") as string}
                </Paragraph>

                {productTags?.data && productTags.data.length > 0 && (
                    <div className="w-full flex justify-start">
                        <div className="pt-6 flex flex-wrap">
                            {productTags?.data?.map((tag) => (
                                <ProductTag
                                    key={tag?.id}
                                    title={tag.label.toUpperCase()}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* Product 'Add To Basket' Section */}
            {showQuantity && (
                <div
                    className={`md:flex-none flex flex-col w-full bg-white justify-center space-y-5 px-5 border-t border-line overflow-scroll no-scrollbar`}
                    style={{
                        minHeight: "128px",
                        paddingBottom:
                            Capacitor.getPlatform() !== "web"
                                ? insets?.bottom
                                : undefined,
                    }}
                >
                    <div
                        className="flex justify-center items-center"
                        style={{
                            marginTop:
                                Capacitor.getPlatform() !== "web"
                                    ? "20px"
                                    : undefined,
                        }}
                    >
                        <div
                            className="flex cursor-pointer"
                            onClick={handleOnMinus}
                        >
                            <Minus width="24px" height="24px" />
                        </div>
                        <Paragraph className="w-10 flex justify-center text-lg font-bold text-dark-blue px-13">
                            {quantity}
                        </Paragraph>
                        <div
                            className="flex cursor-pointer"
                            onClick={() => setQuantity(quantity + 1)}
                        >
                            <Plus width="24px" height="24px" />
                        </div>
                    </div>
                    <div>
                        <AddToBasket
                            productPrice={(
                                (product?.unitPrice ?? 0) * quantity
                            ).toFixed(2)}
                            handleClick={debounce(handleConfirm, 1000, {})}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductOverlay;
