import CardOrderDetailProps from "./props";

export default function CardOrderDetail({
    pictureUrl,
    firstContent,
    secondContent,
    onClickDisabled,
}: CardOrderDetailProps) {
    //Render
    return (
        <div
            style={{ height: 64 }}
            className={`flex flex-1 justify-start items-center shadow-card mb-4 rounded-lg overflow-hidden ${
                onClickDisabled ? "cursor-default" : "cursor-pointer"
            }`}
        >
            <img
                className="object-contain"
                src={pictureUrl}
                style={{
                    width: 64,
                    minWidth: 64,
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                }}
            />

            <div
                className="bg-white flex flex-1 flex-row items-center justify-between h-full px-3"
                style={{
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8,
                    maxHeight: 64,
                }}
            >
                <div className="flex flex-col md:text-start">
                    {firstContent}
                </div>

                {secondContent}
            </div>
        </div>
    );
}
