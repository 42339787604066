export interface PopupProps {
    isVisible: boolean;
    handleOnClose: (event: React.MouseEvent) => void;
    children: React.ReactElement;
    styleType?: PopUpStyleType;
}

export enum PopUpStyleType {
    Default = "default",
    FullScreen = "full-screen",
}
