import { CompanyResDTO } from "../new-types";

const modifyTimeOfLateOrderError = (
    company: CompanyResDTO | undefined,
    translate: (key: string) => string
) => {
    if (company) {
        let tmpMsg = "";
        tmpMsg += translate("KitchenTimingDetail")?.replace(
            "{{timeframe}}",
            `${company?.newKitchenClosingHourBegin} and after ${company?.newKitchenClosingHourEnd}`
        );
        return tmpMsg;
    }
    return "";
};

export default modifyTimeOfLateOrderError;
