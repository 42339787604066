import Button from "../../../../../../../../components/button";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import AddToBasketProp from "./props";

const Footer = ({ onClick, confirm }: AddToBasketProp) => {
    //Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <Button
            onClick={onClick}
            className={`min-w-full min-h-11! max-h-11! text-white flex justify-center items-center cursor-pointer font-normal text-base rounded-lg ${
                confirm ? "bg-custom-green" : "bg-black"
            }`}
            data-cy="noRecommandations"
        >
            {confirm
                ? translate("continue")
                : translate("recommendation.refuse")}
        </Button>
    );
};

export default Footer;
