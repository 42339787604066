import Button from "../../../../components/button";
import { EditProductPanelOverlay } from "../../overlay-context";
import { ReactComponent as Close } from "../../assets/cross-1.svg";
import { useTranslation } from "../../../translation/TranslationContext";
import { Capacitor } from "@capacitor/core";

export default function EditProductPanel({
    title,
    buttonText,
    onButtonClick,
    onClose,
    content,
    onlyContent,
}: EditProductPanelOverlay) {
    const { translate } = useTranslation();

    //Render
    return (
        <div
            role="dialog"
            aria-labelledby="side-panel-title"
            className="z-10 flex flex-col flex-grow bg-fifth overflow-y-auto md:m-8 md:ml-auto md:rounded-3xl md:w-1/4 md:min-w-side-panel md:max-w-side-panel md:max-h-product-overlay-panel"
        >
            <div
                className={`${
                    onlyContent ? "hidden" : "flex"
                } h-min flex-col p-6 shadow-sm bg-white`}
            >
                <div onClick={onClose} className="cursor-pointer bg-white">
                    <Button back />
                </div>

                <h1
                    id="side-panel-title"
                    className="text-xl font-semibold mt-4"
                >
                    {title}
                </h1>
            </div>

            <div className="flex flex-1 flex-col bg-fifth overflow-y-auto">
                <div className={`${onlyContent ? "flex" : "hidden"} w-full`}>
                    <div
                        className={`cursor-pointer flex flex-col w-full bg-white pr-6 ${
                            Capacitor.platform === "ios" ? "pt-8" : ""
                        }`}
                    >
                        <div
                            onClick={onClose}
                            className={`absolute right-0 ${
                                Capacitor.platform === "ios"
                                    ? "transform translate-y-2 mr-6 "
                                    : "mr-12 mt-4"
                            } ${
                                Capacitor.platform === "android"
                                    ? "mr-6! mt-4"
                                    : ""
                            }`}
                        >
                            <Close />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-1 overflow-y-auto">
                    {content}
                </div>
            </div>

            <div
                className={`m-6 ${onlyContent ? "hidden" : "flex"}`}
                onClick={onButtonClick}
            >
                <Button className="w-full bg-green-dark">
                    <span className="text-lg">
                        {buttonText
                            ? translate(buttonText)
                            : translate("confirm")}
                    </span>
                </Button>
            </div>
        </div>
    );
}
