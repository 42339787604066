import { MyAccountCardProps } from "./props";
import { ReactComponent as AVATAR } from "./assets/avatar.svg";

export default function MyAccountCard(props: MyAccountCardProps) {
    //Attributes
    const { fname, lname, email, pictureURL } = props;

    return (
        <div
            style={{ height: "156px" }}
            className="flex w-full ml-auto flex-col hover:bg-teal-500 bg-white rounded-lg shadow-lg mb-4 p-4"
        >
            <div className="flex w-full justify-center">
                <AVATAR className="flex justify-center w-full max-h-profile-avatar max-w-profile-avatar rounded-full h-full border-current border-2 md:w-20" />
            </div>
            <p className="flex justify-center text-sm font-poppins font-medium text-secondary">
                {`${fname} ${lname}`}
            </p>
            <p className="flex justify-center text-xs font-poppins font-normal text-third">
                {email}
            </p>
        </div>
    );
}
