import Modal from "react-modal";
import { PopupProps, PopUpStyleType } from "./props";
import { customStyles, fullscreenStyle } from "./styles";

function getStyle(type: PopUpStyleType) {
    switch (type) {
        case PopUpStyleType.FullScreen:
            return fullscreenStyle;
        default:
            return customStyles;
    }
}

const Popup = ({
    isVisible,
    handleOnClose,
    children,
    styleType = PopUpStyleType.Default,
}: PopupProps) => {
    return (
        <Modal
            isOpen={isVisible}
            onRequestClose={handleOnClose}
            style={getStyle(styleType)}
        >
            {children}
        </Modal>
    );
};

export default Popup;
