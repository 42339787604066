import React, { useState } from "react";
import Button from "../../../../../../components/button";
import Title from "../../../../../../components/title";
import { useProfile } from "../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import useMedia from "../../../../../../hooks/useMedia";
import { useAllUserPaymentMethodQuery } from "../../../../../../queries/userPaymentMethod";
import { PaymentMethodDTO } from "../../../../../../types/dtos/paymentMethod";
import Page from "../../../../components/page";
import PaymentCard from "../../../../components/payment-card";
import Detail from "../../modules/detail";
import BackCard from "../back-card";
import { loadStripe } from "@stripe/stripe-js";
import UserPaymentService from "../../../../../../services/UserPaymentService";
import BusyIndicator from "../../../../../../components/busy-indicator";
import NightbornBrand from "../../../../../../components/branding";

const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY ?? "");

export default function PaymentMethodPanel() {
    //Attributes
    const { translate } = useTranslation();
    const { user } = useProfile();
    const userPaymentMethodQuery = useAllUserPaymentMethodQuery(user.id);
    const [disabled, setDisabled] = useState(false);

    //UseMedia
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Functions
    const onButtonClick = async () => {
        setDisabled(true);

        const stripe = await stripePromise;

        const response = await UserPaymentService.post(user.id);

        if (stripe) {
            const result = await stripe.redirectToCheckout({
                sessionId: response.data,
            });
            setDisabled(false);
        }
    };

    return (
        <>
            <BackCard title="paymentMethod" />

            <Page
                tabBarVisible={currentMediaQuery === 1 ? true : false}
                className="flex md:px-4 px-4 pt-4 md:pt-24 md:mb-0 md:mx-auto flex-1 md:justify-between"
                isBasketPanelVisible={false}
                eventBannerVisible={false}
            >
                <div className="md:flex md:max-h-payment-method-panel">
                    <div className="md:block hidden md:flex-3">
                        <Detail showTabBar={false} paymentMethodActive />
                    </div>

                    <div className="md:flex-1 md:p-4 flex flex-col md:h-full md:bg-white md:rounded-lg md:shadow-lg md:max-w-sm md:min-w-custom-sm md:max-h-payment-method-panel overflow-hidden">
                        <Title className="md:block hidden text-profile-panel-title">
                            paymentMethod
                        </Title>

                        <BusyIndicator query={userPaymentMethodQuery}>
                            <Title
                                className={`text-sm! text-custom-green mb-2! uppercase ${
                                    userPaymentMethodQuery.data?.length ?? 0 > 0
                                        ? ""
                                        : "text-center font-light!"
                                }`}
                            >
                                {userPaymentMethodQuery.data?.length ?? 0 > 0
                                    ? "myPaymentMethods"
                                    : "currentlyNoPaymentMethod"}
                            </Title>
                            <div className="overflow-y-auto h-full">
                                {userPaymentMethodQuery.data?.map(
                                    (elt: PaymentMethodDTO) => (
                                        <div className="py-2">
                                            <PaymentCard
                                                last4={elt.card.last4}
                                                brand={elt.card.brand}
                                                paymentMethodId={elt.id}
                                            />
                                        </div>
                                    )
                                )}
                            </div>

                            <Button
                                disabled={disabled}
                                onClick={onButtonClick}
                                className={`${
                                    currentMediaQuery === 2 ? "hidden" : ""
                                } min-w-full mt-4 mb-0`}
                            >
                                <span className="text-lg">
                                    {translate("addPaymentMethod")}
                                </span>
                            </Button>
                        </BusyIndicator>
                    </div>
                </div>
                <Button
                    disabled={disabled}
                    onClick={onButtonClick}
                    className={`${
                        currentMediaQuery === 2 ? "" : "hidden"
                    } min-w-full mt-auto mb-6`}
                >
                    <span className="text-lg">
                        {translate("addPaymentMethod")}
                    </span>
                </Button>
                {currentMediaQuery === 1 && (
                    <div className="md:pb-6">
                        <NightbornBrand />
                    </div>
                )}
            </Page>
        </>
    );
}
