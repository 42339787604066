import React from "react";
import { CommentSidePanelOverlay } from "../../overlay-context";
import { ReactComponent as Close } from "../../assets/cross.svg";
import TabBar from "../../../../modules/company/components/tab-bar";
import { Capacitor } from "@capacitor/core";
import { isIosOrAndroid } from "../../../../utils/isIosOrAndroid";
import { EventTypeEnum } from "../../../../types/enums/event-type";
import EventTabBar from "../../../../modules/company/components/event-tab-bar";
import { useEvent } from "../../../event";

const plat = Capacitor.platform;

export default function CommentSidePanel({
    onClose,
    content,
}: CommentSidePanelOverlay) {
    //Attributes
    const { event } = useEvent();

    //Render
    return (
        <>
            <div
                role="dialog"
                aria-labelledby="side-panel-title"
                className="z-10 no-scrollbar flex flex-col flex-grow bg-fifth overflow-y-auto md:min-w-side-panel md:m-8 md:ml-auto md:rounded-3xl md:w-1/4 md:max-w-side-panel md:max-h-product-overlay-panel"
            >
                <div
                    className="h-24 p-6 pb-0 md:shadow-sm md:bg-white"
                    style={{
                        paddingTop:
                            plat === "ios" ? 50 : plat === "android" ? 40 : 24,
                        height: isIosOrAndroid(plat) ? 114 : 96,
                    }}
                >
                    <div className="flex justify-end cursor-pointer">
                        <button onClick={onClose}>
                            <Close />
                        </button>
                    </div>

                    <h1
                        id="side-panel-title"
                        className="text-2xl font-semibold mt-4 mb-0 text-custom-green"
                    >
                        Add Comments
                    </h1>
                </div>

                <div className="flex flex-1 flex-col p-6 bg-fifth overflow-y-auto">
                    {content}
                </div>

                <div className="hidden">
                    {event.id && event.type === EventTypeEnum.Public ? (
                        <EventTabBar />
                    ) : (
                        <TabBar />
                    )}
                </div>
            </div>
        </>
    );
}
