import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TranslationContextProvider } from "./contexts/translation";

import translations from "./i18n/translations.json";
import AlertContainer from "./components/alert";

import Shell from "./modules";

import "./App.css";
import AppUrlListener from "./components/url-listener";
import { Capacitor, Plugins } from "@capacitor/core";
import { isMobile } from "react-device-detect";

import {
    AppTrackingStatusResponse,
    AppTrackingTransparencyPlugin,
} from "capacitor-plugin-app-tracking-transparency";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { QueryClient, QueryClientProvider } from "react-query";
import SafeAreaContextProvider from "./contexts/safe-area";
import { createClient, AnalyticsProvider } from "@segment/analytics-react";
import OdooChatbot from "./components/odoo_bot";
import useMedia from "./hooks/useMedia";

export default function App() {
    //Attributes
    const TRACKING_ID = "G-0X0K4NZF5Y"; // YOUR_OWN_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    const tagManagerArgs = {
        gtmId: "GTM-596QZMV",
    };

    TagManager.initialize(tagManagerArgs);
    const AppTrackingTransparency =
        Plugins.AppTrackingTransparency as AppTrackingTransparencyPlugin;

    const segmentClient = createClient({
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY ?? "",
    });
    // APP_TRACKING
    useEffect(() => {
        if (Capacitor.getPlatform() === "ios") {
            getStatus();
            requestPermission();
        }
    }, []);

    //Function
    async function getStatus(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.getStatus();
        return response;
    }

    async function requestPermission(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.requestPermission();
        return response;
    }

    //To disable right click on mobile
    if (
        Capacitor.getPlatform() === "android" ||
        Capacitor.getPlatform() === "ios" ||
        isMobile
    ) {
        document.addEventListener("contextmenu", (event) =>
            event.preventDefault()
        );
    }

    const queryClientRef = React.useRef();
    if (!queryClientRef.current) {
        // @ts-ignore
        queryClientRef.current = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnMount: true,
                    refetchOnReconnect: false,
                    refetchOnWindowFocus: false,
                },
            },
        });
    }

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Render
    return (
        <>
            <AnalyticsProvider client={segmentClient}>
                <QueryClientProvider
                    client={queryClientRef.current ?? ({} as QueryClient)}
                >
                    <BrowserRouter>
                        <AppUrlListener />
                        {/* NOW USING ODOO LIVE CHAT INSTEAD OF LANDBOT */}
                        {/* <Landbot /> */}
                        <OdooChatbot isVisible={currentMediaQuery !== 2} />
                        <TranslationContextProvider translations={translations}>
                            <SafeAreaContextProvider>
                                <Switch>
                                    <Route path="/" component={Shell} />
                                </Switch>
                                <AlertContainer />
                            </SafeAreaContextProvider>
                        </TranslationContextProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </AnalyticsProvider>
        </>
    );
}
