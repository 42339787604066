// modules
import React from "react";
import useMedia from "../../hooks/useMedia";
import Props from "./props";

// style
import "./style.css";

const Box = (props: Props): JSX.Element => {
    // Attributes
    const { children, className = "" } = props;
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Methods
    if (currentMediaQuery === 2) {
        return (
            <div
                className={`p-4 mx-6 my-16 h-full bg-white bg-cover  rounded-2xl ${className}`}
            >
                {children}
            </div>
        );
    }

    // Render
    return (
        <div
            className={`max-w-lg p-10 md:m-auto bg-white bg-cover  w-full rounded-2xl ${className}`}
        >
            {children}
        </div>
    );
};

export default Box;
