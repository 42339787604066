import React, { useEffect } from "react";
import OrderInformationCard from "../order-information-card";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as SELECTED } from "../../assets/selected.svg";
import { OrderTimingType } from "../../../../../../types/enums/order-timing-type";
import { useOrder } from "../../../../../../contexts/order";
import { useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import BusyIndicator from "../../../../../../components/busy-indicator";

const OrderTiming = () => {
    //Attributes
    const { order, dispatch } = useOrder();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);

    //Effects
    useEffect(() => {
        dispatch({
            payload:
                companyQuery?.orderTimingType === OrderTimingType.Both
                    ? OrderTimingType.Noon
                    : companyQuery?.orderTimingType,
            target: "orderTimingType",
            type: "userChanged",
        });
        dispatch({
            payload:
                companyQuery?.orderTimingType === OrderTimingType.Both
                    ? OrderTimingType.Noon
                    : companyQuery?.orderTimingType,
            target: "orderTimingType",
            type: "userChanged",
        });
    }, [companyQuery]);

    // Function
    function handleChanged(type: OrderTimingType) {
        if (companyQuery?.orderTimingType !== OrderTimingType.Both) return;
        dispatch({
            payload: type,
            target: "orderTimingType",
            type: "userChanged",
        });
    }

    //Render
    return (
        <BusyIndicator query={companyQuery}>
            <OrderInformationCard icon={<Clock />} title="Order timing">
                <div className="px-4 py-2">
                    <div className="flex flex-col flex-1">
                        <div
                            className={`flex flex-row flex-1 justify-between my-4 ${
                                companyQuery?.orderTimingType !==
                                OrderTimingType.Both
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                            }`}
                            onClick={() => handleChanged(OrderTimingType.Noon)}
                        >
                            <span className="text-sm font-semibold">
                                Order before 11am - Delivered at 12pm
                            </span>
                            <SELECTED
                                className={`${
                                    order.orderTimingType ===
                                    OrderTimingType.Noon
                                        ? "opacity-100"
                                        : "opacity-0"
                                }`}
                            />
                        </div>

                        <div
                            className={`flex flex-row flex-1 justify-between my-4 ${
                                companyQuery?.orderTimingType !==
                                OrderTimingType.Both
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                            }`}
                            onClick={() =>
                                handleChanged(OrderTimingType.Evening)
                            }
                        >
                            <span className="text-sm font-semibold">
                                Order before 5pm - Delivered at 6pm
                            </span>
                            <SELECTED
                                className={`${
                                    order.orderTimingType ===
                                    OrderTimingType.Evening
                                        ? "opacity-100"
                                        : "opacity-0"
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </OrderInformationCard>
        </BusyIndicator>
    );
};

export default OrderTiming;
