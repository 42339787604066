import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import Lottie from "../../../../../../components/lottie";
import { useProfile } from "../../../../../../contexts/profile";
import { useGetOrderById } from "../../../../../../go-services/order/order";
import { OrderResDTO, OrderStatus } from "../../../../../../new-types";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useAnalytics } from "@segment/analytics-react";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./assets/loading-foodiz.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function LoadingOrder() {
    //Attributes
    const history = useHistory();
    const { user } = useProfile();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { orderId } = useParams<any>();
    const { track } = useAnalytics();

    //Queries
    useGetOrderById(user.employeeId, orderId, {
        query: {
            onSuccess: (data) => {
                console.log("order", data);
                switch (data?.status) {
                    case OrderStatus.CLOSED:
                        handleTrackOrderCompletedEvent(data);
                        history.push(`/${companyName}/order-overview/success`);
                        return;
                    default:
                        history.push(`/${companyName}/order-overview`);
                        return;
                }
            },
            onError: (error) => {
                console.log("error", error);
                history.push(`/${companyName}/order-overview`);
                return;
            },
        },
    });

    //Function
    function handleTrackOrderCompletedEvent(order: OrderResDTO) {
        if (!order) return;
        if (!order.basketProducts) return;

        const total = order.basketProducts.reduce((total, product) => {
            const adjustedAmount =
                product.baseAmount ?? 0 * (product.indexedPrice ?? 100 / 100);
            const productTotal =
                adjustedAmount * (product.quantity ?? 1) -
                (product?.refundAmount ?? 0);
            return total + productTotal;
        }, 0);
        track("Order Completed", {
            order_id: order?.id,
            coupon: order?.promoCode?.id,
            total: total,
        });
    }

    //Render
    return (
        <div className="flex flex-1">
            <div className="flex m-auto">
                <Lottie options={defaultOptions} height={400} width={400} />
            </div>
        </div>
    );
}
