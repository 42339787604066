import { ComponentType, useMemo } from "react";
import { ReactComponent as Checkmark } from "../../assets/checkmark.svg";
import { ReactComponent as Error } from "../../assets/error.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
import { InlineMessageProps } from "./props";
import { className } from "../../../../../../lib/formatters";

const ICON_MAP: Record<InlineMessageProps["type"], ComponentType> = {
    error: Error,
    success: Checkmark,
};

export default function InlineMessage({
    type,
    onCloseClick,
    children,
    extraClassName,
}: InlineMessageProps) {
    const Icon = useMemo(() => ICON_MAP[type], [type]);

    return (
        <div
            className={`flex bg-white rounded-lg shadow-lg p-4 mb-4 ${extraClassName}`}
        >
            <Icon />

            <p
                className={className("flex-grow", "text-sm", "mx-4", {
                    "text-green-light": type === "success",
                    "text-red-light": type === "error",
                })}
            >
                {children}
            </p>

            <button onClick={onCloseClick}>
                <Close />
            </button>
        </div>
    );
}
