import { useHistory, useParams } from "react-router";
import BusyIndicator from "../../../../components/busy-indicator";
import Title from "../../../../components/title";
import { useProfile } from "../../../../contexts/profile";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import { ReactComponent as EmptyBasket } from "./assets/empty.svg";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import Paragraph from "../../../../components/paragraph";
import Button from "../../../../components/button";
import { useOverlay } from "../../../../contexts/overlay/overlay-context";
import NewBasketProductCard from "./components/new-basket-product-card";
import {
    getGetBasketProductsQueryKey,
    useDeleteBasketProduct,
    useGetBasketProducts,
    useUpdateBasketProduct,
} from "../../../../go-services/basket-product/basket-product";
import { useQueryClient } from "react-query";
import { BasketProductResDTO, ProductResDTO } from "../../../../new-types";
import { getProductPrice } from "../../../../utils/getProductPrice";
import ProductOverlay from "../home/components/product-overlay";
import useMedia from "../../../../hooks/useMedia";
import { Capacitor } from "@capacitor/core";
import { useSafeArea } from "../../../../contexts/safe-area";
import { useOneCompanyQuery } from "../../../../queries/company";
import { useOneBasketQuery } from "../../../../queries/basket";
import { QueryKey } from "../../../../types/enums/query-key";
import * as Sentry from "@sentry/react";

function Basket() {
    //Attributes
    const { insets } = useSafeArea();
    const queryClient = useQueryClient();
    const history = useHistory();
    const { user } = useProfile();
    const { removeCurrentOverlay, showSidePanel } = useOverlay();
    const { translate } = useTranslation();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Queries
    const { mutateAsync: updateBasketProduct } = useUpdateBasketProduct();
    const { mutateAsync: deleteBasketProduct } = useDeleteBasketProduct();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket, isLoading: isBasketLoading } =
        useOneBasketQuery(companyId!, user?.employeeId);
    const { data: basketProducts, isLoading: isBasketProductsLoading } =
        useGetBasketProducts(user?.employeeId, currentBasket?.id ?? "", {
            query: { enabled: !!user?.employeeId && !!currentBasket?.id },
        });

    history.listen(() => {
        removeCurrentOverlay();
    });

    //Functions
    const handleGoBack = () => {
        removeCurrentOverlay();
        if (history.location.pathname !== `/${companyName}`) {
            history.push(`/${companyName}`);
        }
    };

    const handleAddProduct = (
        basketProduct: BasketProductResDTO,
        updatedQuantity: number
    ) => {
        updateBasketProduct(
            {
                employeeId: user?.employeeId,
                basketId: currentBasket?.id ?? "",
                productId: basketProduct?.product?.id ?? "",
                data: { quantity: updatedQuantity },
            },
            {
                onSuccess: handleBasketUpdateSuccess,
                onError: (e) => handleBasketUpdateError(e, basketProduct),
            }
        );
    };

    // Handlers
    const handleRemoveProduct = (
        basketProduct: BasketProductResDTO,
        updatedQuantity: number
    ) => {
        if (updatedQuantity === 0) {
            deleteBasketProduct(
                {
                    employeeId: user?.employeeId ?? "",
                    basketId: basketProduct?.basketId ?? "",
                    productId: basketProduct?.product?.id ?? "",
                },
                {
                    onSuccess: handleBasketUpdateSuccess,
                    onError: (e) => handleBasketDeleteError(e, basketProduct),
                }
            );
            return;
        }

        updateBasketProduct(
            {
                employeeId: user?.employeeId,
                basketId: basketProduct?.basketId ?? "",
                productId: basketProduct?.product?.id ?? "",
                data: { quantity: updatedQuantity },
            },
            {
                onSuccess: handleBasketUpdateSuccess,
            }
        );
    };

    const handleProductClick = (product: ProductResDTO) => {
        // product panel
        showSidePanel({
            title: "",
            content: <ProductOverlay product={product} showQuantity={false} />,
            onlyContent: true,
            overflow: currentMediaQuery === 2 ? false : true,
            animate: currentMediaQuery === 2,
        });
    };

    // Success handler
    const handleBasketUpdateError = (
        error: any,
        basketProduct: BasketProductResDTO
    ) => {
        Sentry.captureException(
            `An Error occured when updating the basket from this user : ${user.id} in the basket : ${currentBasket?.id} and product : ${basketProduct?.product?.id}`,
            error
        );
    };
    const handleBasketDeleteError = (
        error: any,
        basketProduct: BasketProductResDTO
    ) => {
        Sentry.captureException(
            `An Error occured when deletiong the basket from this user : ${user.id} in the basket : ${currentBasket?.id} and product : ${basketProduct?.product?.id}`,
            error
        );
    };

    const handleBasketUpdateSuccess = () => {
        // Refetch basket
        queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
        queryClient.invalidateQueries(
            getGetBasketProductsQueryKey(
                user?.employeeId ?? "",
                currentBasket?.id ?? ""
            )
        );
    };

    if (basketProducts?.length === 0) {
        return (
            <div className="flex flex-col flex-1">
                <div
                    style={{ transform: "translate(0px, -5.5px)" }}
                    className="flex flex-col flex-1 items-center justify-center"
                >
                    <EmptyBasket />
                    <div>
                        <Title
                            style={{ color: "#222B45" }}
                            className="text-center text-2xl! mt-16 mb-5!"
                        >
                            emptyBasket
                        </Title>
                        <div className="flex justify-center">
                            <Paragraph
                                style={{ color: "#222B45" }}
                                className="text-lg w-40 text-center"
                            >
                                {translate("emptyBasketSub")}
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        paddingBottom:
                            Capacitor.getPlatform() === "ios"
                                ? insets?.bottom
                                : undefined,
                    }}
                >
                    <Button
                        className={`min-w-full bg-add-button  min-h-11! max-h-11!`}
                        onClick={handleGoBack}
                    >
                        {translate("goBackHome")}
                    </Button>
                </div>
            </div>
        );
    }

    //Render
    return (
        <BusyIndicator
            isLoading={isBasketLoading || isBasketProductsLoading}
            queries={[]}
        >
            <div className="flex flex-col space-y-4">
                {basketProducts?.map((basketProduct) => (
                    <NewBasketProductCard
                        key={basketProduct?.id}
                        onClick={() =>
                            handleProductClick(basketProduct?.product)
                        }
                        onAddProduct={(updatedQuantity) =>
                            handleAddProduct(basketProduct, updatedQuantity)
                        }
                        onRemoveProduct={(updatedQuantity) =>
                            handleRemoveProduct(basketProduct, updatedQuantity)
                        }
                        name={basketProduct?.product?.name ?? ""}
                        pictureUrl={basketProduct?.product?.pictureUrl ?? ""}
                        initialQuantity={basketProduct?.quantity ?? 0}
                        price={getProductPrice({ ...basketProduct })}
                    />
                ))}
            </div>
        </BusyIndicator>
    );
}

export default Basket;
