import Paragraph from "../../../../../../components/paragraph";
import { useSafeArea } from "../../../../../../contexts/safe-area";
import SignInAndSignUpModalProps from "./props";
import { useHistory } from "react-router";
import {
    AuthRoute,
    FindCompanyRouteParams,
} from "../../../../../../types/params/find-company-route";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";

const SignInAndSignUpModal = ({
    productPictureUrl,
}: SignInAndSignUpModalProps) => {
    //Attributes
    const { insets } = useSafeArea();
    const history = useHistory();
    const { translate } = useTranslation();

    // Functions
    function handleOnClick(redirectRoute: AuthRoute) {
        history.push({
            pathname: `/find-company`,
            state: {
                redirectRoute,
            } as FindCompanyRouteParams,
        });
    }

    //Render
    return (
        <div className="flex flex-col flex-1 bg-white p md:max-h-product-overlay-panel max-h-screen! rounded-2xl overflow-y-auto! no-scrollbar">
            {/*  Product Image */}
            <div
                className="flex-none"
                style={{
                    maxHeight:
                        Capacitor.getPlatform() === "android"
                            ? "318px"
                            : undefined,
                }}
            >
                <img
                    className="w-full object-contain"
                    src={productPictureUrl}
                    alt={`product`}
                />
            </div>

            {/* Product informations */}
            <div className="flex flex-col flex-1 justify-center items-center p-5 px-10 overflow-y-auto! no-scrollbar md:min-h-restaurant-product">
                <Paragraph className="text-xl font-semibold mb-3">
                    {"Welcome on Foodiz!"}
                </Paragraph>

                <Paragraph className="text-sm ml-5 justify-center items-center font-poppins font-light w-full text-center">
                    {
                        "To start ordering your food, Sign in to your account or create your account"
                    }
                </Paragraph>
            </div>

            {/* Sign up and Sign in Buttons */}
            <div
                className={`flex flex-col flex-1 bg-white justify-end space-y-3 max-h-48 md:h-48 p-5 md:mb-8`}
            >
                <div
                    className="flex justify-center items-center bg-black t rounded-lg px-3 py-2.5 cursor-pointer"
                    onClick={() => {
                        handleOnClick(AuthRoute.SIGN_UP);
                    }}
                >
                    <Paragraph className="text-white font-poppins text-base">
                        {translate("FirstTimeAccount")}
                    </Paragraph>
                </div>
                <div
                    className="flex justify-center items-center bg-black rounded-lg px-3 py-2.5 cursor-pointer"
                    onClick={() => {
                        handleOnClick(AuthRoute.SIGN_IN);
                    }}
                >
                    <Paragraph className="text-white font-poppins text-base">
                        {translate("AlreadyAnAccount")}
                    </Paragraph>
                </div>
            </div>
        </div>
    );
};

export default SignInAndSignUpModal;
