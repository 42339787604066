import { useState } from "react";
import PaymentCardProps from "./props";
import { ReactComponent as VISA } from "../../../../assets/visa.svg";
import { ReactComponent as DELETE } from "../../../../assets/delete.svg";
import { ReactComponent as MASTERCARD } from "./assets/mastercard.svg";
import { ReactComponent as AMEX } from "./assets/amex.svg";
import DeletePaymentMethodModal from "../../modules/profile/components/delete-payment-method-modal";
import { displayMinimalAmount } from "../../../../utils/minimalAmount";

const PaymentCard = ({
    last4,
    brand,
    paymentMethodId,
    className,
    isSelected,
    showDelete,
}: PaymentCardProps) => {
    //Attributes
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    let icon;

    switch (brand) {
        case "visa":
            icon = <VISA />;
            break;
        case "mastercard":
            icon = <MASTERCARD />;
            break;
        case "amex":
            icon = <AMEX />;
            break;
        default:
            break;
    }

    //Functions
    const handleDelete = () => {
        setOpenDeleteModal(true);
    };

    //Render
    return (
        <div
            style={{ border: "1px solid rgba(237, 241, 247, 0.5)" }}
            className={`flex flex-col justify-between w-full bg-white rounded-lg ${className}`}
        >
            <div className="flex">
                <div className="my-auto self-center">{icon}</div>
                <div>
                    <div className="flex pl-4 items-center">
                        {Array.from({ length: 4 })
                            .fill(null)
                            .map((_, i) => (
                                <div
                                    key={i}
                                    className={`h-2 w-2 mr-1 bg-secondary rounded-full ${
                                        i == 3 || i == 7 ? "mr-2" : ""
                                    }`}
                                />
                            ))}
                        <p className="text-sm text-secondary">{last4}</p>
                    </div>
                    <p
                        className={`text-xs ${
                            isSelected
                                ? "text-text-subbody"
                                : "text-text-placeholder"
                        } pl-4`}
                    >
                        {displayMinimalAmount()}
                    </p>
                </div>
            </div>
            <div
                onClick={handleDelete}
                style={{ minHeight: 42, minWidth: 42 }}
                className={`${
                    showDelete === false ? "hidden" : ""
                } bg-gray-lightest flex hover:animate-zoom rounded-md cursor-pointer`}
            >
                <DELETE
                    style={{ minHeight: 24, minWidth: 24 }}
                    className="hover:animate-zoom m-auto my-auto active:animate-dezoom"
                />
            </div>
            {openDeleteModal === true && (
                <DeletePaymentMethodModal
                    isVisible={openDeleteModal}
                    handleOnClose={() => setOpenDeleteModal(false)}
                    paymentMethodId={paymentMethodId}
                />
            )}
        </div>
    );
};

export default PaymentCard;
