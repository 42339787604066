import React from "react";
import ParagraphProps from "./props";

function Paragraph({ children, ...rest }: ParagraphProps) {
    return (
        <p className={`${rest.className} text-xs font-poppins`} {...rest}>
            {children}
        </p>
    );
}

export default Paragraph;
