import React, { useCallback } from "react";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { BackCardProps } from "./props";
import { ReactComponent as Arrow } from "../../assets/arrow-left.svg";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

export default function BackCard({ title }: BackCardProps) {
    //Attributes
    const plat = Capacitor.platform;
    const { translate } = useTranslation();
    const history = useHistory();

    //Functions
    const handleOnClick = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <div
            className="md:hidden min-w-full h-16 flex items-center justify-between flex-row bg-white shadow-lg"
            onClick={handleOnClick}
            style={{
                cursor: "pointer",
                paddingTop: plat === "ios" ? 30 : 0,
            }}
        >
            <div className="w-12! h-8! flex items-center pl-4">
                <Arrow className="" />
            </div>
            <p className="text-secondary justify-start font-semibold text-base mx-4 pt-4 pb-4 w-full">
                {translate(title)}
            </p>
            <Arrow className="opacity-0 w-12! h-8!" />
        </div>
    );
}
