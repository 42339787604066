import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import Title from "../../../../../../components/title";
import { useProfile } from "../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import useMedia from "../../../../../../hooks/useMedia";
import Service from "../../../../../../services/Service";
import UserService from "../../../../../../services/UserService";
import { UserDTO } from "../../../../../../types/dtos/user";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import Page from "../../../../components/page";
import Detail from "../../modules/detail";
import BackCard from "../back-card";
import * as Sentry from "@sentry/react";

import NightbornBrand from "../../../../../../components/branding";

export default function ChangePasswordPanel() {
    //Attributes
    const { translate } = useTranslation();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const history = useHistory();
    const { user, dispatch } = useProfile();
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

    const [disabled, setDisabled] = useState(false);

    //UseMedia
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Functions
    const onButtonClick = async () => {
        setDisabled(true);
        if (
            currentPassword === "" ||
            newPassword === "" ||
            confirmNewPassword === ""
        ) {
            Service.errors.push("Fill all fields");
            setDisabled(false);
            return;
        }

        if (newPassword !== confirmNewPassword) {
            Service.errors.push(
                "Confirm password doesn't match the new Password"
            );
            setDisabled(false);
            return;
        }

        const newUser: UserDTO = {
            ...user,
            password: newPassword,
            oldPassword: currentPassword,
        };

        const response = await UserService.put(newUser).catch((e) => {
            setDisabled(false);
            Service.errors.push("passwordNotUpdated");
            Sentry.captureException(
                `An Error occred when updating the password from user ${user.id} : `,
                e
            );
        });

        if (response) {
            setDisabled(false);
            if (response.status != 200) {
                Service.errors.push("passwordNotUpdated");
                return;
            }
            history.replace(`/${companyName}/profile`);
        }
    };

    return (
        <>
            <BackCard title="changePassword" />
            <Page
                tabBarVisible={currentMediaQuery === 1 ? true : false}
                className="px-4 pt-4 md:pt-24 md:mb-0 md:mx-auto md:justify-between"
                isBasketPanelVisible={false}
                eventBannerVisible={false}
            >
                <div className="md:flex">
                    <div className="md:block hidden md:flex-3">
                        <Detail showTabBar={false} changePasswordActive />
                    </div>

                    <div className="md:flex-1 flex flex-col md:h-full md:bg-white md:rounded-lg md:shadow-lg md:max-w-sm md:min-w-custom-sm md:p-4">
                        <Title className="md:block hidden text-profile-panel-title">
                            changePassword
                        </Title>

                        <Input
                            value={currentPassword}
                            onChange={(event) => setCurrentPassword(event)}
                            type={"password"}
                            label={translate("currentPassword")}
                            placeholder={translate("currentPassword")}
                        />

                        <Input
                            value={newPassword}
                            onChange={(event) => setNewPassword(event)}
                            type={"password"}
                            className=""
                            label={translate("newPassword")}
                            placeholder={translate("newPassword")}
                        />

                        <Input
                            value={confirmNewPassword}
                            onChange={(event) => setConfirmNewPassword(event)}
                            type={"password"}
                            className=""
                            label={translate("confirmNewPassword")}
                            placeholder={translate("confirmNewPassword")}
                        />

                        <Button
                            disabled={disabled}
                            onClick={onButtonClick}
                            className={`${
                                currentMediaQuery === 2 ? "hidden" : ""
                            } min-w-full mt-auto`}
                        >
                            <span className="text-lg">
                                {translate("saveChanges")}
                            </span>
                        </Button>
                    </div>
                </div>
                <Button
                    disabled={disabled}
                    onClick={onButtonClick}
                    className={`${
                        currentMediaQuery === 2 ? "" : "hidden"
                    } min-w-full mt-auto mb-6`}
                >
                    <span className="text-lg">{translate("saveChanges")}</span>
                </Button>
                {currentMediaQuery === 1 && (
                    <div className="md:pb-6">
                        <NightbornBrand />
                    </div>
                )}
            </Page>
        </>
    );
}
