import React from "react";
import { useParams } from "react-router";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { ReactComponent as Location } from "../../assets/location.svg";
import OrderInformationCard from "../order-information-card";

export default function DeliveryAddressCard() {
    //Attributes
    const { translate } = useTranslation();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);

    return (
        <OrderInformationCard
            icon={<Location />}
            title={translate("deliveryAddress")}
        >
            <div className="p-4">
                <p className="text-sm font-semibold text-secondary">
                    {companyQuery?.address.streetName}{" "}
                    {companyQuery?.address.number}
                </p>

                <p className="text-xs font-semibold text-third">
                    {companyQuery?.address.zipCode},{" "}
                    {companyQuery?.address.cityName}
                </p>
            </div>
        </OrderInformationCard>
    );
}
