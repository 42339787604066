import React from "react";
import { useParams } from "react-router-dom";
import Title from "../../../../../../components/title";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useRestaurantsByCompany } from "../../../../../../queries/restaurant";
import { RestaurantType } from "../../../../../../types/enums/restaurant-type";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import Page from "../../../../components/page";
import EventBanner from "../../../../components/page/components/event-banner";
import CompanyHeader from "../../../listing/components/company-header";
import RestaurantCard from "../../../listing/components/restaurant-card";

export default function PrivateEvent() {
    //Attributes
    const { companyId: companyName } = useParams<CompanyRouteParams>();

    // Company Query
    const { data: company } = useOneCompanyQuery(companyName);
    const companyId = company?.id;

    // CompanyRestaurants Query
    const query = useRestaurantsByCompany(companyId as string);
    const eventRestaurants =
        (query &&
            query.data?.filter(
                (elt) =>
                    elt.isAssigned &&
                    (elt.restaurant.restaurantType === RestaurantType.B2B ||
                        elt.restaurant.restaurantType === RestaurantType.Both)
            )) ||
        [];

    //Render
    return (
        <Page
            tabBarVisible={true}
            isBasketPanelVisible={false}
            className="md:mb-0"
        >
            <EventBanner />
            <div className="md:min-h-company-header md:max-h-company-header">
                <CompanyHeader />
            </div>
            <div className="p-4 md:px-24 flex flex-col flex-1">
                <Title className="flex md:px-32 mb-0! mt-6 font-semibold text-custom-green">
                    ourRestaurants
                </Title>
                <div className="flex flex-wrap justify-center md:justify-start md:space-x-8 md:px-24">
                    {eventRestaurants.map((elt, index) => (
                        <RestaurantCard
                            index={index}
                            key={elt.id}
                            companyRestaurant={elt}
                        />
                    ))}
                </div>
            </div>
        </Page>
    );
}
