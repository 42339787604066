import React from "react";
import Translation from "../translation";
import TitleProps from "./props";

function Title({ className = "", children, ...rest }: TitleProps) {
    return (
        //Receves the color through params
        <h1
            className={`text-title font-poppins font-semibold mb-8 ${className}`}
            {...rest}
        >
            <Translation>{children}</Translation>
        </h1>
    );
}

export default Title;
