import { EventDTO } from "../types/dtos/event";
import { EventTypeEnum } from "../types/enums/event-type";
import Service from "./Service";

function post(companyId: string, eventDTO: EventDTO) {
    if (eventDTO.type === EventTypeEnum.Private) {
        eventDTO.maxAmount = 0;
    }

    return Service.api.post<EventDTO>(
        `/v1/api/companies/${companyId}/events`,
        eventDTO
    );
}

function get(companyId: string, eventId: string) {
    return Service.api.get<EventDTO>(
        `/v1/api/companies/${companyId}/events/${eventId}`
    );
}

function deleteEvent(companyId: string, eventId: string) {
    return Service.api.delete<EventDTO>(
        `/v1/api/companies/${companyId}/events/${eventId}`
    );
}

export default { post, get, deleteEvent };
