import React from "react";
import { PaymentModalProps } from "./props";
import { useProfile } from "../../../../../../contexts/profile";
import Popup from "../../../../../../components/popup";
import Title from "../../../../../../components/title";
import Button from "../../../../../../components/button";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import UserPaymentService from "../../../../../../services/UserPaymentService";
import { ReactComponent as DELETEICON } from "./assets/delete-icon.svg";
import Service from "../../../../../../services/Service";
import { useAllUserPaymentMethodQuery } from "../../../../../../queries/userPaymentMethod";

const DeletePaymentMethodModal = ({
    isVisible,
    handleOnClose,
    paymentMethodId,
}: PaymentModalProps) => {
    // Attributes
    const { translate } = useTranslation();
    const { user } = useProfile();
    const userPaymentMethodQuery = useAllUserPaymentMethodQuery(user.id);

    // Handler
    async function handleDelete() {
        const response = await UserPaymentService.deletePaymentMethod(
            user.id,
            paymentMethodId
        );

        if (response) {
            if (response.status != 200) {
                Service.errors.push("paymentMethodDeleteFailed");
            }
            handleOnClose();
            userPaymentMethodQuery.refetch();
        }
        // userPaymentMethodQuery.refetch();
    }
    return (
        <Popup isVisible={isVisible} handleOnClose={handleOnClose}>
            <div className="flex flex-1 flex-col items-center!">
                <DELETEICON className="mb-12" />
                <Title className="text-center max-w-sm text-sm! md:text-title!">
                    deletePaymentMethodConfirmation
                </Title>
                <div className="flex flex-1 flex-row ">
                    <Button
                        onClick={handleOnClose}
                        className="bg-cancel-background py-2 px-3 rounded-lg text-dark-blue! font-semibold md:py-4 md:px-10 "
                    >
                        {translate("cancel")}
                    </Button>
                    <div className="mr-3" />
                    <Button
                        onClick={handleDelete}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                    >
                        {translate("confirm")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default DeletePaymentMethodModal;
