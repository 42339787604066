import React, { useState } from "react";
import { NewBasketProductCardProps } from "./props";
import { ReactComponent as Minus } from "./assets/minus.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import Paragraph from "../../../../../../components/paragraph";

export default function NewBasketProductCard({
    onAddProduct,
    onRemoveProduct,
    name,
    pictureUrl,
    price,
    initialQuantity,
    onClick,
}: NewBasketProductCardProps) {
    // Attributes
    const [quantity, setQuantity] = useState<number>(initialQuantity ?? 0);

    // Handlers
    const handleMinus = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (quantity > 0) {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
            onRemoveProduct(newQuantity);
        }
    };

    const handlePlus = (e: React.MouseEvent) => {
        e.stopPropagation();
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
        onAddProduct(newQuantity);
    };

    // Render
    return (
        <div className="flex justify-start h-20! min-h-20 bg-white rounded-md border border-black overflow-hidden">
            <img
                className="max-h-20 max-w-20 object-cover border-r border-black cursor-pointer"
                alt="foodiz"
                src={pictureUrl}
                onClick={onClick}
            />
            <div className="flex flex-col justify-between flex-1 px-2 py-3">
                <div className="flex flex-col justify-between">
                    <div className="flex flex-row items-center justify-between">
                        <div className="text-xs truncate font-semibold flex-1">
                            {name}
                        </div>
                        <div className="text-secondary font-semibold text-xs leading-4 bg-white">
                            {price}
                        </div>
                    </div>
                </div>

                {/*  Quantity Add/Remove Buttons */}
                <div className="flex w-18 items-center justify-between">
                    <div className="cursor-pointer" onClick={handleMinus}>
                        <Minus />
                    </div>
                    <Paragraph className="text-base font-semibold text-black">
                        {quantity}
                    </Paragraph>
                    <div className="cursor-pointer" onClick={handlePlus}>
                        <Plus />
                    </div>
                </div>
            </div>
        </div>
    );
}
