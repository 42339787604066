/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    BasketResDTO,
    BasketReqUpdateDTO,
    ProductPagingResDTO,
    GetBasketSubProductsParams,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCurrentBasket = (employeeId: string, signal?: AbortSignal) => {
    return customInstance<BasketResDTO>({
        url: `/v1/api/employees/${employeeId}/current-basket`,
        method: "get",
        signal,
    });
};

export const getGetCurrentBasketQueryKey = (employeeId: string) => [
    `/v1/api/employees/${employeeId}/current-basket`,
];

export type GetCurrentBasketQueryResult = NonNullable<
    Awaited<ReturnType<typeof getCurrentBasket>>
>;
export type GetCurrentBasketQueryError = unknown;

export const useGetCurrentBasket = <
    TData = Awaited<ReturnType<typeof getCurrentBasket>>,
    TError = unknown
>(
    employeeId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getCurrentBasket>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetCurrentBasketQueryKey(employeeId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getCurrentBasket>>
    > = ({ signal }) => getCurrentBasket(employeeId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getCurrentBasket>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!employeeId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getEmployeeBaskets = (
    employeeId: string,
    signal?: AbortSignal
) => {
    return customInstance<BasketResDTO[]>({
        url: `/v1/api/employees/${employeeId}/baskets`,
        method: "get",
        signal,
    });
};

export const getGetEmployeeBasketsQueryKey = (employeeId: string) => [
    `/v1/api/employees/${employeeId}/baskets`,
];

export type GetEmployeeBasketsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getEmployeeBaskets>>
>;
export type GetEmployeeBasketsQueryError = unknown;

export const useGetEmployeeBaskets = <
    TData = Awaited<ReturnType<typeof getEmployeeBaskets>>,
    TError = unknown
>(
    employeeId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getEmployeeBaskets>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetEmployeeBasketsQueryKey(employeeId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getEmployeeBaskets>>
    > = ({ signal }) => getEmployeeBaskets(employeeId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getEmployeeBaskets>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!employeeId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createBasket = (employeeId: string) => {
    return customInstance<BasketResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets`,
        method: "post",
    });
};

export type CreateBasketMutationResult = NonNullable<
    Awaited<ReturnType<typeof createBasket>>
>;

export type CreateBasketMutationError = unknown;

export const useCreateBasket = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createBasket>>,
        TError,
        { employeeId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createBasket>>,
        { employeeId: string }
    > = (props) => {
        const { employeeId } = props ?? {};

        return createBasket(employeeId);
    };

    return useMutation<
        Awaited<ReturnType<typeof createBasket>>,
        TError,
        { employeeId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const updateBasketById = (
    employeeId: string,
    basketId: string,
    basketReqUpdateDTO: BasketReqUpdateDTO
) => {
    return customInstance<BasketResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: basketReqUpdateDTO,
    });
};

export type UpdateBasketByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateBasketById>>
>;
export type UpdateBasketByIdMutationBody = BasketReqUpdateDTO;
export type UpdateBasketByIdMutationError = unknown;

export const useUpdateBasketById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateBasketById>>,
        TError,
        { employeeId: string; basketId: string; data: BasketReqUpdateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateBasketById>>,
        { employeeId: string; basketId: string; data: BasketReqUpdateDTO }
    > = (props) => {
        const { employeeId, basketId, data } = props ?? {};

        return updateBasketById(employeeId, basketId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateBasketById>>,
        TError,
        { employeeId: string; basketId: string; data: BasketReqUpdateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const getBasketSubProducts = (
    employeeId: string,
    basketId: string,
    params?: GetBasketSubProductsParams,
    signal?: AbortSignal
) => {
    return customInstance<ProductPagingResDTO>({
        url: `/v1/api/employees/${employeeId}/baskets/${basketId}/sub-products`,
        method: "get",
        params,
        signal,
    });
};

export const getGetBasketSubProductsQueryKey = (
    employeeId: string,
    basketId: string,
    params?: GetBasketSubProductsParams
) => [
    `/v1/api/employees/${employeeId}/baskets/${basketId}/sub-products`,
    ...(params ? [params] : []),
];

export type GetBasketSubProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getBasketSubProducts>>
>;
export type GetBasketSubProductsQueryError = unknown;

export const useGetBasketSubProducts = <
    TData = Awaited<ReturnType<typeof getBasketSubProducts>>,
    TError = unknown
>(
    employeeId: string,
    basketId: string,
    params?: GetBasketSubProductsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getBasketSubProducts>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetBasketSubProductsQueryKey(employeeId, basketId, params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getBasketSubProducts>>
    > = ({ signal }) =>
        getBasketSubProducts(employeeId, basketId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getBasketSubProducts>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!(employeeId && basketId),
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};
