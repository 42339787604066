import { useQuery } from "react-query";
import RestaurantService from "../services/RestaurantService";
import { QueryKey } from "../types/enums/query-key";
import CompanyRestaurantService from "../services/CompanyRestaurantService";

export function useOneRestaurantQuery(restaurantId: string) {
    return useQuery(
        [QueryKey.Restaurants, { id: restaurantId }],
        () => RestaurantService.get(restaurantId).then((res) => res.data),
        { enabled: !!restaurantId }
    );
}

export function useRestaurantsByCompany(companyId: string) {
    return useQuery(
        [QueryKey.Restaurants, { companyId }],
        () => CompanyRestaurantService.get(companyId).then((res) => res.data),
        { enabled: !!companyId }
    );
}
