import React from "react";
import Button from "../../components/button";
import Paragraph from "../../components/paragraph";
import Title from "../../components/title";

function NotFound() {
    // Rendering
    return (
        <div className="p-4 h-full text-center">
            <Title>YouAreLostMyFriend</Title>

            <Paragraph className="mt-16">HeyYouGetBackInTrack</Paragraph>

            <a href="https://foodiz.be" className="flex justify-start mt-12">
                <Button className="w-full">GetBackInTrack</Button>
            </a>
        </div>
    );
}

export default NotFound;
