import { PromoBannerProps } from "./props";

export default function PromoBanner(props: PromoBannerProps) {
    //Attributes
    const { sourceUri } = props;

    if (!sourceUri) return <></>;

    //Render
    return (
        <div
            className="h-mobile-promo-banner! md:h-promo-banner! cursor-pointer"
            onClick={() =>
                window.open("https://forms.gle/5PeEZVEyEhvtAGPr5", "_blank")
            }
        >
            <img
                src={sourceUri}
                className="h-mobile-promo-banner! md:h-promo-banner! w-full object-fill"
            />
        </div>
    );
}
