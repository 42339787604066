import React, { useEffect, useState } from "react";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import Loading from "./loading";
import NotFound from "./not-found";
import Company from "./company";
import ProfileContextProvider from "../contexts/profile";
import BasketContextProvider from "../contexts/basket";
import OrderContextProvider from "../contexts/order";
import FindCompany from "./find-company";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import UpdateVersionModal from "./company/components/update-version";
import { useGetConfigurationByOsAndVersion } from "../go-services/configuration/configuration";
import { DeviceType } from "../new-types/deviceType";
import { useAnalytics } from "@segment/analytics-react";

export default function Shell() {
    // Attributes
    const history = useHistory();
    const { pathname, search } = useLocation();
    const { page } = useAnalytics();
    const plat = Capacitor.getPlatform();

    const [version, setVersion] = useState("");
    const [openUpdateVersionDialog, setOpenUpdateVersionDialog] =
        useState(false);

    const { data: config } = useGetConfigurationByOsAndVersion(
        {
            os: plat?.toUpperCase() as DeviceType,
            version: version,
        },
        { query: { enabled: !!plat && plat !== "web" && !!version } }
    );

    // Effects
    useEffect(() => {
        if ((plat === "ios" || plat === "android") && config?.disabled) {
            setOpenUpdateVersionDialog(true);
        }
    }, [config, plat]);

    useEffect(() => {
        if (plat === "ios" || plat === "android") {
            App.getInfo().then((infos) => {
                setVersion(infos.version);
            });
        }
    }, [plat]);

    useEffect(() => {
        history.replace(
            `/loading?redirect=${encodeURIComponent(pathname + search)}`
        );

        history.listen(() => {
            if (plat !== "android" && plat !== "ios") {
                window.scrollTo({ top: 0 });
            }
        });

        Device?.getInfo().then((elt: any) => {
            if (elt.platform === "android") {
                App.addListener("backButton", (data: any) => {
                    if (window.history.length === 1) {
                        App.exitApp();
                    }

                    history.goBack();
                });
            }
        });
    }, []);

    // Segment page tracking
    useEffect(() => {
        page(pathname);
    }, [pathname]);

    return (
        <Providers>
            <Switch>
                <Route path="/callback" component={Loading} />
                <Route path="/find-company" component={FindCompany} />
                <Route path="/not-found" component={NotFound} />
                <Route path="/loading" component={Loading} />
                <Route path="/:companyId" component={Company} />
                <Route
                    path="/"
                    render={() => (
                        <Redirect
                            to={`/loading?redirect=${encodeURIComponent(
                                pathname + search
                            )}`}
                        />
                    )}
                />
            </Switch>
            {openUpdateVersionDialog && <UpdateVersionModal />}
        </Providers>
    );
}

const Providers = ({ children }: any) => {
    return (
        <ProfileContextProvider>
            <BasketContextProvider>
                <OrderContextProvider>{children}</OrderContextProvider>
            </BasketContextProvider>
        </ProfileContextProvider>
    );
};
