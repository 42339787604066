import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/button";
import Title from "../../../../components/title";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import success from "../../assets/success.svg";

function Success() {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const companyId = localStorage.getItem("COMPANY_NAME");

    // Functions
    function goToLogin() {
        if (companyId !== undefined && companyId !== null) {
            history.push(`/${companyId}/sign-in`);
            return;
        }
        history.push(`/home`);
    }

    // Render
    return (
        <>
            <div className="flex flex-col justify-center items-center w-full my-4">
                <img
                    src={success}
                    width="118px"
                    height="110.85px"
                    alt="success"
                />
                <Title className="text-3xl! leading-8 text-center mt-4">
                    ResetYourPasswordSuccess
                </Title>
            </div>

            <div className="flex justify-start mt-16">
                <Button className="min-w-full" onClick={goToLogin}>
                    {translate("GoToLogin")}
                </Button>
            </div>
        </>
    );
}

export default Success;
