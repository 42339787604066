import { useCallback } from "react";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import OrderInformationCard from "../order-information-card";
import { PromoCodeCardProps } from "./props";
import { ReactComponent as Promotion } from "../../assets/promotion.svg";
import Button from "../../../../../../components/button";
import { ReactComponent as Close } from "../../assets/close-circle.svg";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import PromoCodePanel from "../promo-code-panel";
import BasketPromoCodeService from "../../../../../../services/BasketPromoCodeService";
import { useParams } from "react-router";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useProfile } from "../../../../../../contexts/profile";
import { useQueryClient } from "react-query";
import { QueryKey } from "../../../../../../types/enums/query-key";
import * as Sentry from "@sentry/react";

export default function PromoCodeCard({
    promoCode,
    currentBasket,
}: PromoCodeCardProps) {
    //Attributes
    const { translate } = useTranslation();
    const { showPromoCodeSidePanel } = useOverlay();
    const onAddClick = useCallback(() => {
        showPromoCodeSidePanel({
            content: <PromoCodePanel currentBasket={currentBasket} />,
        });
    }, [showPromoCodeSidePanel]);
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id!;
    const { user } = useProfile();

    const queryClient = useQueryClient();

    //Functions
    const handleDelete = async () => {
        try {
            await BasketPromoCodeService.deleteBasketPromoCode(
                companyId,
                user.employeeId,
                currentBasket?.id ?? "",
                promoCode?.id!
            );

            queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
        } catch (error: any) {
            Sentry.captureException(
                `An error occured when deleting the promo code for user : ${user.id} in company : ${companyId} deleting the promo code : ${promoCode?.id} in the basket : ${currentBasket?.id}`,
                error
            );
        }
    };

    //Render
    return (
        <OrderInformationCard
            icon={<Promotion />}
            title={translate("promoCode")}
        >
            <div className="p-4">
                {promoCode && (
                    <div className="flex mb-4">
                        <div className="flex bg-gray-light py-2 px-3 mr-4 rounded-full">
                            <p className="text-xs text-black mr-3">
                                {promoCode?.name}
                            </p>
                            <button onClick={handleDelete}>
                                <Close />
                            </button>
                        </div>
                    </div>
                )}

                {!promoCode && (
                    <p className="text-sm text-third mb-4">
                        {translate("addPromoCode")}
                    </p>
                )}

                {!promoCode && (
                    <Button
                        className="bg-violet-DEFAULT w-24 h-8! text-xs!"
                        onClick={onAddClick}
                    >
                        <p>{translate("addCode")}</p>
                    </Button>
                )}
            </div>
        </OrderInformationCard>
    );
}
