import { useQuery } from "react-query";
import BasketProductService from "../services/BasketProductService";
import { QueryKey } from "../types/enums/query-key";

export function useAllBasketProduct(
    companyId: string,
    employeeId: string,
    basketId: string
) {
    return useQuery(
        [
            QueryKey.BasketProduct,
            { cid: companyId, eid: employeeId, bid: basketId },
        ],
        () =>
            BasketProductService.getAll(companyId, employeeId, basketId).then(
                (res) => res.data
            ),
        { enabled: !!basketId && !!companyId && !!employeeId }
    );
}
