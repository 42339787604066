import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import BusyIndicator from "../../../../components/busy-indicator";
import Button from "../../../../components/button";
import EmptyList from "../../../../components/empty-list";
import { useProfile } from "../../../../contexts/profile";
import { useAllOrdersByCompanyAndEmployeeQuery } from "../../../../queries/order";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import Page from "../../components/page";
import OrderDescription from "./component/description";
import EmptyBasket from "./assets/empty.svg";
import { useOneCompanyQuery } from "../../../../queries/company";
import CardPictureOrder from "../../../../components/card-picture-order";
import { useOverlay } from "../../../../contexts/overlay/overlay-context";
import { OrderStatus } from "../../../../types/enums/order-status";

export default function Orders(props: RouteComponentProps<CompanyRouteParams>) {
    // TODO: Integrate other parameters

    //Attributes
    const { user: localUser } = useProfile();
    const history = useHistory();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const allOrdersQuery = useAllOrdersByCompanyAndEmployeeQuery(
        companyId!,
        localUser.employeeId
    );
    const { removeCurrentOverlay } = useOverlay();

    history.listen(() => {
        removeCurrentOverlay();
    });

    return (
        <Page className="px-4 pt-6 flex-1 flex flex-col">
            <BusyIndicator query={allOrdersQuery}>
                <div className="md:flex md:justify-start md:flex-wrap md:ml-40 md:mt-6 ">
                    {allOrdersQuery.data
                        ?.sort((a, b) =>
                            a.deliveryDate < b.deliveryDate ? 1 : -1
                        )
                        .filter((elt) => elt.status === OrderStatus.Closed)
                        .map((order, index) => (
                            <CardPictureOrder
                                key={index}
                                firstContent={
                                    <OrderDescription order={order} />
                                }
                                order={order}
                            />
                        ))}
                </div>
                {allOrdersQuery.data &&
                    allOrdersQuery.data.filter(
                        (elt) => elt.status === OrderStatus.Closed
                    ).length === 0 && (
                        <EmptyList
                            title="YourOrderHistoryIsEmpty"
                            subTitle="TakeYourFirstOrderFirstPart"
                            button={
                                <Button
                                    className="w-full mt-auto"
                                    onClick={() =>
                                        history.push(`/${companyName}`)
                                    }
                                >
                                    Back to homepage
                                </Button>
                            }
                            image={EmptyBasket}
                        />
                    )}
            </BusyIndicator>
        </Page>
    );
}
