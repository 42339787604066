import React from "react";
import Paragraph from "../../../../../../components/paragraph";
import { ReactComponent as FoodizLogo } from "./assets/foodiz.svg";
import { ReactComponent as PlayStoreBadge } from "./assets/play-store-badge.svg";
import { ReactComponent as AppStoreBadge } from "./assets/app-store-badge.svg";
import { ReactComponent as Linkedin } from "./assets/linkedin.svg";
import { ReactComponent as Facebook } from "./assets/facebook.svg";
import { ReactComponent as Twitter } from "./assets/twitter.svg";
import { ReactComponent as Instagram } from "./assets/instagram.svg";
import { ReactComponent as PaymentMethods } from "./assets/payment-methods.svg";

function Footer() {
    return (
        <div className="hidden md:flex flex-col w-full bottom-0">
            <div className="flex flex-col justify-between space-y-8 w-full bottom-0 bg-footer min-h-footer h-footer px-20 py-14 text-white">
                <div className="flex w-full justify-between items-center">
                    <FoodizLogo />

                    <div className="flex-col p-3 border border-white rounded space-y-1">
                        <Paragraph className="text-sm font-bold">
                            Need Office Catering?
                        </Paragraph>
                        <div className="flex gap-1 text-sm font-normal items-center">
                            <Paragraph>Contact:</Paragraph>
                            <a href="mailto:sales@foodiz.be">sales@foodiz.be</a>
                        </div>
                    </div>

                    <div className="flex-col">
                        <Paragraph className="text-sm font-bold">
                            Our address
                        </Paragraph>
                        <Paragraph className="text-sm font-normal">
                            Rue Dieudonné Lefèvre 17, 1020 Brussels
                        </Paragraph>
                    </div>

                    <div className="flex-col">
                        <Paragraph className="text-sm font-bold">
                            E-mail
                        </Paragraph>
                        <a
                            className="text-sm font-normal"
                            href="mailto:sales@foodiz.be"
                        >
                            sales@foodiz.be
                        </a>
                    </div>

                    <div className="flex-col">
                        <Paragraph className="text-sm font-bold">
                            Website
                        </Paragraph>
                        <a
                            className="text-sm font-normal"
                            href="https://www.foodiz.be/"
                        >
                            www.foodiz.be
                        </a>
                    </div>
                </div>

                <hr className="opacity-25" />

                <div className="w-full flex items-center">
                    <div className="flex items-center w-full justify-between">
                        <div className="flex items-center">
                            <Paragraph className="mr-6">
                                Download the app
                            </Paragraph>

                            <a
                                href="https://play.google.com/store/apps/details?id=be.foodiz.app"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <PlayStoreBadge className="mr-3" />
                            </a>
                            <a
                                href="https://apps.apple.com/be/app/foodiz/id1583583964"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <AppStoreBadge />
                            </a>
                        </div>
                        <div className="flex items-center gap-2">
                            <a
                                href="https://www.linkedin.com/company/foodizdelivery/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Linkedin />
                            </a>
                            <a
                                href="https://www.facebook.com/foodizdelivery"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Facebook />
                            </a>
                            <a
                                href="https://www.instagram.com/foodiz.delivery/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Instagram />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex items-center h-13 bg-white justify-center gap-4">
                <Paragraph className="text-xs font-normal">
                    Pay Safely with
                </Paragraph>
                <PaymentMethods />
            </div>
        </div>
    );
}

export default Footer;
