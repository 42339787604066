import { EmployeeDTO } from "../types/dtos/employee";
import { UserDTO } from "../types/dtos/user";
import Service from "./Service";

function get() {
    return Service.api.get<UserDTO>("/v1/api/profile").then((elt) => elt.data);
}

const exported = {
    get,
};

export default exported;
