export enum QueryKey {
    Orders = "ORDERS",
    Products = "PRODUCTS",
    Companies = "COMPANIES",
    CompaniesProducts = "COMPANIES-PRODUCTS",
    Restaurants = "RESTAURANTS",
    Users = "USERS",
    UserPaymentMethods = "UserPaymentMethods",
    Employees = "EMPLOYEES",
    Baskets = "BASKETS",
    BasketProduct = "BASKETPRODUCT",
    PromoCode = "PROMOCODE",
    Event = "EVENT",
}
