import { useState } from "react";
import { useGetRestaurantById } from "../../../../../../go-services/restaurant/restaurant";

import ProductCard from "../product-card";
import RestaurantSectionProps from "./props";

function RestaurantSection({
    restaurantId,
    products,
    publicRestaurant,
    isPublic,
}: RestaurantSectionProps) {
    // Attribute
    const [isLoaded, setLoaded] = useState<boolean>(false);

    // Query
    const { data: restaurant } = useGetRestaurantById(restaurantId ?? "", {
        query: { enabled: !publicRestaurant },
    });
    const restaurantData = publicRestaurant ?? restaurant;

    return (
        <div
            className="w-full rounded-2xl md:p-6 p-3"
            style={{
                backgroundColor: restaurantData?.colorReference ?? "#FCF0EF",
            }}
        >
            {/* Restaurant Banner */}

            <div
                className={`min-h-13 rounded-lg overflow-hidden ${
                    isLoaded && "shadow-restaurant-banner"
                } md:mb-8 mb-4`}
                style={{
                    backgroundColor:
                        restaurantData?.colorReference ?? "transparent",
                }}
            >
                {!!restaurantData?.bannerPictureUrl && (
                    <img
                        src={restaurantData?.bannerPictureUrl}
                        className="object-cover h-13 min-h-13 w-full"
                        onLoad={() => setLoaded(true)}
                    />
                )}
            </div>

            {/* Restaurant Products */}
            <div className="grid 3xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-x-10 sm:gap-x-5 gap-y-6">
                {restaurant?.advertisementUrl && (
                    <div
                        className={
                            "rounded-lg flex border-2 border-black md:min-w-restaurant-product-desktop h-restaurant-product overflow-hidden"
                        }
                    >
                        <img
                            src={restaurantData?.advertisementUrl ?? ""}
                            className="object-cover w-full h-full min-w-restaurant-product-image"
                        />
                    </div>
                )}

                {products
                    ?.filter((elt) => elt.isAvailable)
                    .map((product) => (
                        <ProductCard
                            key={product?.id}
                            product={product}
                            isPublic={isPublic}
                        />
                    ))}
            </div>
        </div>
    );
}

export default RestaurantSection;
