import { BasketDTO } from "../types/dtos/basket";
import { BasketStatus } from "../types/enums/basket-status";
import { ProductPriority } from "../types/enums/product-priority";

export const initialBasket: BasketDTO = {
    id: "",
    creationDate: "0001-01-01",
    modificationDate: "0001-01-01",
    employeeId: "",
    promoCodeId: "",
    totalPrice: 0,
    basketStatus: BasketStatus.Unknown,
    basketProducts: [
        {
            basketId: "",
            productId: "",
            quantity: 0,
            id: "",
            indexPrice: 0,
            refundAmount: 0,
            totalAmount: 0,
            baseAmount: 0,
            creationDate: "0001-01-01",
            modificationDate: "0001-01-01",
            product: {
                id: "",
                creationDate: "0001-01-01",
                modificationDate: "0001-01-01",
                name: "",
                unitPrice: 0,
                isAvailable: false,
                description: "",
                pictureUrl: "",
                promoDescription: "",
                restaurantId: "",
                priority: ProductPriority.Low,
            },
        },
    ],
};

export interface BasketActionReducer {
    type: "serverChanged" | "userChanged";
    target: string;
    payload: any;
}

export const basketReducer = (
    state: BasketDTO,
    action: BasketActionReducer
) => {
    switch (action.type) {
        case "userChanged":
            return {
                ...state,
                [action.target]: action.payload,
            };

        case "serverChanged":
            return {
                ...state,
                ...action.payload,
            };

        default:
            break;
    }
};
