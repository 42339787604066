import { useEffect } from "react";

interface OdooChatbotProps {
    isVisible: boolean;
}

const OdooChatbot = ({ isVisible }: OdooChatbotProps) => {
    useEffect(() => {
        if (!isVisible) {
            return;
        }
        const script1 = document.createElement("script");
        script1.src = "https://foodiz.odoo.com/im_livechat/loader/1";
        script1.type = "text/javascript";
        document.body.appendChild(script1);

        const script2 = document.createElement("script");
        script2.src = "https://foodiz.odoo.com/im_livechat/assets_embed.js";
        script2.type = "text/javascript";
        document.body.appendChild(script2);
    }, [isVisible]);

    return null;
};

export default OdooChatbot;
