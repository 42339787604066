import React, { useCallback } from "react";
import { CardPictureProps } from "./props";
import { useOverlay } from "../../contexts/overlay/overlay-context";
import OrderDetail from "../../modules/company/modules/orders/component/order-detail";

export default function CardPictureOrder({
    firstContent,
    secondContent,
    order,
    onClickDisabled,
}: CardPictureProps) {
    // Attributes
    const { showOrderSidePanel } = useOverlay();

    //Functions
    const onClickOrder = useCallback(() => {
        showOrderSidePanel({
            content: <OrderDetail order={order!} />,
            order: order!,
        });
    }, []);

    return (
        <div
            onClick={onClickDisabled ? () => {} : onClickOrder}
            className={`flex justify-start items-center shadow-card mb-4 md:mr-5 md:bg-white md:flex-col md:p-8 md:rounded-xl md:min-w-order-card md:max-w-order-card md:min-h-order-card max-h-order-panel-mobile rounded-lg overflow-hidden ${
                onClickDisabled ? "cursor-default" : "cursor-pointer"
            }`}
        >
            <div className="max-w-order-card-picture min-w-order-card-picture">
                <img
                    alt="foodiz"
                    src={
                        order &&
                        order.basketDTO?.basketProducts?.[0]?.product
                            ?.pictureUrl
                    }
                    className="w-full h-full object-cover min-h-order-card-picture md:rounded-md"
                />
            </div>

            <div className="bg-white flex flex-row flex-1 items-center justify-between p-2 min-h-order-card-picture">
                <div className="flex flex-col flex-1 md:text-center">
                    {firstContent}
                </div>

                {secondContent}
            </div>
        </div>
    );
}
