import React from "react";
import { useTranslation } from "../../contexts/translation/TranslationContext";
import Paragraph from "../paragraph";
import Title from "../title";
import Translation from "../translation";
import { EmptyListProps } from "./props";

const EmptyList = ({ title, subTitle, image, button }: EmptyListProps) => {
    //Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <div className="flex flex-col flex-1 justify-between pb-6 md:max-w-restaurant-product-desktop md:m-auto md:pb-12!">
            <div className="flex flex-col mt-auto items-center md:justify-center">
                <div>
                    <img src={image} />
                </div>
                <Title
                    style={{ color: "#222B45" }}
                    className="text-center text-2xl! mt-16 mb-5!"
                >
                    {title}
                </Title>
                <div className="flex justify-center">
                    <Paragraph
                        style={{ color: "#222B45" }}
                        className="text-lg w-40 text-center"
                    >
                        {translate(`${subTitle}`)}
                    </Paragraph>
                </div>
            </div>
            <div className="mt-auto">{button}</div>
        </div>
    );
};

export default EmptyList;
