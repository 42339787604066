/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
    OPEN: "OPEN",
    WAITING: "WAITING",
    CLOSED: "CLOSED",
    UNKNOWN: "UNKNOWN",
} as const;
