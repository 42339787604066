interface GetProductPriceParams {
    baseAmount?: number;
    indexedPrice?: number;
    quantity?: number;
    refundAmount?: number;
    currencySuffix?: "€" | "EUR";
}

export function getProductPrice({
    baseAmount,
    indexedPrice,
    quantity,
    refundAmount,
    currencySuffix = "€",
}: GetProductPriceParams) {
    let result = "";
    if (
        baseAmount !== undefined &&
        indexedPrice !== undefined &&
        quantity !== undefined &&
        refundAmount !== undefined
    ) {
        result =
            (baseAmount * quantity - refundAmount).toFixed(2) + currencySuffix;
    }

    return result;
}
