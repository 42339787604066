import React from "react";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { OptionCardProps } from "./props";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as ArrowRightActive } from "../../assets/arrow-right-active.svg";

export default function OptionCard(props: OptionCardProps) {
    //Attributes
    const { title, icon, onClick, active } = props;
    const { translate } = useTranslation();

    return (
        <div
            className="p-4 text-sm flex justify-between items-center bg-white rounded-lg shadow-lg mb-4"
            onClick={onClick}
            style={{ cursor: "pointer" }}
        >
            <div className="flex items-center">
                {icon}

                <p className="text-secondary font-poppins font-medium text-sm mx-4">
                    {translate(title)}
                </p>
            </div>
            {active ? <ArrowRightActive /> : <ArrowRight />}
        </div>
    );
}
