import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { OrderInformationCardProps } from "./props";

export default function OrderInformationCard({
    icon,
    title,
    onEditClick,
    children,
}: OrderInformationCardProps) {
    const { translate } = useTranslation();

    return (
        <div className="bg-white rounded-lg shadow-lg mb-4 md:min-w-custom">
            <div
                className={`p-4 flex justify-between items-center ${
                    children && "border-b border-gray-lightest"
                }`}
            >
                <div className="flex items-center">
                    {icon}

                    <p className="text-secondary font-medium text-base mx-4">
                        {title}
                    </p>
                </div>

                {!!onEditClick && (
                    <button
                        onClick={onEditClick}
                        className="rounded bg-gray-light px-3"
                    >
                        <span className="text-sm text-gray-DEFAULT font-semibold">
                            {translate("edit").toUpperCase()}
                        </span>
                    </button>
                )}
            </div>

            {children}
        </div>
    );
}
