import { OrderDTO } from "../types/dtos/order";
import { paymentOrderDTO } from "../types/dtos/paymentOrder";
import { buildQueryParams } from "../utils/buildQueryParams";
import Service from "./Service";

function getAll(
    companyId: string,
    employeeId: string,
    sortBy?: string,
    applyEnabledProductFilters?: boolean
) {
    let url = `/v1/api/companies/${companyId}/employees/${employeeId}/orders`;
    const params = {
        sortBy,
        applyEnabledProductFilters,
    };
    const queryParams = buildQueryParams(params);

    return Service.api.get<Array<OrderDTO>>(`${url}${queryParams}`);
}

function get(companyId: string, employeeId: string, basketId: string) {
    return Service.api.get<OrderDTO>(
        `v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/current-order`
    );
}

function getByOrderId(
    companyId: string,
    employeeId: string,
    basketId: string,
    orderId: string
) {
    return Service.api.get<OrderDTO>(
        `v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/orders/${orderId}`
    );
}

function post(
    companyId: string,
    employeeId: string,
    basketId: string,
    orderDTO: OrderDTO
) {
    orderDTO.deliveryDate = new Date(orderDTO.deliveryDate);

    return Service.api.post<OrderDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/orders`,
        orderDTO
    );
}

function postPayment(
    companyId: string,
    employeeId: string,
    basketId: string,
    orderId: string,
    paymentOrderDTO: paymentOrderDTO
) {
    paymentOrderDTO.order.deliveryDate = new Date(
        paymentOrderDTO.order.deliveryDate
    );

    return Service.api.post<OrderDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/orders/${orderId}/payments`,
        paymentOrderDTO
    );
}

function put(
    companyId: string,
    employeeId: string,
    basketId: string,
    orderDTO: OrderDTO
) {
    return Service.api.put<OrderDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets/${basketId}/orders`,
        orderDTO
    );
}

const exported = { getAll, get, post, postPayment, put, getByOrderId };

export default exported;
