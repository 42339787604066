import React from "react";
import Paragraph from "../../../../../../../../components/paragraph";
import Title from "../../../../../../../../components/title";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";

function Confirmation() {
    // Attributes
    const { translate } = useTranslation();

    // Rendering
    return (
        <div className="mt-6">
            <Title className="text-3xl! leading-8 mb-3!">CheckYourMails</Title>

            <Paragraph className="text-dark-blue">
                {translate("CheckYourMailsDescription")}
            </Paragraph>
        </div>
    );
}

export default Confirmation;
