import { PaymentMethodDTO } from "../types/dtos/paymentMethod";
import Service from "./Service";

function get(userId: string) {
    return Service.api.get<PaymentMethodDTO[]>(
        `/v1/api/users/${userId}/payment-methods`
    );
}

function post(userId: string) {
    return Service.api.post<string>(`/v1/api/users/${userId}/payment-methods`);
}

function deletePaymentMethod(userId: string, paymentMethodId: string) {
    return Service.api.delete<any>(
        `/v1/api/users/${userId}/payment-methods/${paymentMethodId}`
    );
}

const exported = { get, post, deletePaymentMethod };

export default exported;
