import React from "react";
import Popup from "../../../../../../../../components/popup";
import Title from "../../../../../../../../components/title";
import { LogoutModalProps } from "./props";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import Button from "../../../../../../../../components/button";
import { useProfile } from "../../../../../../../../contexts/profile";
import { initialUser } from "../../reducer";
import { useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";

const LogoutModal = ({ isVisible, handleOnClose }: LogoutModalProps) => {
    // Attributes
    const { translate } = useTranslation();
    const { user, dispatch } = useProfile();
    const history = useHistory();
    const { companyId } = useParams<CompanyRouteParams>();

    // Handler
    function handleLogOut() {
        //clear user info
        dispatch({
            payload: initialUser,
            target: "self",
            type: "serverChanged",
        });

        //clear local storage
        const hasSeenNotification = localStorage.getItem("hasSeenNotification");

        localStorage.clear();

        localStorage.setItem("COMPANY_NAME", companyId);

        if (hasSeenNotification !== null) {
            localStorage.setItem(
                "hasSeenNotification",
                JSON.stringify({
                    employeeId: user.employeeId,
                    seen: true,
                })
            );
        }
        history.replace(`/${companyId}/sign-in`);
    }

    //Render
    return (
        <Popup isVisible={isVisible} handleOnClose={handleOnClose}>
            <div className="flex flex-col items-center!">
                <LeaveIcon className="mb-12" />
                <Title className="text-center max-w-xs">
                    logOutConfirmation
                </Title>
                <div className="flex flex-row ">
                    <Button
                        onClick={handleOnClose}
                        className="bg-cancel-background py-2 px-3 rounded-lg text-dark-blue! font-semibold md:py-4 md:px-10 "
                    >
                        {translate("cancel")}
                    </Button>
                    <div className="mr-3" />
                    <Button
                        onClick={handleLogOut}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                    >
                        {translate("logOut")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default LogoutModal;
