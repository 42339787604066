/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    EmployeeResDTO,
    EmployeePagingResDTO,
    GetEmployeesParams,
    UserReqCreateDTO,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getEmployeeById = (
    companyId: string,
    employeeId: string,
    signal?: AbortSignal
) => {
    return customInstance<EmployeeResDTO>({
        url: `/v1/api/companies/${companyId}/employees/${employeeId}`,
        method: "get",
        signal,
    });
};

export const getGetEmployeeByIdQueryKey = (
    companyId: string,
    employeeId: string
) => [`/v1/api/companies/${companyId}/employees/${employeeId}`];

export type GetEmployeeByIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getEmployeeById>>
>;
export type GetEmployeeByIdQueryError = unknown;

export const useGetEmployeeById = <
    TData = Awaited<ReturnType<typeof getEmployeeById>>,
    TError = unknown
>(
    companyId: string,
    employeeId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getEmployeeById>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetEmployeeByIdQueryKey(companyId, employeeId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getEmployeeById>>
    > = ({ signal }) => getEmployeeById(companyId, employeeId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getEmployeeById>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!(companyId && employeeId),
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const deleteEmployeeById = (companyId: string, employeeId: string) => {
    return customInstance<EmployeeResDTO>({
        url: `/v1/api/companies/${companyId}/employees/${employeeId}`,
        method: "delete",
    });
};

export type DeleteEmployeeByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteEmployeeById>>
>;

export type DeleteEmployeeByIdMutationError = unknown;

export const useDeleteEmployeeById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteEmployeeById>>,
        TError,
        { companyId: string; employeeId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteEmployeeById>>,
        { companyId: string; employeeId: string }
    > = (props) => {
        const { companyId, employeeId } = props ?? {};

        return deleteEmployeeById(companyId, employeeId);
    };

    return useMutation<
        Awaited<ReturnType<typeof deleteEmployeeById>>,
        TError,
        { companyId: string; employeeId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const getEmployees = (
    companyId: string,
    params?: GetEmployeesParams,
    signal?: AbortSignal
) => {
    return customInstance<EmployeePagingResDTO>({
        url: `/v1/api/companies/${companyId}/employees`,
        method: "get",
        params,
        signal,
    });
};

export const getGetEmployeesQueryKey = (
    companyId: string,
    params?: GetEmployeesParams
) => [`/v1/api/companies/${companyId}/employees`, ...(params ? [params] : [])];

export type GetEmployeesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getEmployees>>
>;
export type GetEmployeesQueryError = unknown;

export const useGetEmployees = <
    TData = Awaited<ReturnType<typeof getEmployees>>,
    TError = unknown
>(
    companyId: string,
    params?: GetEmployeesParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getEmployees>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetEmployeesQueryKey(companyId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmployees>>> = ({
        signal,
    }) => getEmployees(companyId, params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getEmployees>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!companyId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createEmployee = (
    companyId: string,
    userReqCreateDTO: UserReqCreateDTO
) => {
    return customInstance<EmployeeResDTO>({
        url: `/v1/api/companies/${companyId}/employees`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: userReqCreateDTO,
    });
};

export type CreateEmployeeMutationResult = NonNullable<
    Awaited<ReturnType<typeof createEmployee>>
>;
export type CreateEmployeeMutationBody = UserReqCreateDTO;
export type CreateEmployeeMutationError = unknown;

export const useCreateEmployee = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createEmployee>>,
        TError,
        { companyId: string; data: UserReqCreateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createEmployee>>,
        { companyId: string; data: UserReqCreateDTO }
    > = (props) => {
        const { companyId, data } = props ?? {};

        return createEmployee(companyId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createEmployee>>,
        TError,
        { companyId: string; data: UserReqCreateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
