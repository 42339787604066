import Button from "../../../../components/button";
import { ReactComponent as Foodiz } from "../event-tab-bar/assets/foodiz.svg";
import Paragraph from "../../../../components/paragraph";
import {
    AuthRoute,
    FindCompanyRouteParams,
} from "../../../../types/params/find-company-route";
import { useHistory } from "react-router";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";

export const TAB_BAR_HEIGHT = 84;

function PublicTabBar() {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();

    //Functions
    function handleOnClick(redirectRoute: AuthRoute) {
        history.push({
            pathname: `/find-company`,
            state: {
                redirectRoute,
            } as FindCompanyRouteParams,
        });
    }

    // Render
    return (
        <div className="flex flex-1 flex-col bg-white">
            <div
                className={`flex flex-row justify-between items-center  bg-white md:px-16 min-w-full overflow-hidden relative z-30  border-b md:h-21`}
            >
                <div>
                    <Foodiz />
                </div>
                <div className="flex justify-end items-center space-x-4">
                    <div
                        onClick={() => {
                            handleOnClick(AuthRoute.SIGN_UP);
                        }}
                        className="cursor-pointer"
                    >
                        <Paragraph className="text-sign-in-button font-bold">
                            {translate("Sign-up")}
                        </Paragraph>
                    </div>
                    <Button
                        onClick={() => {
                            handleOnClick(AuthRoute.SIGN_IN);
                        }}
                        className="bg-sign-in-button text-white font-bold rounded-lg py-2 px-4"
                    >
                        {translate("Sign-in")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PublicTabBar;
