import { App } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    const companyName = window.localStorage.getItem("COMPANY_NAME") ?? "";

    useEffect(() => {
        App?.addListener("appUrlOpen", (data: any) => {
            let slug = data.url.split("app.foodiz.be").pop();

            if (slug) {
                if (slug.includes("://")) {
                    slug = slug.replace("://", "/");
                }
                // if there is a company name in the localStorage and the slug didn't include the company name
                if (companyName && !slug.includes(companyName)) {
                    slug = `/${companyName}${slug}`;
                }
                history.push(slug);
                return;
            }
        });
    }, []);

    return null;
};

export default AppUrlListener;
