import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../../../components/button";
import AuthCard from "../../../../../../../../components/auth-card";
import Paragraph from "../../../../../../../../components/paragraph";
import { useProfile } from "../../../../../../../../contexts/profile";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { useResendEmailConfirmation } from "../../../../../../../../go-services/user/user";
import Service from "../../../../../../../../services/Service";
import * as Sentry from "@sentry/react";

function ConfirmEmail() {
    // Attributes
    const history = useHistory();
    const { user } = useProfile();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { mutateAsync: resendEmailConfirmation, isLoading: isResendLoading } =
        useResendEmailConfirmation({
            mutation: {
                onSuccess: handleSuccessResendEmail,
                onError: (error: any) => handleErrorResendEmail(error),
            },
        });

    // Functions
    function handleResendEmail() {
        if (isResendLoading) {
            return;
        }
        resendEmailConfirmation({
            data: { email: user.email },
        });
    }

    function handleSuccessResendEmail() {
        Service.success.push("emailSend");
    }
    function handleErrorResendEmail(error: any) {
        Sentry.captureException(
            `An error occured when resending the confirmation email for email : ${user.email}: `,
            error
        );
    }

    // Render
    return (
        <AuthCard
            onGoBack={() => history.goBack()}
            footerContent={
                <div className="mt-6 mb-1 md:mt-4 space-y-4 justify-center items-center">
                    <Button
                        onClick={() =>
                            history.replace(`/${companyName}/sign-in`)
                        }
                        className="min-w-full bg-add-button "
                    >
                        Back to Sign In page
                    </Button>
                </div>
            }
            showLogo={false}
            step={4}
        >
            <div className="flex flex-col h-full justify-between">
                <div>
                    <div>
                        <Paragraph className="text-left text-3xl text-add-button font-poppins font-semibold mb-8 leading-8">
                            Welcome to Foodiz! Please verify your email to get
                            started🍔🥗
                        </Paragraph>
                    </div>
                    <div className="flex-col space-y-20">
                        <div className="flex-col space-y-5">
                            <p className="text-left text-xs text-add-button font-poppins font-normal leading-5">
                                To ensure the security of your Foodiz account
                                and keep you in the loop we need to verify your
                                email address. We sent the verification mail to{" "}
                                <strong>{user.email}</strong>
                            </p>
                            <Paragraph className="text-left text-xs text-add-button font-poppins font-normal leading-5">
                                Open the mail and click on the verify button to
                                get started.
                            </Paragraph>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex-col space-y-3 align-middle">
                        <div className="flex justify-center space-x-1">
                            <p className="text-center text-xs text-add-button font-poppins font-normal leading-5 inline">
                                Didn’t get the e-mail?{" "}
                            </p>
                            <p
                                className="text-xs text-green-dark font-poppins font-semibold leading-5 inline cursor-pointer"
                                onClick={handleResendEmail}
                            >
                                Send again
                            </p>
                        </div>
                        <p className="text-center text-xs text-add-button font-poppins font-normal leading-5">
                            You used the wrong e-mail?{" "}
                            <a
                                className="text-xs text-green-dark font-poppins font-semibold leading-5"
                                href={`${companyName}/sign-up/name`}
                            >
                                Create an account again
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </AuthCard>
    );
}

export default ConfirmEmail;
