import { ResetPasswordDTO } from "../types/dtos/resetPassword";
import Service from "./Service";

async function post(resetPassword: ResetPasswordDTO) {
    return await Service.iam.post(`/v1/api/reset-password`, resetPassword);
}

const ResetPasswordService = {
    post,
};

export default ResetPasswordService;
