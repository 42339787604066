import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button";
import Input from "../../components/input";
import Paragraph from "../../components/paragraph";
import AuthCard from "../../components/auth-card";
import { useTranslation } from "../../contexts/translation/TranslationContext";
import { useGetCompanyByName } from "../../go-services/company/company";
import { CompanyResDTO } from "../../new-types/companyResDTO";
import Service from "../../services/Service";
import {
    AuthRoute,
    FindCompanyRouteParams,
} from "../../types/params/find-company-route";
import { FindCompanyProps } from "./props";

export default function FindCompany({ redirectRoute }: FindCompanyProps) {
    //Attributes
    const { translate } = useTranslation();
    const history = useHistory();
    const companyName = window.localStorage.getItem("COMPANY_NAME") ?? "";
    const [companyCode, setCompanyCode] = useState<string>("");
    const [clicked, setClicked] = useState<boolean>(false);
    const location = useLocation<FindCompanyRouteParams>();

    // Retrieve values of previous page
    redirectRoute = location?.state.redirectRoute;

    const { refetch: refetchCompanyByName, isSuccess: companyQueryIsSucces } =
        useGetCompanyByName(companyCode, {
            query: { enabled: clicked },
        });
    const [disabled, setDisabled] = useState(false);

    //Effects
    useEffect(() => {
        if (!companyQueryIsSucces) {
            setClicked(false);
        }
    }, [companyQueryIsSucces]);

    useEffect(() => {
        if (companyName !== "") {
            // Go to sign in page
            if (redirectRoute === AuthRoute.SIGN_IN) {
                history.replace(`/${companyName.toLowerCase()}/sign-in`, {
                    origin: "find company",
                });
                setDisabled(false);
                return;
            }

            // Go to sign up page
            if (redirectRoute === AuthRoute.SIGN_UP) {
                return;
            }
            // Go to auth page
            history.replace(`/${companyName.toLowerCase()}/auth`, {
                origin: "find company",
            });
        }
    }, [companyName, redirectRoute]);

    //Functions
    const handleOnClick = async () => {
        setClicked(true);
        setDisabled(true);

        const response = await refetchCompanyByName();
        const company = response.data as CompanyResDTO;

        if (!response.isError) {
            localStorage.setItem(
                "COMPANY_NAME",
                company?.name?.toLowerCase() ?? ""
            );

            // Go to sign in page
            if (redirectRoute === AuthRoute.SIGN_IN) {
                history.replace(`/${company?.name?.toLowerCase()}/sign-in`, {
                    origin: "find company",
                });
                setDisabled(false);
                return;
            }

            // Go to sign up page
            if (redirectRoute === AuthRoute.SIGN_UP) {
                history.push(`/${company?.name?.toLowerCase()}/sign-up/name`, {
                    origin: "find company",
                });
                setDisabled(false);
                return;
            }

            // Go to auth page
            history.replace(`/${company?.name?.toLowerCase()}/auth`, {
                origin: "find company",
            });
        } else {
            Service.errors.push("companyDoesNotExist");
        }
        setDisabled(false);
    };

    const handleKeyDown = async (key: any) => {
        if (key.code === "Enter") {
            await handleOnClick();
        }
    };

    return (
        <AuthCard
            onGoBack={() => history?.goBack()}
            footerContent={
                <Button
                    disabled={disabled}
                    onClick={handleOnClick}
                    className="bg-add-button w-full"
                    data-cy="next"
                >
                    {translate("confirm")}
                </Button>
            }
            step={1}
        >
            <Paragraph className="text-left text-3xl text-add-button font-poppins font-semibold mb-8 line-clamp-2">
                Welcome 👋, what’s your Company ID?
            </Paragraph>
            <Input
                type="text"
                autoFocus
                placeholder="ex: azerty"
                label="Company id"
                labelColorClassName="text-profile-panel-label"
                value={companyCode}
                onChange={(event) => setCompanyCode(event)}
                onKeyDown={(key) => handleKeyDown(key)}
                data-cy={"companyCode"}
            />
            <div className="flex flex-row shadow-sm md:shadow-none border border-add-button p-3 mt-4 rounded-lg space-x-1 bg-grey-bg">
                <Paragraph className="text-base">🧐</Paragraph>
                <div className="space-y-5 ">
                    <Paragraph className="font-poppins font-normal text-xs	">
                        {translate("CompanyIdDescriptionPart1")}
                    </Paragraph>
                    <Paragraph className="font-poppins font-normal text-xs	">
                        {translate("CompanyIdDescriptionPart2")}
                    </Paragraph>
                </div>
            </div>
        </AuthCard>
    );
}
