import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Service from "../../services/Service";
import Translation from "../translation";

const Alert = (props: any) => {
    // Attributes
    const { error, success } = props;

    if (error != undefined) {
        return (
            <motion.div
                transition={{ duration: 0.4 }}
                initial={{ marginBottom: -50, opacity: 0 }}
                animate={{ marginBottom: 20, opacity: 1 }}
                className={
                    "px-8 py-2 rounded-xl text-white bg-error-red flex md:max-w-lg"
                }
            >
                <Translation>{error}</Translation>
            </motion.div>
        );
    }

    return (
        <motion.div
            transition={{ duration: 0.4 }}
            initial={{ marginBottom: -50, opacity: 0 }}
            animate={{ marginBottom: 20, opacity: 1 }}
            className={
                "px-8 py-2 rounded-xl text-white bg-green-light flex md:max-w-lg"
            }
        >
            <Translation>{success}</Translation>
        </motion.div>
    );
};

const AlertContainer = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<string[]>([]);

    // Effects
    useEffect(() => {
        setInterval(() => {
            if (Service.errors.length > 0) {
                setErrors((prev) => {
                    const alert = Service.errors.pop();
                    if (alert) {
                        return [alert].concat(prev);
                    }
                    return prev;
                });

                setTimeout(() => {
                    setErrors((prev) => {
                        const newArray: string[] = [];
                        const reversed = prev.reverse();
                        reversed.pop();
                        return newArray.concat(reversed.reverse());
                    });
                }, 5000);
            }

            if (Service.success.length > 0) {
                setSuccess((prev) => {
                    const alert = Service.success.pop();

                    if (alert) {
                        return [alert].concat(prev);
                    }
                    return prev;
                });

                setTimeout(() => {
                    setSuccess((prev) => {
                        const newArray: string[] = [];
                        const reversed = prev.reverse();
                        reversed.pop();
                        return newArray.concat(reversed.reverse());
                    });
                }, 5000);
            }
        }, 1000);
    }, []);

    return (
        <div
            className="md:flex md:flex-row-reverse"
            style={{
                position: "fixed",
                right: 10,
                left: 10,
                bottom: 0,
                // this z-index is one higher than the z-index of the intercom widget
                // so that the alert is always on top of the intercom widget
                zIndex: 2147483004,
            }}
        >
            {errors.map((elt, index) => (
                <Alert key={index} error={elt} />
            ))}
            {success.map((elt, index) => (
                <Alert key={index} success={elt} />
            ))}
        </div>
    );
};

export default AlertContainer;
