import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useOverlay } from "../../../../contexts/overlay/overlay-context";
import { useGetAllPromotion } from "../../../../go-services/promotion/promotion";
import { FileDeviceType } from "../../../../new-types";
import { IHomeLogicInput, IHomeLogicOutput } from "./types";
import { useProfile } from "../../../../contexts/profile";
import { useAllOrdersByCompanyAndEmployeeQuery } from "../../../../queries/order";
import ReOrderService from "../../../../services/ReOrderService";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import useBasketLogic from "../../../../hooks/useBasketLogic";
import { errorMapper } from "../../../../mappers/message-errors";
import { ErrorType } from "../../../../types/enums/error-type";
import { useAnalytics } from "@segment/analytics-react";
import * as Sentry from "@sentry/react";
import { SortByEnum } from "../../../../types/enums/sort-by";

const useHomeLogic = ({
    currentMediaQuery,
    company,
}: IHomeLogicInput): IHomeLogicOutput => {
    // Attribues
    const history = useHistory();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { removeCurrentOverlay } = useOverlay();
    const [activeRestaurantId, setActiveRestaurantId] = useState<string>();
    const [disableAutoScroll, setDisableAutoScroll] = useState<boolean>(false);
    const sectionsRef = useRef<HTMLDivElement[]>([]);
    const scrollRef = useRef<HTMLDivElement>(null);
    const restaurantHeaderRef = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const { user } = useProfile();
    const [isReOrderLoading, setIsReOrderLoading] = useState<boolean>(false);
    const { refetchCurrentBasket } = useBasketLogic();
    const { track } = useAnalytics();

    // Queries
    const { data: promotion, isLoading: isPromotionLoading } =
        useGetAllPromotion({
            deviceType:
                currentMediaQuery === 1
                    ? FileDeviceType.WEB
                    : FileDeviceType.MOBILE,
        });

    const orders = useAllOrdersByCompanyAndEmployeeQuery(
        company?.id!,
        user.employeeId,
        SortByEnum.CREATION_DATE,
        true
    )?.data;

    // Handlers
    history.listen(() => {
        removeCurrentOverlay();
    });

    function handleScroll() {
        if (
            disableAutoScroll ||
            !sectionsRef?.current ||
            !restaurantHeaderRef?.current
        )
            return;

        const headerRect = restaurantHeaderRef.current?.getBoundingClientRect();

        const someSectionIntersecting = sectionsRef?.current?.some(
            (section) => {
                const sectionRect = section.getBoundingClientRect();

                const isIntersecting =
                    headerRect.bottom <= sectionRect.top + sectionRect.height &&
                    headerRect.top + headerRect.height >= sectionRect.top;

                if (isIntersecting) {
                    setActiveRestaurantId(section.id);
                }

                return isIntersecting;
            }
        );

        if (!someSectionIntersecting) setActiveRestaurantId(undefined);
    }

    function handleRestaurantTabPress(restaurantId: string) {
        if (timer) {
            clearTimeout(timer);
        }

        setDisableAutoScroll(true);
        setActiveRestaurantId(restaurantId);
        const selectedSection = sectionsRef.current.find(
            (section) => section.id === restaurantId
        );

        selectedSection?.scrollIntoView({
            behavior: "smooth",
        });
        setTimer(
            setTimeout(() => {
                setDisableAutoScroll(false);
            }, 1000)
        );
    }

    async function handleCreateReOrder(orderId: string) {
        if (!orderId) return;
        try {
            setIsReOrderLoading(true);
            await ReOrderService.post(company?.id!, user?.employeeId, orderId);
            track("Re order clicked", {
                user: user?.id,
            });
            refetchCurrentBasket();
            return history.push(`/${companyName}/order-overview?reorder=true`);
        } catch (e: any) {
            errorMapper(ErrorType.REORDER);
            Sentry.captureException(
                `An error occured when reordering the basket for the order : ${orderId}`,
                e
            );
            console.error(e);
        } finally {
            setIsReOrderLoading(false);
        }
    }

    return {
        activeRestaurantId,
        setActiveRestaurantId,
        sectionsRef,
        scrollRef,
        restaurantHeaderRef,
        promotion,
        isPromotionLoading,
        handleScroll,
        handleRestaurantTabPress,
        disableAutoScroll,
        orders,
        handleCreateReOrder,
        isReOrderLoading,
    };
};

export default useHomeLogic;
