import { isDate, isValid, parse } from "date-fns";

/**
 * This function will evaluate if the givenHour (HH:mm) is between the range of the begin (HH:mm) and end hours (HH:mm).
 * Given hours ∈ [begin hour;end hour]
 *
 * @param givenHour evaluated hour
 * @param beginIntervalHour begin of the interval
 * @param endIntervalHour end of the interval
 * @returns true if the givenHour is between the range of the begin and end hours, false otherwise.
 */
const isHourBetweenBeginAndEndHours = (
    givenHour: Date,
    beginIntervalHour: string,
    endIntervalHour: string
): boolean => {
    // parse the begin and end hours to Date objects with date-fns parse function
    const beginHour = parse(beginIntervalHour, "HH:mm", new Date());
    const endHour = parse(endIntervalHour, "HH:mm", new Date());

    if (isValid(givenHour) && isValid(beginHour) && isValid(endHour)) {
        // comapre the given hour with the begin and end hours
        return givenHour >= beginHour && givenHour <= endHour;
    } else {
        return false;
    }
};

export default isHourBetweenBeginAndEndHours;
