import Button from "../button";
import { AuthCardProps } from "./props";
import { ReactComponent as FoodizLogo } from "./assets/foodiz.svg";

const AUTH_STEPS = 4;

const AuthCard = ({
    children,
    onGoBack,
    footerContent,
    showLogo = true,
    step,
}: AuthCardProps) => {
    // Render
    return (
        <div className="bg-mobile-sign-background md:bg-sign-background bg-no-repeat flex flex-1 flex-col items-center justify-center overflow-hidden px-4 md:px-0 font-poppins">
            {/* Modal Card */}
            <div className="relative bg-white rounded-20px md:px-8 md:py-8 px-4 py-5 md:min-h-3/4 md:max-h-9/10  min-h-mobile-auth-modals md:w-auth-card w-full overflow-hidden">
                {/* Progress bar */}
                <div>
                    <div className="w-full top-0 left-0 absolute h-5px bg-light-grey z-20" />
                    <div
                        className="top-0 left-0 absolute h-5px bg-event-banner-bg z-30"
                        style={{ width: step * (100 / AUTH_STEPS) + "%" }}
                    />
                </div>

                <div className="flex flex-col flex-1 h-full">
                    <Button back onClick={onGoBack} />
                    <div className="flex flex-col flex-1 md:mx-auto md:w-auth-modals-content">
                        {/* Logo */}
                        {showLogo && (
                            <div className="md:flex md:justify-center md:mt-0 mt-16 md:pt-4 hidden">
                                <FoodizLogo />
                            </div>
                        )}

                        {/* Content */}
                        <div className="flex flex-col h-full flex-grow md:mt-10 mt-7 md:mb-2">
                            {children}
                        </div>

                        {/* Bottom Content */}
                        <div>{footerContent}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthCard;
