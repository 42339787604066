import React, { Fragment, useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import Title from "../../../../../../components/title";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import UserService from "../../../../../../services/UserService";
import { UserDTO } from "../../../../../../types/dtos/user";
import { ProfileRouteParams } from "../../../../../../types/params/profile-route";
import Page from "../../../../components/page";
import Detail from "../../modules/detail";
import { reducer } from "../../modules/detail/reducer";
import BackCard from "../back-card";
import { useProfile } from "../../../../../../contexts/profile";
import { useOneUserQuery } from "../../../../../../queries/user";
import useMedia from "../../../../../../hooks/useMedia";
import Service from "../../../../../../services/Service";
import Paragraph from "../../../../../../components/paragraph";
import DeleteUserModal from "./components/delete-user-modal";
import NightbornBrand from "../../../../../../components/branding";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export default function AccountInfoPanel() {
    //Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const { user: localUser } = useProfile();
    const query = useOneUserQuery(localUser.id);
    const { companyId } = useParams<ProfileRouteParams>();
    const [user, dispatch] = useReducer(reducer, localUser);
    const [disabled, setDisabled] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [version, setVersion] = useState("");
    const plat = Capacitor.getPlatform();
    const isMobile = plat === "ios" || plat === "android";

    // Effects
    useEffect(() => {
        if (query.data && companyId) {
            dispatch({
                type: "serverChanged",
                target: "self",
                payload: query.data,
            });
        }
    }, [query.data, companyId]);

    useEffect(() => {
        if (isMobile) {
            App.getInfo().then((infos) => {
                setVersion(infos.version);
            });
        }
    }, [plat, isMobile]);

    //UseMedia
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Functions
    const onButtonClick = async (userToUpdate: UserDTO | any) => {
        setDisabled(true);

        for (const attr in userToUpdate) {
            if (
                attr !== "password" &&
                attr !== "paymentMethod" &&
                attr !== "oldPassword"
            ) {
                if (userToUpdate[attr] === "" || userToUpdate[attr] === -1) {
                    Service.errors.push("fillAllRequiredInputs");
                    return;
                }
            }
        }

        const response = await UserService.put(userToUpdate);
        await query.refetch();

        if (response) {
            setDisabled(false);
            Service.success.push("profileUpdated");
            history.push(`/${companyId}/profile`);
        }
    };

    function handleDeleteAccount() {
        setOpenDeleteUserModal(true);
    }

    return (
        <BusyIndicator query={query}>
            <BackCard title="accountInfo" />
            <Page
                tabBarVisible={currentMediaQuery === 1 ? true : false}
                className={
                    "px-4 py-4 md:pt-24 md:mb-0 md:mx-auto flex-1 md:justify-between"
                }
                isBasketPanelVisible={false}
                eventBannerVisible={false}
            >
                <div className="md:flex flex flex-grow ">
                    <div className="md:block hidden md:flex-3">
                        <Detail showTabBar={false} infoActive />
                    </div>

                    <div className="md:flex-1  flex flex-1 flex-col mb-4 md:h-full md:bg-white md:rounded-lg md:shadow-lg md:max-w-sm md:min-w-custom-sm  md:mb-0 md:p-4 ">
                        <Title className="hidden md:block text-profile-panel-title ">
                            accountInfo
                        </Title>
                        <Input
                            required
                            onChange={(event: any) =>
                                dispatch({
                                    type: "userChanged",
                                    payload: event,
                                    target: "firstName",
                                })
                            }
                            value={user.firstName}
                            label={translate("fname")}
                            placeholder={translate("fname")}
                        />
                        <Input
                            required
                            onChange={(event: any) =>
                                dispatch({
                                    type: "userChanged",
                                    payload: event,
                                    target: "lastName",
                                })
                            }
                            value={user.lastName}
                            label={translate("lname")}
                            className="mt-4"
                            placeholder={translate("lname")}
                        />
                        <Input
                            readOnly
                            required
                            value={user.email}
                            className="mt-4 cursor-default"
                            label={translate("email")}
                            placeholder={translate("email")}
                        />
                        <Input
                            required
                            onChange={(event: any) =>
                                dispatch({
                                    type: "userChanged",
                                    payload: event,
                                    target: "phoneNumber",
                                })
                            }
                            value={user.phoneNumber}
                            className="mt-4"
                            label={translate("phoneNumber")}
                            name="phoneNumbers"
                            placeholder={translate("phoneNumber")}
                        />
                        <Button
                            disabled={disabled}
                            onClick={() => onButtonClick(user)}
                            className={`${
                                currentMediaQuery === 2 ? "" : "hidden"
                            } min-w-full mb-6 mt-10 `}
                        >
                            <span className="text-lg">
                                {translate("saveChanges")}
                            </span>
                        </Button>
                        <Paragraph
                            className={`${
                                currentMediaQuery === 2 ? "" : "hidden"
                            } text-xs underline cursor-pointer  self-center`}
                            onClick={handleDeleteAccount}
                        >
                            {translate("deleteAccount")}
                        </Paragraph>
                        <Button
                            disabled={disabled}
                            onClick={() => onButtonClick(user)}
                            className={`${
                                currentMediaQuery === 2 ? "hidden" : ""
                            } min-w-full mt-16`}
                        >
                            <span className="text-lg">
                                {translate("saveChanges")}
                            </span>
                        </Button>
                        <Paragraph
                            className={`${
                                currentMediaQuery === 2 ? "hidden" : ""
                            } text-xs underline cursor-pointer mt-2 self-center`}
                            onClick={handleDeleteAccount}
                        >
                            {translate("deleteAccount")}
                        </Paragraph>
                        {isMobile && version !== "" && (
                            <div className=" text-dark-blue text-base font-normal mt-auto mx-auto">
                                {"Version " + version}
                            </div>
                        )}
                    </div>
                </div>
                {currentMediaQuery === 1 && (
                    <div className="md:pb-6">
                        <NightbornBrand />
                    </div>
                )}
                {openDeleteUserModal && (
                    <DeleteUserModal
                        isVisible={openDeleteUserModal}
                        handleOnClose={() => setOpenDeleteUserModal(false)}
                    />
                )}
            </Page>
        </BusyIndicator>
    );
}
