import Axios, { AxiosRequestConfig } from "axios";

export const AXIOS_INSTANCE = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

const getImprovedConfig = async () => {
    const access_token = localStorage.getItem("ACCESS_TOKEN");
    return {
        headers: {
            Authorization: `bearer ${access_token}`,
        },
    };
};

export const customInstance = async <T>(
    config: AxiosRequestConfig
): Promise<T> => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
        ...config,
        ...(await getImprovedConfig()),
        cancelToken: source.token,
    }).then(({ data }: any) => data);

    // eslint-disable-next-line
    // @ts-ignore
    promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
    };

    return promise;
};
