import { BasicPanelOverlay } from "../../overlay-context";

export default function BasicPanel({ content }: BasicPanelOverlay) {
    //Render
    return (
        <div role="dialog" aria-labelledby="basic-panel-title">
            {content}
        </div>
    );
}
