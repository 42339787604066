import { ProductTagProps } from "./props";

const ProductTag = ({ title }: ProductTagProps) => {
    //Render
    return (
        <div className="bg-tag-background m-1 rounded-lg min-w-fit">
            <div className="font-poppins font-medium text-custom-green text-center px-2 py-1 text-tag w-full">
                {title}
            </div>
        </div>
    );
};

export default ProductTag;
