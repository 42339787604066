import { useQuery } from "react-query";
import EmployeeEventService from "../services/EmployeeEventService";
import EventCompanyRestaurantService from "../services/EventCompanyRestaurantService";
import EventService from "../services/EventService";
import { QueryKey } from "../types/enums/query-key";

export function useGetEvent(companyId: string, eventId: string) {
    return useQuery(
        [QueryKey.Event, { companyId, eventId }],
        () => EventService.get(companyId, eventId).then((res) => res.data),
        { enabled: !!companyId && !!eventId }
    );
}

export function useEventCompanyRestaurant(companyId: string, eventId: string) {
    return useQuery(
        [QueryKey.Event, { companyId, eventId }],
        () =>
            EventCompanyRestaurantService.get(companyId, eventId).then(
                (res) => res.data
            ),
        { enabled: !!companyId && !!eventId }
    );
}

export function useEventEmployee(
    companyId: string,
    eventId: string,
    employeeId: string
) {
    return useQuery(
        [QueryKey.Event, { companyId, eventId, employeeId }],
        () => EmployeeEventService.get(companyId, employeeId, eventId),
        { enabled: !!companyId && !!eventId && !!employeeId }
    );
}
