import React, { useState } from "react";
import Page from "../../components/page";
import SearchInput from "./components/search-input";
import { ReactComponent as SearchCTAImage } from "./assets/icons/search-cta.svg";
import { ReactComponent as SearchCTAMobile } from "./assets/icons/search-cta-mobile.svg";
import ProductGrid from "./components/product-grid";
import { useGetProductsByTagId } from "../../../../go-services/product/product";
import Paragraph from "../../../../components/paragraph";

function Search() {
    //Attributes
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [tag, setTagId] = useState<string>("");
    const [focus, setIsFocus] = useState(false);

    const { data: tagProduct, isLoading: tagProductLoading } =
        useGetProductsByTagId(tag ?? "");

    //Render
    return (
        <Page>
            <div className="h-full max-w-6xl w-full self-center">
                <div className="bg-white md:bg-transparent rounded-md py-3">
                    <SearchInput
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        setTagId={setTagId}
                        setTitle={setTitle}
                        focus={focus}
                        setIsFocus={setIsFocus}
                    />
                </div>
                <div className="h-full" onClick={() => setIsFocus(false)}>
                    {!!tag && (
                        <div className="h-full px-4 z-10">
                            <ProductGrid
                                title={title}
                                products={tagProduct?.data ?? []}
                                isLoading={tagProductLoading}
                            />
                        </div>
                    )}
                    {!tag && (
                        <>
                            <div className="hidden md:flex h-full flex-col space-y-10 justify-center items-center">
                                <SearchCTAImage />
                                <Paragraph className="text-xl font-semibold">
                                    Search using keywords
                                </Paragraph>
                            </div>
                            <div className="flex md:hidden h-full flex-col space-y-10 justify-center items-center">
                                <SearchCTAMobile />
                                <Paragraph className="text-xl font-semibold">
                                    Search using keywords
                                </Paragraph>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Page>
    );
}

export default Search;
