import React from "react";
import { useHistory, useParams } from "react-router";
import Button from "../../../../../../../../components/button";
import Popup from "../../../../../../../../components/popup";
import Title from "../../../../../../../../components/title";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { ReactComponent as TrashIcon } from "./assets/trash.svg";
import { DeleteEventModalProps } from "./props";

const DeleteEventModal = ({
    isVisible,
    handleClose,
}: DeleteEventModalProps) => {
    // Attributes
    const { translate } = useTranslation();
    const history = useHistory();
    const { companyId: companyName } = useParams<CompanyRouteParams>();

    // Handlers
    async function handleDelete() {
        history.push(`/${companyName}`);
        handleClose();
    }

    return (
        <Popup isVisible={isVisible} handleOnClose={() => handleClose()}>
            <div className="flex flex-col items-center">
                <TrashIcon className="mb-5" />
                <Title className="text-center max-w-order-card text-base! font-bold!">
                    deleteEventOrder
                </Title>
                <div className="flex flex-row">
                    <Button
                        onClick={() => handleClose()}
                        className="bg-cancel-background py-2 px-3 rounded-lg text-dark-blue! font-semibold md:py-4 md:px-10 "
                    >
                        {translate("cancel")}
                    </Button>
                    <div className="mr-4" />
                    <Button
                        onClick={handleDelete}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                    >
                        {translate("confirm")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default DeleteEventModal;
