import { EventCompanyRestaurantDTO } from "../types/dtos/event-company-restaurant";
import Service from "./Service";

async function get(companyId: string, eventId: string) {
    return Service.api.get<EventCompanyRestaurantDTO[]>(
        `/v1/api/companies/${companyId}/events/${eventId}/restaurants`
    );
}

export default { get };
