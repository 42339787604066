import { Capacitor } from "@capacitor/core";
import BusyIndicator from "../../../../components/busy-indicator";
import useMedia from "../../../../hooks/useMedia";
import modifyTimeOfLateOrderError from "../../../../utils/modifyErrorMessageKitchenClosed";
import Page from "../../components/page";
import NotificationModal from "../profile/modules/detail/components/notification-modal";
import Footer from "./components/footer";
import KitchenTimingModal from "./components/kitchen-timing-modal";
import PromoBanner from "./components/promo-banner";
import RestaurantSection from "./components/restaurant-section";
import StickyRestaurantsHeader from "./components/sticky-header";
import { HomeProps } from "./props";

import useLogic from "./logic";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import { useProfile } from "../../../../contexts/profile";
import LatestOrdersSection from "./components/latest-orders";

export default function Home({ isPublic }: HomeProps) {
    // Attributes
    const { user } = useProfile();
    const { translate } = useTranslation();
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Global Logic
    const {
        activeRestaurantId,
        setActiveRestaurantId,
        handleRestaurantTabPress,
        handleScroll,
        isPromotionLoading,
        promotion,
        restaurantHeaderRef,
        scrollRef,
        sectionsRef,
        disableAutoScroll,
        companyRestaurants,
        isCompanyRestaurantsLoading,
        isProductsLoading,
        restaurantSectionEntities,
        handleModalClose,
        handleNotificationModalClose,
        isNotificationModalOpen,
        setIsNotificationModalOpen,
        customClosingMsg,
        isKitchenClose,
        isTemporaryClosed,
        company,
        orders,
        handleCreateReOrder,
        isReOrderLoading,
    } = useLogic({ currentMediaQuery, isPublic });

    // Render function
    const renderRestaurantSections = () => {
        return restaurantSectionEntities?.map((restaurantSectionEntity) => {
            if (!restaurantSectionEntity?.products) return <></>;

            return (
                <div
                    key={restaurantSectionEntity.restaurantId}
                    id={restaurantSectionEntity.restaurantId}
                    ref={(element) => {
                        if (element) sectionsRef?.current?.push(element);
                    }}
                    style={{ scrollMarginTop: 52 }}
                    className="md:pb-8 pb-3"
                >
                    <RestaurantSection
                        {...restaurantSectionEntity}
                        isPublic={isPublic}
                    />
                </div>
            );
        });
    };

    //Render
    return (
        <Page
            ref={scrollRef}
            isBasketPanelVisible={currentMediaQuery === 2 && !isPublic} // if is mobile, in web we show it in the StickyRestaurantsHeader already
            backgroundColor="white"
            isPublic={isPublic}
            eventBannerVisible={false}
            tabBarVisible={!isPublic}
            onScroll={handleScroll}
        >
            <BusyIndicator
                isLoading={
                    isCompanyRestaurantsLoading ||
                    isProductsLoading ||
                    isPromotionLoading
                }
                queries={[]}
            >
                {/* Promo Banner */}
                <PromoBanner sourceUri={promotion?.data?.[0]?.fileUrl} />

                <StickyRestaurantsHeader
                    ref={restaurantHeaderRef}
                    companyRestaurants={companyRestaurants}
                    activeRestaurantId={activeRestaurantId}
                    disableAutoScroll={disableAutoScroll}
                    setActiveRestaurantId={setActiveRestaurantId}
                    onRestaurantPress={handleRestaurantTabPress}
                    isPublic={isPublic}
                />
                {orders && !!orders.length && (
                    <div className="md:px-6 md:pt-6 px-2 pt-4.5 pb-2">
                        <div className="flex flex-col justify-center no-scrollbar">
                            <LatestOrdersSection
                                handleCreateReOrder={handleCreateReOrder}
                                orders={orders}
                                isReOrderLoading={isReOrderLoading}
                            />
                        </div>
                    </div>
                )}

                <div className="md:px-6 md:pt-6 px-2 pt-4.5 md:pb-32 pb-2">
                    {/* Restaurants Sections */}
                    <div className="flex flex-col justify-center no-scrollbar">
                        {renderRestaurantSections()}
                    </div>
                </div>

                <Footer />
            </BusyIndicator>

            {!isPublic && Capacitor.getPlatform() !== "web" && (
                <NotificationModal
                    isVisible={isNotificationModalOpen}
                    setIsVisible={setIsNotificationModalOpen}
                    handleOnClose={() => handleNotificationModalClose()}
                />
            )}
            {/* Kitchen closed popup */}
            {!isPublic && isKitchenClose && !user.hasSeenKitchenClosedModal && (
                <KitchenTimingModal
                    isOpen={isKitchenClose && !user.hasSeenKitchenClosedModal}
                    handleOnClose={handleModalClose}
                    errorMsg={
                        isTemporaryClosed
                            ? customClosingMsg
                            : modifyTimeOfLateOrderError(company, translate)
                    }
                />
            )}
        </Page>
    );
}
