import React, { useState } from "react";
import Translation from "../../../../../../components/translation";
import { ReactComponent as SuitcaseIcon } from "./assets/suitcase.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { useEvent } from "../../../../../../contexts/event";
import Popup from "../../../../../../components/popup";
import DeleteEventModal from "./components/delete-popup";
import { EventTypeEnum } from "../../../../../../types/enums/event-type";
import { Capacitor } from "@capacitor/core";
import { useParams } from "react-router";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { EnumQueryParams } from "../../../../../../types/enums/query-params";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useEventEmployee } from "../../../../../../queries/event";
import { useProfile } from "../../../../../../contexts/profile";
import EventType from "../../../../modules/events/modules/type";

const plat = Capacitor.platform;

const EventBanner = () => {
    // Attributes
    const { user } = useProfile();
    const { event, dispatch } = useEvent();
    const { companyId } = useParams<CompanyRouteParams>();
    const [openModal, setOpenModal] = useState(false);
    const { translate } = useTranslation();
    const { data: companyQuery } = useOneCompanyQuery(companyId);
    const eventEmployeeQuery = useEventEmployee(
        companyQuery?.id!,
        event.id,
        user.employeeId
    );

    return (
        <div
            className={`flex flex-1 max-h-52px flex-row justify-between bg-event-banner-bg! items-center ${
                plat === "ios" ? "mt-10" : ""
            }`}
        >
            <div className="flex flex-row items-center p-4">
                <SuitcaseIcon />
                <div className="text-custom-green text-sm font-medium ml-4">
                    <Translation>
                        {eventEmployeeQuery.data
                            ? "Has ordered"
                            : event.type === EventTypeEnum.Private
                            ? "OrderPrivateOrder"
                            : "OrderPublicEvent"}
                    </Translation>
                </div>
                <div className="text-custom-green text-sm font-medium ml-4">
                    {event.type === EventTypeEnum.Public &&
                        !eventEmployeeQuery.data &&
                        `${translate("eventMaxAmount")} ${event.maxAmount} € `}
                </div>
            </div>
            <DeleteEventModal
                isVisible={openModal}
                handleClose={() => setOpenModal(false)}
            />
        </div>
    );
};

export default EventBanner;
