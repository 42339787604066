import { useQuery } from "react-query";
import EmployeeService from "../services/EmployeeService";
import { EmployeeDTO } from "../types/dtos/employee";
import { QueryKey } from "../types/enums/query-key";

export function useOneEmployeeQuery(companyId: string, employeeId: string) {
    return useQuery(
        [QueryKey.Employees, { employeeId }],
        () =>
            EmployeeService.get(companyId, employeeId).then((res) => res.data),
        { enabled: !!employeeId }
    );
}

export function useAddEmployeeQuery(companyId: string, employee: EmployeeDTO) {
    return useQuery(
        [QueryKey.Employees, { companyId }],
        () => EmployeeService.post(companyId, employee).then((res) => res.data),
        { enabled: !!companyId }
    );
}
