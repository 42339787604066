import BusyIndicator from "../../../../../../components/busy-indicator";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { formatCurrencyValue } from "../../../../../../lib/formatters";
import { ReactComponent as Dish } from "../../assets/dish.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { ReactComponent as Tag } from "../../assets/tag.svg";
import OrderInformationCard from "../order-information-card";
import PaymentButton from "../payment-button";
import { OrderBreakdownCardProps } from "./props";
import Paragraph from "../../../../../../components/paragraph";
import KitchenTimingModal from "../../../listing/components/kitchen-timing-modal";
import modifyTimeOfLateOrderError from "../../../../../../utils/modifyErrorMessageKitchenClosed";
import useOrderBreakdownLogic from "./logic";

export default function OrderBreakdownCard({
    currency,
    currentBasket,
    refetchCurrentBasket,
    isBasketLoading,
}: OrderBreakdownCardProps) {
    //Attributes
    const { translate } = useTranslation();
    const {
        basketProducts,
        closeKitchenPopup,
        company,
        displayUnavailableProductsWarning,
        generateErrorMessage,
        handleOnPaymentClick,
        isPaymentLoading,
        isProductsLoading,
        listOfUnavailableProduct,
        promoCodeAmount,
        setCloseKitchenPopup,
        shouldDisablePaymentButton,
        subTotal,
        total,
        user,
    } = useOrderBreakdownLogic({
        currency,
        currentBasket,
        refetchCurrentBasket,
        isBasketLoading,
    });

    //Render
    return (
        <BusyIndicator
            isLoading={isBasketLoading || isProductsLoading}
            queries={[]}
        >
            {!isBasketLoading && !!displayUnavailableProductsWarning && (
                <div className="w-full flex items-center bg-error-500 rounded-md p-4 mb-4 gap-4">
                    <div className="flex h-full ">
                        <Warning />
                    </div>
                    <p
                        className="text-sm text-white"
                        dangerouslySetInnerHTML={{
                            __html: generateErrorMessage(
                                listOfUnavailableProduct
                            ),
                        }}
                    />
                </div>
            )}
            <OrderInformationCard
                icon={<Dish />}
                title={translate("orderOverview")}
            >
                {basketProducts?.map((basketProduct) => (
                    <div
                        key={basketProduct.product!.name}
                        className="flex items-center p-4 border-b border-gray-lightest"
                    >
                        <div className="w-8 h-8 flex justify-center items-center bg-gray-light rounded-md mr-4">
                            <span className="text-sm text-black">
                                {basketProduct.quantity}
                            </span>
                        </div>
                        <p className="text-sm font-semibold flex flex-1">
                            {basketProduct.product!.name}
                        </p>

                        <span className="text-xs">
                            {formatCurrencyValue(
                                (basketProduct.product!.unitPrice ?? 0) *
                                    (basketProduct.quantity ?? 0),
                                currency
                            )}
                        </span>
                    </div>
                ))}

                <div className="p-4">
                    <div className="flex pb-2">
                        <Paragraph className="flex flex-1">Subtotal</Paragraph>

                        <span className="text-md font-semibold ml-2">
                            {formatCurrencyValue(subTotal, currency)}
                        </span>
                    </div>

                    <div className="flex py-2 items-center">
                        <Paragraph className="flex flex-1 mr-2">
                            {translate("promoCode")}
                        </Paragraph>

                        <Tag />

                        <span className="text-md font-semibold ml-2">
                            {`- ${formatCurrencyValue(
                                promoCodeAmount,
                                currency
                            )}`}
                        </span>
                    </div>
                    <div className="flex py-2 items-center">
                        <Paragraph className="flex flex-1 mr-2">
                            Delivery
                        </Paragraph>

                        <span className="text-md font-semibold ml-2">
                            🤑 Free
                        </span>
                    </div>

                    <div className="flex pt-2">
                        <p className="flex flex-1 text-custom-green font-bold">
                            Total
                        </p>

                        <span className="text-md font-bold ml-2">
                            {formatCurrencyValue(total, currency)}
                        </span>
                    </div>

                    <PaymentButton
                        disableBasket={shouldDisablePaymentButton(
                            user?.paymentMethod
                        )}
                        isLoading={isPaymentLoading}
                        onClick={handleOnPaymentClick}
                        amount={total}
                        currency={currency}
                        className="block mt-16"
                    />
                </div>
            </OrderInformationCard>
            <KitchenTimingModal
                isOpen={closeKitchenPopup}
                handleOnClose={() => setCloseKitchenPopup(false)}
                errorMsg={modifyTimeOfLateOrderError(company, translate)}
            />
        </BusyIndicator>
    );
}
