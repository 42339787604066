import React, { createContext, useContext, useReducer } from "react";
import {
    initialOrder,
    OrderActionsReducer,
    OrderReduer,
} from "../../reducers/orderReducer";
import { OrderDTO } from "../../types/dtos/order";

interface OrderContextInterface {
    order: OrderDTO;
    dispatch: (action: OrderActionsReducer) => void;
}

const OrderContext = createContext<OrderContextInterface>({
    order: initialOrder,
    dispatch: function (action: OrderActionsReducer) {},
});

export function useOrder() {
    return useContext(OrderContext);
}

const OrderContextProvider = ({ children }: any) => {
    //Attributes
    const OrderProvider = OrderContext.Provider;
    const [order, dispatch] = useReducer(OrderReduer, initialOrder);

    return (
        <OrderProvider value={{ order, dispatch }}>{children}</OrderProvider>
    );
};

export default OrderContextProvider;
