import { UserDTO } from "../types/dtos/user";
import Service from "./Service";

function get(userId: string) {
    return Service.api.get<UserDTO>(`/v1/api/users/${userId}`);
}

function put(userDTO: UserDTO) {
    return Service.api.put<UserDTO>("/v1/api/profile", userDTO);
}

const exported = { get, put };

export default exported;
