import { BasketDTO } from "../types/dtos/basket";
import Service from "./Service";

function getByEmployee(companyId: string, employeeId: string) {
    return Service.api.get<BasketDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/current-basket`
    );
}

function getAllByEmployee(companyId: string, employeeId: string) {
    return Service.api.get<Array<BasketDTO>>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets`
    );
}

function post(companyId: string, employeeId: string, basketDTO: BasketDTO) {
    return Service.api.post<BasketDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets`,
        basketDTO
    );
}

function put(companyId: string, employeeId: string, basketDTO: BasketDTO) {
    return Service.api.put(
        `/v1/api/companies/${companyId}/employees/${employeeId}/baskets`,
        basketDTO
    );
}

const exported = { getByEmployee, getAllByEmployee, post, put };

export default exported;
