import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { useLocation } from "react-router";
import { useOneCompanyQuery } from "../../queries/company";
import { useEventEmployee, useGetEvent } from "../../queries/event";
import {
    eventReducer,
    getInitialEvent,
    IEventActionReducer,
} from "../../reducers/eventReducer";
import { EventDTO } from "../../types/dtos/event";
import { EnumQueryParams } from "../../types/enums/query-params";
import { useProfile } from "../profile";

interface IEventContext {
    event: EventDTO;
    dispatch: any;
    isEventLinkCopied: boolean;
    setIsEventLinkCopied: any;
}

const EventContext = createContext<IEventContext>({
    event: getInitialEvent(),
    dispatch: (action: IEventActionReducer) => {},
    isEventLinkCopied: false,
    setIsEventLinkCopied: () => {},
});

export const useEvent = () => {
    return useContext(EventContext);
};

const EventContextProvider = ({ children }: any) => {
    const { user } = useProfile();
    const [data, dispatch] = useReducer(eventReducer, getInitialEvent());
    const [isEventLinkCopied, setIsEventLinkCopied] = useState(false);
    const location = useLocation();
    const companyName = location.pathname.split("/")[1];
    const eventId = location.pathname.includes("share")
        ? ""
        : location.search.split("=")[1];
    const eventQuery = useGetEvent(companyName, eventId);

    useEffect(() => {
        if (eventQuery.data) {
            dispatch({
                type: "server",
                payload: eventQuery.data,
                target: "_self",
            });
        }
    }, [eventQuery.data]);

    return (
        <EventContext.Provider
            value={{
                event: data,
                dispatch,
                isEventLinkCopied,
                setIsEventLinkCopied,
            }}
        >
            {children}
        </EventContext.Provider>
    );
};

export default EventContextProvider;
