import { useQuery } from "react-query";
import UserPaymentService from "../services/UserPaymentService";
import { QueryKey } from "../types/enums/query-key";

export function useAllUserPaymentMethodQuery(userId: string) {
    return useQuery(
        [QueryKey.UserPaymentMethods, { userId }],
        () => UserPaymentService.get(userId).then((res) => res.data),
        { enabled: !!userId }
    );
}
