/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    PromotionPagingResDTO,
    GetAllPromotionParams,
    PromotionDTO,
    PromotionReqCreateDTO,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getAllPromotion = (
    params?: GetAllPromotionParams,
    signal?: AbortSignal
) => {
    return customInstance<PromotionPagingResDTO>({
        url: `/v1/api/promotion`,
        method: "get",
        params,
        signal,
    });
};

export const getGetAllPromotionQueryKey = (params?: GetAllPromotionParams) => [
    `/v1/api/promotion`,
    ...(params ? [params] : []),
];

export type GetAllPromotionQueryResult = NonNullable<
    Awaited<ReturnType<typeof getAllPromotion>>
>;
export type GetAllPromotionQueryError = unknown;

export const useGetAllPromotion = <
    TData = Awaited<ReturnType<typeof getAllPromotion>>,
    TError = unknown
>(
    params?: GetAllPromotionParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getAllPromotion>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetAllPromotionQueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getAllPromotion>>
    > = ({ signal }) => getAllPromotion(params, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getAllPromotion>>,
        TError,
        TData
    >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<
        TData,
        TError
    > & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createPromotion = (
    promotionReqCreateDTO: PromotionReqCreateDTO
) => {
    return customInstance<PromotionDTO>({
        url: `/v1/api/promotion`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: promotionReqCreateDTO,
    });
};

export type CreatePromotionMutationResult = NonNullable<
    Awaited<ReturnType<typeof createPromotion>>
>;
export type CreatePromotionMutationBody = PromotionReqCreateDTO;
export type CreatePromotionMutationError = unknown;

export const useCreatePromotion = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createPromotion>>,
        TError,
        { data: PromotionReqCreateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createPromotion>>,
        { data: PromotionReqCreateDTO }
    > = (props) => {
        const { data } = props ?? {};

        return createPromotion(data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createPromotion>>,
        TError,
        { data: PromotionReqCreateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const deletePromotionById = (promotionId: string) => {
    return customInstance<void>({
        url: `/v1/api/promotion/${promotionId}`,
        method: "delete",
    });
};

export type DeletePromotionByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof deletePromotionById>>
>;

export type DeletePromotionByIdMutationError = unknown;

export const useDeletePromotionById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePromotionById>>,
        TError,
        { promotionId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deletePromotionById>>,
        { promotionId: string }
    > = (props) => {
        const { promotionId } = props ?? {};

        return deletePromotionById(promotionId);
    };

    return useMutation<
        Awaited<ReturnType<typeof deletePromotionById>>,
        TError,
        { promotionId: string },
        TContext
    >(mutationFn, mutationOptions);
};
