import ButtonProps from "./props";
import { ReactComponent as BackIcon } from "./assets/back-btn.svg";
import { useState } from "react";
import Lottie from "../lottie";

const DEFAULT_OPTIONS = {
    loop: true,
    autoplay: true,
    animationData: require("./assets/27-loading.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function Button({
    className = "",
    children,
    back,
    isLoadingActive = true,
    ...rest
}: ButtonProps) {
    const [extraClassName, setExtraClassName] = useState("");

    if (back) {
        return (
            <button
                {...rest}
                className={`${className} ${
                    rest.disabled ? "bg-gray-DEFAULT cursor-default" : ""
                }`}
            >
                <BackIcon />
            </button>
        );
    }

    return (
        <button
            onMouseEnter={() => setExtraClassName("hover")}
            onMouseLeave={() => setExtraClassName("")}
            onMouseDown={() => setExtraClassName("focus")}
            {...rest}
            className={`py-2 px-3 h-11 flex shadow text-base rounded-md bg-custom-green text-white items-center font-semibold justify-center ${
                rest.disabled ? " cursor-wait" : ""
            } ${className} ${extraClassName}`}
        >
            <div className={`${rest.disabled ? "hidden" : "w-full "}`}>
                {children}
            </div>
            <div
                className={`${
                    rest.disabled
                        ? !isLoadingActive
                            ? "hidden"
                            : ""
                        : "hidden"
                }`}
            >
                <Lottie
                    isClickToPauseDisabled={true}
                    options={DEFAULT_OPTIONS}
                    height={40}
                    width={70}
                />
            </div>
        </button>
    );
}
