import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useOverlay } from "../../../../contexts/overlay/overlay-context";
import AccountInfoPanel from "./components/account-info-panel";
import ChangePasswordPanel from "./components/change-password-panel";
import PaymentMethodPanel from "./components/payment-method-panel";
import Detail from "./modules/detail";

export default function Profile() {
    const history = useHistory();
    const { removeCurrentOverlay } = useOverlay();

    history.listen(() => {
        removeCurrentOverlay();
    });

    return (
        <Switch>
            <Route
                path="/:companyId/profile/info"
                component={AccountInfoPanel}
            />
            <Route
                path="/:companyId/profile/payment-method"
                component={PaymentMethodPanel}
            />
            <Route
                path="/:companyId/profile/change-password"
                component={ChangePasswordPanel}
            />

            <Route exact path="/:companyId/profile" component={Detail} />
        </Switch>
    );
}
