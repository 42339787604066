import { useQuery } from "react-query";
import BasketService from "../services/BasketService";
import { QueryKey } from "../types/enums/query-key";

export function useOneBasketQuery(companyId: string, employeeId: string) {
    return useQuery(
        [QueryKey.Baskets, { id: employeeId }],
        () =>
            BasketService.getByEmployee(companyId, employeeId).then(
                (res) => res.data
            ),
        // Add a stale time to avoid an infinite refetch in the OrderBreakdownCard's useEffect component
        { enabled: !!companyId && !!employeeId, staleTime: 1000 }
    );
}

export function useAllEmployeeBasketQuery(
    companyId: string,
    employeeId: string
) {
    return useQuery(
        [QueryKey.Baskets, { id: employeeId }],
        () =>
            BasketService.getAllByEmployee(companyId, employeeId).then(
                (res) => res.data
            ),
        // Add a stale time to avoid an infinite refetch in the OrderBreakdownCard's useEffect component
        { enabled: !!companyId && !!employeeId, staleTime: 1000 }
    );
}
