import { useCallback, useEffect, useState } from "react";
import Page from "../../../../components/page";
import MyAccountCard from "../../components/my-account-card";
import OptionCard from "../../components/options-card";
import { ReactComponent as User } from "../../assets/user.svg";
import { ReactComponent as UserActive } from "../../assets/user-active.svg";
import { ReactComponent as PaymentMethod } from "../../assets/id-card.svg";
import { ReactComponent as PaymentMethodActive } from "../../assets/id-card-active.svg";
import { ReactComponent as ChangePassword } from "../../assets/change-password.svg";
import { ReactComponent as ChangePasswordActive } from "../../assets/change-password-active.svg";
import { ReactComponent as LogOut } from "../../assets/logOut.svg";
import { ReactComponent as PrivacyPolicy } from "../../assets/privacy-policy.svg";
import { ReactComponent as Bell } from "../../assets/bell.svg";
import { ReactComponent as Message } from "../../assets/message.svg";
import { ProfileDetailProps } from "./props";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import BusyIndicator from "../../../../../../components/busy-indicator";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { useProfile } from "../../../../../../contexts/profile";
import { useOneUserQuery } from "../../../../../../queries/user";
import { Capacitor } from "@capacitor/core";
import LogoutModal from "./components/logout-modal";
import NotificationModal from "./components/notification-modal";
import NightbornBrand from "../../../../../../components/branding";
import { App } from "@capacitor/app";
import useMedia from "../../../../../../hooks/useMedia";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import { Browser } from "@capacitor/browser";

export default function Detail(props: ProfileDetailProps) {
    //Attributes
    const history = useHistory();
    const {
        showTabBar = true,
        infoActive,
        paymentMethodActive,
        changePasswordActive,
    } = props;
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const location = useLocation();
    const { user } = useProfile();
    const query = useOneUserQuery(user.id);
    const plat = Capacitor.getPlatform();
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const [version, setVersion] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);
    const { removeCurrentOverlay, showContactSidePanel } = useOverlay();

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    const handleClickContact = useCallback(() => {
        removeCurrentOverlay();
        showContactSidePanel({
            onButtonClick: () => {
                window.open("mailto:customer-care@foodiz.odoo.com");
            },
        });
    }, [showContactSidePanel, removeCurrentOverlay]);

    // Effects
    useEffect(() => {
        if (plat === "ios" || plat === "android") {
            App.getInfo().then((infos) => {
                setVersion(infos.version);
            });
        }
    }, [plat]);

    //Functions
    const handleLogOut = () => {
        setOpenLogoutModal(true);
    };

    async function openPrivacyPolicies() {
        Browser.open({
            url: "https://foodizstaging.blob.core.windows.net/files/foodiz_privacy_policies.pdf",
        });
    }

    return (
        <Page
            className={
                showTabBar
                    ? "px-4 md:mb-0 md:pt-24 md:mx-auto justify-between "
                    : "px-4 md:mx-auto md:justify-between"
            }
            tabBarVisible={showTabBar}
            isBasketPanelVisible={false}
            eventBannerVisible={false}
        >
            <BusyIndicator query={query}>
                <div className={`md:flex ${plat === "ios" ? "mt-4" : ""} `}>
                    <div className="md:flex-1 md:min-w-custom">
                        <MyAccountCard
                            fname={query.data?.firstName}
                            lname={query.data?.lastName}
                            email={query.data?.email}
                        />

                        <Link
                            to={
                                location.pathname.endsWith("info")
                                    ? `/${companyName}/profile`
                                    : `/${companyName}/profile/info`
                            }
                        >
                            <OptionCard
                                title="accountInfo"
                                icon={infoActive ? <UserActive /> : <User />}
                                active={infoActive}
                            />
                        </Link>

                        <Link
                            to={
                                location.pathname.endsWith("payment-method")
                                    ? `/${companyName}/profile`
                                    : `/${companyName}/profile/payment-method`
                            }
                        >
                            <OptionCard
                                title="paymentMethod"
                                icon={
                                    paymentMethodActive ? (
                                        <PaymentMethodActive />
                                    ) : (
                                        <PaymentMethod />
                                    )
                                }
                                active={paymentMethodActive}
                            />
                        </Link>

                        <Link
                            to={
                                location.pathname.endsWith("change-password")
                                    ? `/${companyName}/profile`
                                    : `/${companyName}/profile/change-password`
                            }
                        >
                            <OptionCard
                                title="changePassword"
                                icon={
                                    changePasswordActive ? (
                                        <ChangePasswordActive />
                                    ) : (
                                        <ChangePassword />
                                    )
                                }
                                active={changePasswordActive}
                            />
                        </Link>

                        {(Capacitor.getPlatform() === "ios" ||
                            Capacitor.getPlatform() === "android") && (
                            <OptionCard
                                title="notifications"
                                icon={<Bell />}
                                onClick={() => setIsNotificationModalOpen(true)}
                            />
                        )}

                        {currentMediaQuery === 2 && (
                            <OptionCard
                                title="contactus"
                                icon={<Message />}
                                onClick={handleClickContact}
                            />
                        )}
                        <OptionCard
                            title="privacyPolicy"
                            icon={<PrivacyPolicy />}
                            onClick={openPrivacyPolicies}
                        />
                        <OptionCard
                            title="logOut"
                            icon={<LogOut />}
                            onClick={() => handleLogOut()}
                        />
                    </div>
                </div>
            </BusyIndicator>
            {openLogoutModal && (
                <LogoutModal
                    isVisible={openLogoutModal}
                    handleOnClose={() => setOpenLogoutModal(false)}
                />
            )}
            {(Capacitor.getPlatform() === "ios" ||
                Capacitor.getPlatform() === "android") && (
                <NotificationModal
                    isVisible={isNotificationModalOpen}
                    setIsVisible={setIsNotificationModalOpen}
                    handleOnClose={() => setIsNotificationModalOpen(false)}
                />
            )}
            {history.location.pathname.split("/").pop() === "profile" && (
                <div className="mb-6">
                    <NightbornBrand />
                    {version && (
                        <div className="text-center text-tag font-bold text-gray-500">
                            {`version ${version}`}
                        </div>
                    )}
                </div>
            )}
        </Page>
    );
}
