import { PromoCodeDTO } from "../types/dtos/promo-code";
import Service from "./Service";

function get(promoCodeId: string) {
    return Service.api.get<PromoCodeDTO>(`/v1/api/promocodes/${promoCodeId}`);
}

const exported = {
    get,
};

export default exported;
