import { createContext, ReactNode, useContext } from "react";
import { OrderDTO } from "../../types/dtos/order";

export enum OverlayType {
    MODAL,
    SIDE_PANEL,
    EDIT_PRODUCT_PANEL,
    ORDER_SIDE_PANEL,
    PROMO_CODE_SIDE_PANEL,
    COMMENT_SIDE_PANEL,
    BASIC_PANEL,
    BASKET_SIDE_PANEL,
    CONTACT_SIDE_PANEL,
}

export interface Overlay {
    id: number;
    type: OverlayType;
    onClose?(): void;
    animate?: boolean;
    animationDirection?: "up" | "left";
    popup?: boolean;
}

export type InternalOverlay<T extends Overlay> = Omit<T, "id" | "type">;

export interface ModalOverlay extends Overlay {}

export interface SidePanelOverlay extends Overlay {
    title: string;
    content?: ReactNode;
    buttonText?: string;
    onButtonClick?(): void;
    onlyContent?: boolean;
    overflow?: boolean;
    isRounded?: boolean;
    isSafeAreaActive?: boolean;
}
export interface EditProductPanelOverlay extends Overlay {
    title: string;
    content?: ReactNode;
    buttonText?: string;
    onButtonClick?(): void;
    onlyContent?: boolean;
}

export interface BasketSidePanelOverlay extends Overlay {
    title: string;
    content?: ReactNode;
    buttonText?: string;
    onButtonClick?(): void;
}

export interface OrderSidePanelOverlay extends Overlay {
    content?: ReactNode;
    order: OrderDTO;
    showBasicInfoOnly?: boolean;
}

export interface PromoCodeSidePanelOverlay extends Overlay {
    content?: ReactNode;
}

export interface CommentSidePanelOverlay extends Overlay {
    content?: ReactNode;
}
export interface ContactSidePanelOverlay extends Overlay {
    onButtonClick(): void;
}

export interface BasicPanelOverlay extends Overlay {
    content?: ReactNode;
}

export interface OverlayContextValue {
    items: Overlay[];
    showModal(modal: InternalOverlay<ModalOverlay>): void;
    showSidePanel(sidePanel: InternalOverlay<SidePanelOverlay>): void;
    showEditProductPanel(
        editProductPanel: InternalOverlay<EditProductPanelOverlay>
    ): void;
    showBasketSidePanel(
        basketSidePanel: InternalOverlay<BasketSidePanelOverlay>
    ): void;
    showOrderSidePanel(
        orderSidePanel: InternalOverlay<OrderSidePanelOverlay>
    ): void;
    showPromoCodeSidePanel(
        promoCodeSidePanel: InternalOverlay<PromoCodeSidePanelOverlay>
    ): void;
    showCommentSidePanel(
        commentSidePanel: InternalOverlay<CommentSidePanelOverlay>
    ): void;
    showContactSidePanel(
        contactSidePanel: InternalOverlay<ContactSidePanelOverlay>
    ): void;
    showBasicPanel(BasicPanel: InternalOverlay<CommentSidePanelOverlay>): void;
    removeCurrentOverlay(): void;
}

export const OverlayContext = createContext<OverlayContextValue>({
    items: [],
    showModal() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showEditProductPanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showBasketSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showOrderSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showPromoCodeSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showCommentSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showContactSidePanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
    removeCurrentOverlay() {
        throw new Error("Could not find an OverlayProvider.");
    },
    showBasicPanel() {
        throw new Error("Could not find an OverlayProvider.");
    },
});

export function useOverlay() {
    return useContext(OverlayContext);
}
