import { useQuery } from "react-query";
import UserService from "../services/UserService";
import { UserDTO } from "../types/dtos/user";
import { QueryKey } from "../types/enums/query-key";

export function useOneUserQuery(userId: string) {
    return useQuery(
        [QueryKey.Users, { userId }],
        () => UserService.get(userId).then((res) => res.data),
        { enabled: !!userId }
    );
}

// export function useUpdateUserQuery(userDTO:UserDTO, userId:string) {
//     return useQuery(
//         [QueryKey.Users, { userId }],
//         () => UserService.put(userDTO).then(res => res.data),
//         { enabled: !!userDTO }
//     )
// }
