import { EmployeeDTO } from "../types/dtos/employee";
import Service from "./Service";

function get(companyId: string, employeeId: string) {
    return Service.api.get<EmployeeDTO>(
        `/v1/api/companies/${companyId}/employees/${employeeId}`
    );
}

function post(companyId: string, employeeDto: EmployeeDTO) {
    return Service.api.post<EmployeeDTO>(
        `/v1/api/companies/${companyId}/employees`,
        employeeDto
    );
}

const exported = {
    get,
    post,
};

export default exported;
