export function formatCurrencyValue(amount: number, currency: string = "EUR") {
    return Intl.NumberFormat(undefined, {
        currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

/**
 * Conditionally combines the provided CSS classes into one string.
 * @param args The CSS class list to conditionally apply.
 * @example
 *
 * <p className={className("text:sm", "mt-4", { "mb-4": !!props.children, "md:max-w-4xl": props.large })}>
 *  Hello!
 * </p>
 */
export function className(
    ...args: Array<string | { [key: string]: boolean }>
): string {
    const test = args
        .reduce<string>((acc, curr) => {
            if (typeof curr === "string") {
                return (acc += `${curr} `);
            }

            return (acc += Object.keys(curr).reduce((objAcc, objCurr) => {
                // @ts-ignore
                if (curr[objCurr as keyof typeof objCurr]) {
                    return (objAcc += `${objCurr} `);
                }

                return objAcc;
            }, ""));
        }, "")
        .trim();

    return test;
}
