import DishDescriptionProps from "./props";

const DishDescription = ({
    basketProduct,
    isReOrderDisplayed,
}: DishDescriptionProps) => {
    //Attributes
    const reorderProductPrice = (
        ((basketProduct.product?.unitPrice * basketProduct.indexPrice) / 100) *
        basketProduct.quantity
    ).toFixed(2);
    const productPrice = (
        ((basketProduct.baseAmount * basketProduct.indexPrice) / 100) *
            basketProduct.quantity -
        basketProduct.refundAmount
    ).toFixed(2);

    //Functions
    function displayProductPrice() {
        if (isReOrderDisplayed) {
            return reorderProductPrice;
        }
        return productPrice;
    }

    //Render
    return (
        <div className="flex flex-col justify-between">
            <div className="text-xs w-full font-semibold">
                {basketProduct.product.name}
            </div>
            <div className="text-secondary font-semibold text-xs leading-4 bg-white">
                {displayProductPrice() + "€"}
            </div>
        </div>
    );
};

export default DishDescription;
