import { useQuery } from "react-query";
import PromoCodeService from "../services/PromoCodeService";
import { QueryKey } from "../types/enums/query-key";

export function useOnePromoCodeQuery(promoCodeId: string) {
    return useQuery(
        [QueryKey.PromoCode, { id: promoCodeId }],
        () => PromoCodeService.get(promoCodeId).then((res) => res.data),
        { enabled: !!promoCodeId }
    );
}
