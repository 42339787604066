import { useQuery } from "react-query";
import OrderService from "../services/OrderService";
import { QueryKey } from "../types/enums/query-key";

export function useAllOrdersByCompanyAndEmployeeQuery(
    companyId: string,
    employeeId: string,
    sortBy?: string,
    applyEnabledProductFilters?: boolean
) {
    return useQuery(
        [QueryKey.Orders, { companyId, employeeId }],
        () =>
            OrderService.getAll(
                companyId,
                employeeId,
                sortBy,
                applyEnabledProductFilters
            ).then((res) => res.data),
        { enabled: !!companyId && !!employeeId }
    );
}

export function useCurrentOrderByBasketQuery(
    companyId: string,
    employeeId: string,
    basketId: string
) {
    return useQuery(
        [QueryKey.Orders, { companyId, employeeId, basketId }],
        () =>
            OrderService.get(companyId, employeeId, basketId).then(
                (res) => res.data
            ),
        { enabled: !!companyId && !!employeeId && !!basketId }
    );
}

export function useOneOrderQuery(
    companyId: string,
    employeeId: string,
    basketId: string,
    orderId: string
) {
    return useQuery(
        [QueryKey.Orders, { companyId, employeeId, basketId, orderId }],
        () =>
            OrderService.getByOrderId(
                companyId,
                employeeId,
                basketId,
                orderId
            ).then((res) => res.data),
        { enabled: !!companyId && !!employeeId && !!basketId && !!orderId }
    );
}
