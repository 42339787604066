import Paragraph from "../../../../../../components/paragraph";
import SearchProductCard from "./components/search-product-card";
import { ReactComponent as NoResult } from "../../assets/icons/error-cta.svg";
import { ProductGridProps } from "./props";

function ProductGrid({ products, title, isLoading }: ProductGridProps) {
    //Render
    return (
        <div className="h-full">
            {!isLoading && products.length > 0 && (
                <div className="pt-8 md:pt-12">
                    <Paragraph
                        style={{ color: "#006462" }}
                        className="text-xl font-semibold"
                    >
                        {title}
                    </Paragraph>
                    <div className="w-full flex flex-wrap pt-8 md:grid md:grid-cols-2 lg:grid-cols-3">
                        {products &&
                            products.map((product, index) => {
                                return (
                                    <div className="w-full p-2">
                                        <SearchProductCard
                                            index={index}
                                            product={product}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}

            {!isLoading && products.length === 0 && title && (
                <div className="h-full justify-center">
                    <div className="flex flex-col items-center justify-center h-full px-16">
                        <NoResult />
                        <Paragraph
                            style={{ color: "#222B45" }}
                            className="text-2xl font-semibold text-align-center text-center pt-8"
                        >
                            Error
                        </Paragraph>
                        <Paragraph
                            style={{ color: "#222B45" }}
                            className="text-lg font-regular text-align-center font-poppins text-center pt-5"
                        >
                            {`We haven't found any meals with "${title}"`}
                        </Paragraph>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProductGrid;
