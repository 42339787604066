import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import Name from "./modules/name";
import Password from "./modules/password";
import ConfirmEmail from "./modules/confirm-email";

function SignUp() {
    // Attributes
    const { companyId } = useParams<CompanyRouteParams>();

    return (
        <Switch>
            <Route path="/:companyId/sign-up/name" component={Name} />
            <Route path="/:companyId/sign-up/password" component={Password} />
            <Route
                path="/:companyId/sign-up/confirm-email"
                component={ConfirmEmail}
            />

            <Route
                path="/:companyId/sign-up"
                component={() => (
                    <Redirect push={false} to={`/${companyId}/sign-up/name`} />
                )}
            />
        </Switch>
    );
}

export default SignUp;
