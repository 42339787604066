import Button from "../../../../components/button";
import Paragraph from "../../../../components/paragraph";
import Title from "../../../../components/title";
import { useTranslation } from "../../../translation/TranslationContext";
import { ReactComponent as Contact } from "../../assets/contact.svg";
import { ReactComponent as Close } from "../../assets/close-1.svg";
import { ContactSidePanelOverlay } from "../../overlay-context";

export default function ContactSidePanel({
    onClose,
    onButtonClick,
}: ContactSidePanelOverlay) {
    //Attributes
    const { translate } = useTranslation();

    //Render
    return (
        <div
            role="dialog"
            aria-labelledby="side-panel-title"
            className="absolute md:relative rounded-t-3xl md:rounded-20px  w-full z-10 no-scrollbar flex flex-col flex-grow bg-light-beige overflow-y-auto md:min-w-side-panel md:m-8 md:ml-auto md:w-1/4 md:max-w-side-panel md:max-h-product-overlay-panel max-h-9/10 bottom-0"
        >
            <div className="flex flex-col w-full h-18 bg-white rounded-t-3xl px-4 py-6">
                <div onClick={onClose}>
                    <Close />
                </div>
            </div>
            <div className="flex flex-col px-4 py-16 gap-6 text-center items-center">
                <Contact />
                <div className="flex flex-col ">
                    <Title className="">contactus</Title>
                    <Paragraph className="text-sm font-normal">
                        {translate("contactusInfo")}
                    </Paragraph>
                </div>
                <a href="mailto:customer-care@foodiz.odoo.com" target="_blank">
                    <Paragraph className="underline text-button-tertiary text-base font-normal">
                        customer-care@foodiz.odoo.com
                    </Paragraph>
                </a>
            </div>
            <div className="w-full px-4 pb-6">
                <Button
                    onClick={onButtonClick}
                    className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10 w-full"
                >
                    {translate("contactNow")}
                </Button>
            </div>
        </div>
    );
}
