import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import { ReactComponent as Bar } from "./assets/bar.svg";
import { ReactComponent as BasketIcon } from "./assets/basket.svg";
import { ReactComponent as BasketActive } from "./assets/basket-active.svg";
import { ReactComponent as Home } from "./assets/home.svg";
import { ReactComponent as HomeActive } from "./assets/home-active.svg";
import { ReactComponent as Foodiz } from "./assets/foodiz.svg";
import {
    OverlayType,
    useOverlay,
} from "../../../../contexts/overlay/overlay-context";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import Basket from "../../modules/basket";
import { useAllBasketProduct } from "../../../../queries/basketProduct";
import { useOneCompanyQuery } from "../../../../queries/company";
import { useProfile } from "../../../../contexts/profile";
import { useOneBasketQuery } from "../../../../queries/basket";
import { Capacitor } from "@capacitor/core";
import { useOneEmployeeQuery } from "../../../../queries/employee";
import useMedia from "../../../../hooks/useMedia";

export const TAB_BAR_HEIGHT = 65;

function EventTabBar() {
    const history = useHistory();
    const { translate } = useTranslation();
    const { removeCurrentOverlay, showBasketSidePanel, items } = useOverlay();
    const { companyId: paramCompanyId } = useParams<CompanyRouteParams>();

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    const { data: companyQuery } = useOneCompanyQuery(paramCompanyId);
    const { user } = useProfile();
    const { data: basketQuery } = useOneBasketQuery(
        companyQuery?.id!,
        user.employeeId
    );
    const basketId = basketQuery?.id;
    const basketProductQuery = useAllBasketProduct(
        companyQuery?.id!,
        user.employeeId,
        basketId!
    );
    const employeeQuery = useOneEmployeeQuery(paramCompanyId, user.employeeId);

    //Effects
    useEffect(() => {
        basketProductQuery.refetch();
    }, [basketQuery]);

    const routes = useMemo(
        () => [
            {
                icon: Home,
                iconActive: HomeActive,
                to: `/${paramCompanyId}`,
                name: "Home",
            },
            {
                icon: BasketIcon,
                iconActive: BasketActive,
                to: `/${paramCompanyId}/basket`,
                name: "Basket",
            },
        ],
        [paramCompanyId]
    );

    //Functions
    const handleOnClickBasketIcon = useCallback(() => {
        removeCurrentOverlay();
        showBasketSidePanel({
            title: translate("myBasket"),
            content: <Basket />,
            animationDirection: currentMediaQuery === 1 ? "left" : "up",
        });
    }, [showBasketSidePanel, translate]);

    const handleOnClick = (navigateTo: string) => {
        removeCurrentOverlay();
        history.replace(navigateTo);
    };

    const plat = Capacitor.platform;

    if (!employeeQuery.data) {
        return <Fragment />;
    }

    return (
        <div
            className="flex items-center shadow-navBar bg-white md:px-16 min-w-full overflow-hidden relative z-30 pb-5"
            style={{ paddingBottom: plat === "ios" ? 15 : 0 }}
        >
            <div className="hidden md:block flex-1">
                <Foodiz />
            </div>

            <nav
                className="flex flex-grow justify-center"
                style={{ height: TAB_BAR_HEIGHT }}
            >
                {routes.map((route) =>
                    route.to === `/${paramCompanyId}/basket` ? (
                        <div
                            onClick={handleOnClickBasketIcon}
                            className="md:flex-initial md:w-32 h-full flex flex-1 items-center justify-center relative md:flex-grow-0 cursor-pointer"
                        >
                            <Bar
                                className="absolute top-0 md:hidden"
                                style={{
                                    opacity:
                                        items[0]?.type ===
                                        OverlayType.BASKET_SIDE_PANEL
                                            ? 1
                                            : 0,
                                }}
                            />

                            {/* basket popup for number of products in basket */}
                            <div
                                className={`${
                                    basketProductQuery.data?.length! > 0
                                        ? ""
                                        : "hidden"
                                } p-2 absolute ml-5 mb-2 rounded-full h-4 w-4 flex items-center justify-center align-middle bg-violet-DEFAULT! bg-opacity-75 text-white text-xs`}
                            >
                                {basketProductQuery.data?.length || 0}
                            </div>

                            {items[0]?.type ===
                            OverlayType.BASKET_SIDE_PANEL ? (
                                <route.iconActive />
                            ) : (
                                <route.icon />
                            )}

                            <div
                                className="hidden md:block md:absolute md:bottom-0"
                                style={{ transform: "rotate(180deg)" }}
                            >
                                <Bar
                                    style={{
                                        opacity:
                                            items[0]?.type ===
                                            OverlayType.BASKET_SIDE_PANEL
                                                ? 1
                                                : 0,
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className="cursor-pointer h-full flex flex-1 items-center relative md:flex-grow-0"
                            onClick={() => handleOnClick(route.to)}
                        >
                            <Link
                                replace
                                key={route.to}
                                to={route.to}
                                className="flex flex-1 justify-center md:flex-initial md:w-32"
                            >
                                <Bar
                                    className="absolute top-0 md:hidden"
                                    style={{
                                        opacity:
                                            (items.length === 0 &&
                                                history.location.pathname ===
                                                    route.to) ||
                                            (items.length === 0 &&
                                                history.location.pathname.includes(
                                                    "/profile"
                                                ) &&
                                                route.name === "Profile") ||
                                            (items.length === 0 &&
                                                history.location.pathname.includes(
                                                    "/events"
                                                ) &&
                                                route.name === "Event")
                                                ? 1
                                                : 0,
                                    }}
                                />

                                {(items.length === 0 &&
                                    history.location.pathname === route.to) ||
                                (items.length === 0 &&
                                    history.location.pathname.includes(
                                        "/profile"
                                    ) &&
                                    route.name === "Profile") ||
                                (items.length === 0 &&
                                    history.location.pathname.includes(
                                        "/events"
                                    ) &&
                                    route.name === "Event") ? (
                                    <route.iconActive />
                                ) : (
                                    <route.icon />
                                )}

                                <div
                                    className="hidden md:block md:absolute md:bottom-0"
                                    style={{ transform: "rotate(180deg)" }}
                                >
                                    <Bar
                                        style={{
                                            opacity:
                                                (items.length === 0 &&
                                                    history.location
                                                        .pathname ===
                                                        route.to) ||
                                                (items.length === 0 &&
                                                    history.location.pathname.includes(
                                                        "/profile"
                                                    ) &&
                                                    route.name === "Profile") ||
                                                (items.length === 0 &&
                                                    history.location.pathname.includes(
                                                        "/events"
                                                    ) &&
                                                    route.name === "Event")
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </div>
                            </Link>
                        </div>
                    )
                )}
            </nav>

            {/* to center nav elements */}
            <div className="hidden md:flex md:flex-1 opacity-0">
                <Foodiz />
            </div>
        </div>
    );
}

export default EventTabBar;
