import Button from "../../../../../../components/button";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { formatCurrencyValue } from "../../../../../../lib/formatters";
import { PaymentButtonProps } from "./props";
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg";
import { useProfile } from "../../../../../../contexts/profile";
import { PaymentMethod } from "../../../../../../types/enums/payment-method";
import Lottie from "../../../../../../components/lottie";

const DEFAULT_OPTIONS = {
    loop: true,
    autoplay: true,
    animationData: require("./assets/27-loading.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function PaymentButton({
    amount,
    className = "",
    disabled,
    disableBasket,
    isLoading,
    ...rest
}: PaymentButtonProps) {
    //Attributes
    const { translate } = useTranslation();
    const { user } = useProfile();

    //Render
    return (
        <Button
            {...rest}
            className={`w-full  ${
                isLoading || disableBasket
                    ? "bg-gray-DEFAULT cursor-not-allowed"
                    : "bg-yellow-DEFAULT"
            } flex ${className}`}
            isLoadingActive={false}
        >
            <div
                className="flex flex-1 items-center justify-between w-full"
                data-cy="paymentButton"
            >
                {isLoading ? (
                    <div className="flex flex-1 items-center justify-center w-full ">
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={DEFAULT_OPTIONS}
                            height={40}
                            width={70}
                        />
                    </div>
                ) : (
                    <>
                        <span className="text-sm font-semibold text-white">
                            {formatCurrencyValue(amount)}
                        </span>

                        <div className="flex">
                            <p className="flex-1 text-sm font-semibold text-white text-right mx-2">
                                {user.paymentMethod !== PaymentMethod.CreditCard
                                    ? translate("checkoutAndPay")
                                    : translate("confirmAndPay")}
                            </p>
                            <Arrow />
                        </div>
                    </>
                )}
            </div>
        </Button>
    );
}
