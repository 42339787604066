import Input from "../../../../../../components/input";
import Paragraph from "../../../../../../components/paragraph";
import { useGetAllTags } from "../../../../../../go-services/tags/tags";
import { SearchInputProps } from "./props";

function SearchInput({
    searchQuery,
    focus,
    setSearchQuery,
    setTagId,
    setTitle,
    setIsFocus,
}: SearchInputProps) {
    //Attributes
    const { data: allTags } = useGetAllTags({});
    const trimmedTags = allTags?.data?.filter((tag) => {
        const lowerCaseTag = tag.label.toLowerCase();
        return lowerCaseTag.startsWith(searchQuery.toLowerCase());
    });

    //Function
    function handleOnClick(id: string, search: string) {
        setTagId(id);
        setSearchQuery(search);
        setTitle(search);
        setIsFocus(false);
    }

    function handleOnChange(value: string) {
        setSearchQuery(value);
        if (value.length === 0) {
            setTagId("");
            setTitle("");
            return setIsFocus(false);
        }
        if (value.length > 0) {
            setIsFocus(true);
        }
    }

    function handleOnClearFunction() {
        setSearchQuery("");
        setTagId("");
        setTitle("");
        setIsFocus(false);
    }

    //Render
    return (
        <div className="bg-color-white md:px-4">
            <div className="md:m-auto px-4 md:px-0">
                <Input
                    onClick={() => {
                        setIsFocus(true);
                    }}
                    value={searchQuery}
                    onChange={(value: string) => handleOnChange(value)}
                    placeholder="Search - Ex: Vegan, Fish, Meat"
                    isSearch
                    isClearable={focus}
                    handleClearFunction={() => handleOnClearFunction()}
                />
            </div>
            {focus && (
                <div className="relative" style={{ padding: "inherit" }}>
                    <div className="bg-white w-full rounded-b-lg absolute mt-1 md:rounded-lg md:py-4 md:px-4 md:max-w-6xl md:m-auto md:mt-2 md:left-0 md:right-0 md:mr-auto md:ml-auto">
                        {trimmedTags?.length === 0 && (
                            <Paragraph className="py-4 pl-6 md:pl-3 text-placeholder-search!">
                                No tag found with the chosen keyword
                            </Paragraph>
                        )}
                        {trimmedTags &&
                            trimmedTags?.slice(0, 6).map((elt) => {
                                return (
                                    <div
                                        className=" cursor-pointer py-4 rounded-lg hover:bg-tag-background my-1 md:py-2"
                                        onClick={() =>
                                            handleOnClick(elt.id, elt.label)
                                        }
                                    >
                                        <Paragraph className="pl-6 md:pl-3">
                                            {elt.label}
                                        </Paragraph>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SearchInput;
