import Service from "../services/Service";
import { ErrorType } from "../types/enums/error-type";

/**
 * @param errorType – The type will allow to define the error context you need to display
 * @param error – The error will allow to find in the different context which error to display
 * @returns Will display the error toast according to the errorType and error
 */
export function errorMapper(errorType: ErrorType, error?: string) {
    switch (errorType) {
        case ErrorType.SIGN_IN:
            return displaySignInErrors(error);
        case ErrorType.SIGN_UP:
            return displaySignUpErrors(error);
        case ErrorType.RESET_PASSWORD:
            return displayResetPasswordErrors(error);
        case ErrorType.PAYMENT:
            return displayPaymentErrors(error);
        case ErrorType.REORDER:
            return displayReOrderErrors(error);
        default:
            return displayDefaultError(error);
    }
}

/**
 * @returns This section is used to defined all the different error context
 */

export function displaySignInErrors(error?: string) {
    switch (error) {
        case "missingCredentialsError":
            return Service.errors.push("missingCredentialsError");
        case "Email not confirmed.":
            return Service.errors.push("unverifiedEmailError");
        default:
            return Service.errors.push("incorrectCredentialsError");
    }
}

export function displaySignUpErrors(error?: string) {
    switch (error) {
        case "missingSignUpInformationsError":
            return Service.errors.push("missingSignUpInformationsError");
        case "missingCredentialsError":
            return Service.errors.push("missingCredentialsError");
        case "subAdressingSignUpError":
            return Service.errors.push("subAdressingSignUpError");
        case "passwordMatchingSignUpError":
            return Service.errors.push("passwordMatchingSignUpError");
        case "usecase/already-exists?name=email":
            return Service.errors.push("existingEmailSignUpError");
        default:
            return Service.errors.push("accountNotCreated");
    }
}

export function displayResetPasswordErrors(error?: string) {
    switch (error) {
        case "User not found.":
            return Service.errors.push("emailNotFoundResetPasswordError");
        case "passwordMatchingSignUpError":
            return Service.errors.push("passwordMatchingSignUpError");
        case "missingInfoResetPasswordError":
            return Service.errors.push("missingInfoResetPasswordError");
        default:
            return Service.errors.push("emailNotFoundResetPasswordError");
    }
}

export function displayPaymentErrors(error?: string) {
    switch (error) {
        case "emptyBasketPaymentError":
            return Service.errors.push("emptyBasketPaymentError");
        case "unavailableProducts":
            return Service.errors.push("unavailableProducts");
        default:
            return Service.errors.push("orderUpdateError");
    }
}

export function displayReOrderErrors(error?: string) {
    switch (error) {
        default:
            return Service.errors.push("reOrderUpdateError");
    }
}

export function displayDefaultError(error?: string) {
    switch (error) {
        default:
            return Service.errors.push("defaultErrorMessage");
    }
}
