import * as _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../../../components/button";
import Input from "../../../../../../../../components/input";
import { useProfile } from "../../../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { UserActionReducer } from "../../../../../profile/modules/detail/reducer";
import AuthCard from "../../../../../../../../components/auth-card";
import Paragraph from "../../../../../../../../components/paragraph";
import { errorMapper } from "../../../../../../../../mappers/message-errors";
import { ErrorType } from "../../../../../../../../types/enums/error-type";

function Name() {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const { companyId } = useParams<CompanyRouteParams>();
    const { user, dispatch } = useProfile();
    const userName: any = _.pick(user, [
        "firstName",
        "lastName",
        "phoneNumber",
    ]);

    //Functions
    const handleOnChange = (payload: string, target: string) => {
        const action: UserActionReducer = {
            payload: payload,
            target: target,
            type: "userChanged",
        };

        dispatch(action);
    };

    const handleOnClick = () => {
        for (const key in userName) {
            if (userName[key] === "") {
                errorMapper(
                    ErrorType.SIGN_UP,
                    "missingSignUpInformationsError"
                );
                return;
            }
        }

        history.push(`/${companyId}/sign-up/password`);
    };

    const handleKeyDown = (key: any) => {
        if (key.key === "Enter") {
            handleOnClick();
        }
    };

    // Rendering
    return (
        <AuthCard
            onGoBack={() => history.goBack()}
            footerContent={
                <Button
                    onClick={handleOnClick}
                    className="min-w-full bg-add-button"
                >
                    Next
                </Button>
            }
            step={2}
        >
            <Paragraph className="text-3xl text-add-button font-poppins font-semibold">
                What’s your name & number? 😇
            </Paragraph>

            <div className="flex-col space-y-3 mt-8">
                <Input
                    placeholder={translate("fname")}
                    type="text"
                    value={user.firstName}
                    label={translate("fname")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) =>
                        handleOnChange(value, "firstName")
                    }
                    onKeyDown={(key) => handleKeyDown(key)}
                />

                <Input
                    placeholder={translate("lname")}
                    type="text"
                    value={user.lastName}
                    label={translate("lname")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) =>
                        handleOnChange(value, "lastName")
                    }
                    onKeyDown={(key) => handleKeyDown(key)}
                />

                <Input
                    placeholder={"+32 XXX XX XX XX"}
                    type="phone"
                    value={user.phoneNumber}
                    label={translate("phoneNumber")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) =>
                        handleOnChange(value, "phoneNumber")
                    }
                    onKeyDown={(key) => handleKeyDown(key)}
                />
            </div>
        </AuthCard>
    );
}

export default Name;
