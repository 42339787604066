import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import SignPage from "../../component/sign-page";
import Confirmation from "./modules/confirmation";
import Email from "./modules/email";

function ForgotPassword() {
    // Attributes
    const { companyId } = useParams<CompanyRouteParams>();

    return (
        <SignPage>
            <Switch>
                <Route
                    path="/:companyId/forgot-password/email"
                    component={Email}
                />
                <Route
                    path="/:companyId/forgot-password/confirmation"
                    component={Confirmation}
                />
                <Route
                    path="/:companyId/forgot-password"
                    component={() => (
                        <Redirect
                            push={false}
                            to={`/${companyId}/forgot-password/email`}
                        />
                    )}
                />
            </Switch>
        </SignPage>
    );
}

export default ForgotPassword;
