import React, { ReactNode, useCallback, useState } from "react";
import { TranslationContext } from "./TranslationContext";

interface TranslationProviderProps {
    children?: ReactNode;
    translations: {
        [key: string]: {
            [language: string]: string;
        };
    };
}

export default function TranslationProvider({
    translations,
    children,
}: TranslationProviderProps) {
    const [language, setLanguage] = useState("en");

    const changeLanguage = useCallback(
        (newLanguage: string) => setLanguage(newLanguage),
        []
    );

    const translate = useCallback(
        (key: string) => {
            try {
                return translations[key][language];
            } catch {
                return `[${key}]`;
            }
        },
        [translations, language]
    );

    return (
        <TranslationContext.Provider
            value={{ language, changeLanguage, translate }}
        >
            {children}
        </TranslationContext.Provider>
    );
}
