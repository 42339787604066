import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import { useProfile } from "../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import BasketPromoCodeService from "../../../../../../services/BasketPromoCodeService";
import Service from "../../../../../../services/Service";
import { PromoCodeDTO } from "../../../../../../types/dtos/promo-code";
import { OrderTimingType } from "../../../../../../types/enums/order-timing-type";
import { PromoType } from "../../../../../../types/enums/promo-type";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import InlineMessage from "../inline-message";
import { IPromoCodePanelProps } from "./props";
import { QueryKey } from "../../../../../../types/enums/query-key";
import * as Sentry from "@sentry/react";

export default function PromoCodePanel({
    currentBasket,
}: IPromoCodePanelProps) {
    //Attribtues
    const { translate } = useTranslation();
    const [promocode, setPromocode] = useState("");
    const { removeCurrentOverlay } = useOverlay();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id ?? "";
    const { user } = useProfile();
    const basketId = currentBasket?.id ?? "";
    const [disabled, setDisabled] = useState(false);
    const queryClient = useQueryClient();
    //Functions
    const handleOnClick = async () => {
        setDisabled(true);

        const promoCodeDto: PromoCodeDTO = {
            id: "",
            creationDate: new Date().toISOString(),
            modificationDate: new Date().toISOString(),
            name: promocode,
            type: PromoType.Amount,
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            companyRestaurantId: "",
            amount: 0,
            usage: 0,
            companies: [
                {
                    name: "",
                    vatNumber: "",
                    deliveryComments: "",
                    address: {
                        id: "",
                        creationDate: new Date().toISOString(),
                        modificationDate: new Date().toISOString(),
                        streetName: "",
                        number: "",
                        zipCode: "",
                        cityName: "",
                        country: "",
                    },
                    addressId: "",
                    logo: "",
                    id: companyId,
                    creationDate: new Date().toISOString(),
                    modificationDate: new Date().toISOString(),
                    mobileLogo: "",
                    orderTimingType: OrderTimingType.Unknown,
                    code: "",
                },
            ],
            restaurants: [],
        };

        const response = await BasketPromoCodeService.post(
            companyId,
            user.employeeId,
            basketId,
            promoCodeDto
        ).catch((e) => {
            Service.errors.push("promoCodeDoesNotExist");
            Sentry.captureException(
                `An Error occred when adding a promocode for the following user : ${user.id}`,
                e
            );
            setDisabled(false);
            return;
        });

        if (response) {
            queryClient.invalidateQueries({ queryKey: QueryKey.Baskets });
            Service.success.push("promoCodeApplied");
            removeCurrentOverlay();
            setDisabled(false);
        } else {
            setDisabled(false);
        }
    };

    function handleKeyDown(event: any) {
        if (event.key === "Enter" && promocode) {
            handleOnClick();
        }
    }

    //Render
    return (
        <div className="flex flex-col flex-1">
            <InlineMessage extraClassName="hidden" type="error">
                {translate("promoCodeDoesNotExist")}
            </InlineMessage>

            <Input
                autoFocus
                onChange={(event) => setPromocode(event)}
                onKeyDown={(event) => handleKeyDown(event)}
                placeholder={translate("promoCodeExample")}
            />

            <Button
                disabled={disabled}
                onClick={handleOnClick}
                className="mt-auto"
            >
                {translate("apply")}
            </Button>
        </div>
    );
}
