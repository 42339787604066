export function buildQueryParams(params: { [key: string]: any }): string {
    const queryParams = Object.keys(params)
        .filter((key) => params[key] !== undefined && params[key] !== null)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        );

    return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
}
