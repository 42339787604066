import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import Page from "../../components/page";
import EventPage from "./components/page";
import EventShare from "./modules/share";
import EventAmount from "./modules/amount";
import EventRestaurants from "./modules/restaurants";
import EventType from "./modules/type";
import PrivateEvent from "./modules/private-events";

const Events = () => {
    // Attributes
    const { companyId } = useParams<CompanyRouteParams>();

    return (
        <Switch>
            {/* <Route path="/:companyId/events/type" component={EventType} />
                <Route
                    path="/:companyId/events/restaurants"
                    component={EventRestaurants}
                />
                <Route
                    path="/:companyId/events/amount"
                    component={EventAmount}
                />
                <Route path="/:companyId/events/sharex component={EventShare} /> */}
            <Route path="/:companyId/events" component={PrivateEvent} />
            <Route
                render={() => (
                    <Redirect push={false} to={`/${companyId}/events`} />
                )}
            />
        </Switch>
    );
};

export default Events;
