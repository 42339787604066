import React from "react";
import ProgressBarProps from "./props";

export default function ProgressBar({
    bgColor,
    completed,
    className = "",
    ...rest
}: ProgressBarProps) {
    //Attributes
    const fillerStyle = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgColor ?? "#FDC635",
        borderRadius: "inherit",
    };

    //Render
    return (
        <div className={`${className}`} {...rest}>
            <div
                style={{ height: 4 }}
                className={"bg-gray-DEFAULT w-progress-bar rounded-2xl"}
            >
                <div style={fillerStyle} className={"text-right"}></div>
            </div>
        </div>
    );
}
