import Popup from "../../../../../../../../components/popup";
import { DeleteUserModalProps } from "./props";
import Title from "../../../../../../../../components/title";
import Button from "../../../../../../../../components/button";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import { useDeleteUserById } from "../../../../../../../../go-services/user/user";
import { useProfile } from "../../../../../../../../contexts/profile";
import { initialUser } from "../../../../modules/detail/reducer";
import { useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { useDeleteEmployeeById } from "../../../../../../../../go-services/employee/employee";
import { useGetCompanyByName } from "../../../../../../../../go-services/company/company";

const DeleteUserModal = ({
    isVisible,
    handleOnClose,
}: DeleteUserModalProps) => {
    // Attributes
    const { translate } = useTranslation();
    const { user, dispatch } = useProfile();
    const { companyId } = useParams<CompanyRouteParams>();
    const { data: company } = useGetCompanyByName(companyId as string, {
        query: { enabled: !!companyId },
    });
    const history = useHistory();

    const { mutateAsync: deleteEmployee, isLoading: loading } =
        useDeleteEmployeeById();

    // Handler
    function handleDeleteUser() {
        deleteEmployee(
            { employeeId: user.employeeId, companyId: company?.id ?? "" },
            {
                onSuccess: () => {
                    //clear user info
                    dispatch({
                        payload: initialUser,
                        target: "self",
                        type: "serverChanged",
                    });
                    //clear user access tokens
                    localStorage.clear();
                    localStorage.setItem("COMPANY_NAME", companyId);
                    history.replace(`/home`);
                },
            }
        );
    }

    //Render
    return (
        <Popup isVisible={isVisible} handleOnClose={handleOnClose}>
            <div className="flex flex-col items-center!">
                <Title className="text-center max-w-xs">
                    deleteUserConfirmation
                </Title>
                <div className="flex flex-row ">
                    <Button
                        onClick={handleOnClose}
                        className="bg-cancel-background py-2 px-3 rounded-lg text-dark-blue! font-semibold md:py-4 md:px-10 "
                    >
                        {translate("no")}
                    </Button>
                    <div className="mr-3" />
                    <Button
                        onClick={handleDeleteUser}
                        className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10"
                        disabled={loading}
                    >
                        {translate("yes")}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default DeleteUserModal;
