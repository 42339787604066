import { forwardRef, Ref, useMemo, useState } from "react";
import { useGetRestaurantById } from "../../../../../../../go-services/restaurant/restaurant";
import { StickyHeaderMenuItemProps } from "./props";

const StickyHeaderMenuItem = forwardRef(
    (
        {
            restaurantId,
            onMenuItemClick,
            activeRestaurantId,
            companyRestaurant,
        }: StickyHeaderMenuItemProps,
        ref: Ref<HTMLDivElement>
    ) => {
        // Attributes
        const [isLoaded, setLoaded] = useState<boolean>(false);
        // Query
        let restaurant;
        if (companyRestaurant === undefined) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { data: restaurantValue } = useGetRestaurantById(
                restaurantId ?? ""
            );
            restaurant = restaurantValue;
        }

        const isActive = useMemo(() => {
            if (!activeRestaurantId) return true;

            return activeRestaurantId === restaurantId;
        }, [activeRestaurantId]);

        //Render
        return (
            <div
                id={restaurantId}
                className="min-w-restaurant-product cursor-pointer z-50"
                ref={ref}
                onClick={onMenuItemClick}
            >
                <img
                    src={
                        restaurant
                            ? restaurant?.pictureUrl
                            : companyRestaurant?.restaurantPictureUrl
                    }
                    className={`min-w-restaurant-product h-fit! max-w-24 object-scale-down cursor-pointer ${
                        isActive ? "opacity-100" : "opacity-25"
                    }`}
                    onLoad={() => setLoaded(true)}
                    style={{
                        visibility: isLoaded ? "visible" : "hidden",
                    }}
                    draggable="false"
                />
            </div>
        );
    }
);

export default StickyHeaderMenuItem;
