import Service from "./Service";

function post(companyId: string, employeeId: string, orderId: string) {
    return Service.api.post(
        `/v1/api/companies/${companyId}/employees/${employeeId}/orders/${orderId}/reorder`
    );
}

const exported = { post };

export default exported;
