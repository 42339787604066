import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import LatestOrderCard from "./components/latest-order-card";
import { ILatestOrdersSectionProps } from "./props";

const LatestOrdersSection = ({
    handleCreateReOrder,
    isReOrderLoading,
    orders,
}: ILatestOrdersSectionProps) => {
    //Attributes
    const { translate } = useTranslation();
    const displayCardFullWidth = orders?.slice(0, 4).length === 4;

    //Render
    return (
        <div className="bg-light-beige w-full rounded-2xl md:p-6 p-3">
            <div className="flex justify-center bg-white w-full rounded-xl shadow-restaurant-banner!">
                <p className="text-center py-4 text-custom-green font-semibold">
                    {translate("yourLastOrders")}
                </p>
            </div>
            <div className="w-full flex overflow-auto pt-8 gap-4 md:gap-6 no-scrollbar">
                {orders?.slice(0, 4)?.map((order) => (
                    <LatestOrderCard
                        key={order?.id}
                        isReOrderLoading={isReOrderLoading}
                        handleCreateReOrder={handleCreateReOrder}
                        order={order}
                        displayCardFullWidth={displayCardFullWidth}
                    />
                ))}
            </div>
        </div>
    );
};

export default LatestOrdersSection;
