import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import "./style.css";
import { ReactComponent as RestaurantNotFound } from "./assets/restaurant_not_found.svg";
import { AppLauncher } from "@capacitor/app-launcher";
import Button from "../../../../components/button";
import { Capacitor } from "@capacitor/core";
const UpdateVersionModal = () => {
    // Attributes
    const { translate } = useTranslation();
    const plat = Capacitor.getPlatform();

    // Handlers
    async function goToStore() {
        const url =
            plat === "ios"
                ? "https://apps.apple.com/be/app/foodiz/id1583583964?l=fr"
                : "https://play.google.com/store/apps/details?id=be.foodiz.app";
        await AppLauncher.openUrl({
            url,
        });
    }

    return (
        <div className="versioning-background">
            <div className="versioning-modal">
                <div className="versioning-modal-title">
                    <div className="versioning-modal-title-left">
                        {translate("Update required")}
                    </div>
                </div>
                <div className="versioning-modal-content">
                    <RestaurantNotFound
                        className="versioning-modal-content-svg"
                        width={250}
                        height={150}
                    />
                    <div className="versioning-modal-content-text">
                        {translate("To continue ordering your lunch,")}
                        <br />
                        {translate("please update the Foodiz app on the store")}
                    </div>
                    <div className="mt-6 mx-4">
                        <Button
                            onClick={goToStore}
                            className="bg-custom-green text-white py-2 px-3 font-semibold rounded-lg md:py-4 md:px-10 w-full "
                        >
                            {plat === "ios"
                                ? translate("goToAppStore")
                                : translate("goToPlayStore")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateVersionModal;
