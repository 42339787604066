import { Fragment, useCallback, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useOverlay } from "../../contexts/overlay/overlay-context";
import { useProfile } from "../../contexts/profile";
import { useTranslation } from "../../contexts/translation/TranslationContext";
import Basket from "../../modules/company/modules/basket";

import Translation from "../translation";
import { ReactComponent as ARROWICON } from "./assets/arrow.svg";
import BasketPanelProps from "./props";
import "./style.css";
import { useGetBasketProducts } from "../../go-services/basket-product/basket-product";
import Button from "../button";
import useMedia from "../../hooks/useMedia";
import { useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../types/params/company-route";
import { useOneCompanyQuery } from "../../queries/company";
import { useOneBasketQuery } from "../../queries/basket";

const BasketPanel = ({ className = "" }: BasketPanelProps) => {
    //Attributes
    const [showPanel, setShowPanel] = useState(false);
    const { removeCurrentOverlay, showBasketSidePanel } = useOverlay();
    const { translate } = useTranslation();
    const { user } = useProfile();
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    // Queries
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );

    const {
        data: basketProducts,
        refetch: refetchBasketProducts,
        isSuccess: isBasketProductSuccess,
    } = useGetBasketProducts(user?.employeeId, currentBasket?.id ?? "", {
        query: { enabled: !!user?.employeeId && !!currentBasket?.id },
    });

    //Effects
    useEffect(() => {
        const t = setTimeout(() => {
            if (basketProducts && basketProducts?.length > 0) {
                setShowPanel(true);
            } else {
                setShowPanel(false);
            }
        }, 200);

        return () => clearTimeout(t);
    }, [basketProducts]);

    useEffect(() => {
        refetchBasketProducts();
    }, [currentBasket]);

    //Functions
    const handleOnClickBasketPanel = useCallback(() => {
        removeCurrentOverlay();
        showBasketSidePanel({
            title: translate("myBasket"),
            content: <Basket />,
            animate: currentMediaQuery === 2,
            animationDirection: currentMediaQuery === 1 ? "left" : "up",
        });
    }, [showBasketSidePanel, translate]);

    //Render
    if (!!showPanel) {
        return (
            <CSSTransition
                in={showPanel}
                timeout={200}
                classNames="panel"
                unmountOnExit
            >
                <div
                    onClick={handleOnClickBasketPanel}
                    className={`bg-white p-4 md:mt-4 z-30 rounded-basket-panel shadow-basketPanel md:mb-auto md:max-w-md ${className}`}
                >
                    <Button
                        className={
                            "min-w-full text-white bg-profile-panel-label rounded-xl p-4 md:max-w-md"
                        }
                        data-cy="seeBasket"
                    >
                        <div className="flex justify-between">
                            <div className="price text-sm font-bold">
                                {currentBasket?.totalPrice?.toFixed(2)} EUR
                            </div>
                            <div className="add-to-basket text-sm font-bold flex flex-row">
                                <div className="mr-4">
                                    <Translation>seeBasket</Translation>
                                </div>
                                <div className="my-auto">
                                    <ARROWICON />
                                </div>
                            </div>
                        </div>
                    </Button>
                </div>
            </CSSTransition>
        );
    }

    return <Fragment />;
};

export default BasketPanel;
