import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../../../components/button";
import Input from "../../../../../../../../components/input";
import { useProfile } from "../../../../../../../../contexts/profile";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import { UserActionReducer } from "../../../../../profile/modules/detail/reducer";
import { ReactComponent as PaymentMethods } from "./assets/payments.svg";
import { useOneCompanyQuery } from "../../../../../../../../queries/company";
import AuthCard from "../../../../../../../../components/auth-card";
import Paragraph from "../../../../../../../../components/paragraph";
import { useCreateEmployee } from "../../../../../../../../go-services/employee/employee";
import { UserReqCreateDTO } from "../../../../../../../../new-types/userReqCreateDTO";
import { errorMapper } from "../../../../../../../../mappers/message-errors";
import { ErrorType } from "../../../../../../../../types/enums/error-type";

function Password() {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const [confirmPassword, setConfirmPassword] = useState("");
    const { user, dispatch } = useProfile();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const [disabled, setDisabled] = useState(false);
    const { mutateAsync: createEmployee } = useCreateEmployee();

    //Functions
    const handleOnChange = (payload: string, target: string) => {
        const action: UserActionReducer = {
            payload: payload,
            target: target,
            type: "userChanged",
        };

        dispatch(action);
    };

    const handleOnClick = async () => {
        setDisabled(true);

        if (user.email === "" || user.password === "") {
            errorMapper(ErrorType.SIGN_UP, "missingCredentialsError");
            setDisabled(false);
            return;
        }

        // check character for sub-addressing
        if (user.email.includes("+")) {
            errorMapper(ErrorType.SIGN_UP, "subAdressingSignUpError");
            setDisabled(false);
            return;
        }

        if (user.password !== confirmPassword) {
            errorMapper(ErrorType.SIGN_UP, "passwordMatchingSignUpError");
            setDisabled(false);
            return;
        }

        //send userReqCreateDTO to add user
        const employee: UserReqCreateDTO = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            password: user.password,
            oldPassword: null,
        };

        const response = await createEmployee(
            {
                companyId: companyId ?? "",
                data: employee,
            },
            {
                onError: (e: any) => {
                    errorMapper(ErrorType.SIGN_UP, e.response.data);
                    setDisabled(false);
                    return;
                },
            }
        );

        if (response) {
            history.replace(`/${companyName}/sign-up/confirm-email`);
        } else {
            errorMapper(ErrorType.SIGN_UP);
            setDisabled(false);
            return;
        }
    };

    const handleKeyDown = (key: any) => {
        if (key.key === "Enter") {
            handleOnClick();
        }
    };

    // Rendering
    return (
        <AuthCard
            onGoBack={() => history.goBack()}
            footerContent={
                <div className="mt-16 mb-1 md:mt-auto space-y-4 justify-center items-center">
                    <Button
                        disabled={disabled}
                        onClick={handleOnClick}
                        className="min-w-full bg-add-button "
                    >
                        Confirm
                    </Button>
                    <PaymentMethods className="w-full" />
                </div>
            }
            step={3}
        >
            <Paragraph className="text-left text-3xl text-add-button font-poppins font-semibold mb-8 leading-8">
                Almost there! Just enter your email & password🤝
            </Paragraph>

            <div className="flex-col space-y-3">
                <Input
                    placeholder={translate("email")}
                    type="email"
                    value={user.email}
                    label={translate("email")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) => handleOnChange(value, "email")}
                    onKeyDown={(key) => handleKeyDown(key)}
                />

                <Input
                    placeholder={translate("password")}
                    type={"password"}
                    value={user.password}
                    label={translate("password")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) =>
                        handleOnChange(value, "password")
                    }
                    onKeyDown={(key) => handleKeyDown(key)}
                />

                <Input
                    placeholder={translate("confirmPassword")}
                    type={"password"}
                    value={confirmPassword}
                    label={translate("confirmPassword")}
                    labelColorClassName="text-profile-panel-label"
                    onChange={(value: string) => setConfirmPassword(value)}
                    onKeyDown={(key) => handleKeyDown(key)}
                />
            </div>
        </AuthCard>
    );
}

export default Password;
