import { useCallback, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import { ReactComponent as BasketIcon } from "./assets/basket.svg";
import { ReactComponent as BasketActive } from "./assets/basket-active.svg";
import { ReactComponent as Home } from "./assets/home.svg";
import { ReactComponent as HomeActive } from "./assets/home-active.svg";
import { ReactComponent as Order } from "./assets/order.svg";
import { ReactComponent as SearchActive } from "./assets/search-active.svg";
import { ReactComponent as Search } from "./assets/search.svg";
import { ReactComponent as OrderActive } from "./assets/order-active.svg";
import { ReactComponent as Profile } from "./assets/profile.svg";
import { ReactComponent as ProfileActive } from "./assets/profile-active.svg";
import { ReactComponent as Foodiz } from "./assets/foodiz.svg";

import { ReactComponent as Clock } from "./assets/clock-nine.svg";
import {
    OverlayType,
    useOverlay,
} from "../../../../contexts/overlay/overlay-context";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";
import Basket from "../../modules/basket";
import { useProfile } from "../../../../contexts/profile";
import { Capacitor } from "@capacitor/core";
import { useOneEmployeeQuery } from "../../../../queries/employee";
import Paragraph from "../../../../components/paragraph";
import { useGetCompanyById } from "../../../../go-services/company/company";
import modifyTimeOfLateOrderError from "../../../../utils/modifyErrorMessageKitchenClosed";
import isHourBetweenBeginAndEndHours from "../../../../utils/isTimeIntervalBetweenGivenDate";
import { formatAddress } from "../../../../utils/formatAddress";
import { useSafeArea } from "../../../../contexts/safe-area";
import { ReactComponent as EventActive } from "./assets/event-active.svg";
import { ReactComponent as EventIcon } from "./assets/event.svg";
import useMedia from "../../../../hooks/useMedia";
import { useGetBasketProducts } from "../../../../go-services/basket-product/basket-product";
import { useOneCompanyQuery } from "../../../../queries/company";
import { useOneBasketQuery } from "../../../../queries/basket";

export const TAB_BAR_HEIGHT = 84;

function TabBar() {
    const history = useHistory();
    const { insets } = useSafeArea();
    const { translate } = useTranslation();
    const { removeCurrentOverlay, showBasketSidePanel, items } = useOverlay();
    const { companyId: paramCompanyId } = useParams<CompanyRouteParams>();
    const { user } = useProfile();

    const { data: companyQuery } = useOneCompanyQuery(paramCompanyId);
    const companyId = companyQuery?.id;
    const { data: currentBasket } = useOneBasketQuery(
        companyId!,
        user.employeeId
    );
    const { data: basketProducts, refetch: refetchBasketProducts } =
        useGetBasketProducts(user?.employeeId, currentBasket?.id ?? "", {
            query: { enabled: !!user?.employeeId && !!currentBasket?.id },
        });

    const { data: company } = useGetCompanyById(paramCompanyId, {
        query: { enabled: !!paramCompanyId },
    });

    const isKitchenClose = isHourBetweenBeginAndEndHours(
        new Date(),
        company?.newKitchenClosingHourBegin as string,
        company?.newKitchenClosingHourEnd as string
    );

    const employeeQuery = useOneEmployeeQuery(paramCompanyId, user.employeeId);
    const isAdmin = employeeQuery.data?.employeeType === 0 ? true : false;
    const adminRoutes = useMemo(
        () => [
            {
                icon: Home,
                iconActive: HomeActive,
                to: `/${paramCompanyId}`,
                name: "Home",
            },
            {
                icon: BasketIcon,
                iconActive: BasketActive,
                to: `/${paramCompanyId}/basket`,
                name: "Basket",
            },
            {
                icon: EventIcon,
                iconActive: EventActive,
                to: `/${paramCompanyId}/events`,
                name: "Event",
            },
            {
                icon: Order,
                iconActive: OrderActive,
                to: `/${paramCompanyId}/orders`,
                name: "Orders",
            },
            {
                icon: Profile,
                iconActive: ProfileActive,
                to: `/${paramCompanyId}/profile`,
                name: "Profile",
            },
            {
                icon: Search,
                iconActive: SearchActive,
                to: `/${paramCompanyId}/search`,
                name: "Search",
            },
        ],
        [paramCompanyId]
    );

    const userRoutes = useMemo(
        () => [
            {
                icon: Home,
                iconActive: HomeActive,
                to: `/${paramCompanyId}`,
                name: "Home",
            },
            {
                icon: BasketIcon,
                iconActive: BasketActive,
                to: `/${paramCompanyId}/basket`,
                name: "Basket",
            },
            {
                icon: Order,
                iconActive: OrderActive,
                to: `/${paramCompanyId}/orders`,
                name: "Order",
            },
            {
                icon: Profile,
                iconActive: ProfileActive,
                to: `/${paramCompanyId}/profile`,
                name: "Profile",
            },
            {
                icon: Search,
                iconActive: SearchActive,
                to: `/${paramCompanyId}/search`,
                name: "Search",
            },
        ],
        [paramCompanyId]
    );

    const routes = isAdmin ? adminRoutes : userRoutes;

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Effects
    useEffect(() => {
        refetchBasketProducts();
    }, [currentBasket]);

    //Functions
    const handleOnClickBasketIcon = useCallback(() => {
        removeCurrentOverlay();
        showBasketSidePanel({
            title: translate("myBasket"),
            content: <Basket />,
            animationDirection: currentMediaQuery === 1 ? "left" : "up",
        });
    }, [showBasketSidePanel, translate]);

    const handleOnClick = (navigateTo: string) => {
        removeCurrentOverlay();
        history.replace(navigateTo);
    };

    const getBasketProductQuantity = () => {
        let quantity = 0;
        basketProducts?.forEach((element) => {
            quantity += element?.quantity ?? 0;
        });

        return quantity;
    };

    // Render
    return (
        <div className="flex flex-1 flex-col bg-white">
            {/* kitchen closed banner mobile */}
            <div
                style={{ height: "79px", backgroundColor: "#A63A50" }}
                className={`${
                    isKitchenClose ? "flex md:hidden" : "hidden"
                }  w-full items-center justify-start text-base font-semibold text-white px-4 py-2`}
            >
                <Clock className="w-12 mr-3" />
                <Paragraph className="text-xs">
                    {modifyTimeOfLateOrderError(company, translate)}
                </Paragraph>
            </div>
            <div
                className={`flex items-center ${
                    user.hasSeenKitchenClosedModal ? "" : "shadow-navBar"
                }  bg-white md:px-16 min-w-full overflow-hidden relative z-30  border-b border-line md:h-21`}
            >
                <div className="hidden md:block">
                    <Foodiz />
                </div>

                <nav
                    className="flex flex-grow justify-center"
                    style={{
                        marginBottom:
                            Capacitor.getPlatform() === "ios"
                                ? insets?.bottom
                                : undefined,
                    }}
                >
                    {routes.map((route) =>
                        route.to === `/${paramCompanyId}/basket` ? (
                            <div
                                key={route?.name}
                                onClick={handleOnClickBasketIcon}
                                className="md:flex-initial md:w-32 bg-[#1da1f1] h-full flex flex-1 items-center justify-center relative md:flex-grow-0 cursor-pointer pr-4"
                            >
                                {/**
                                 * basket popup for number of products in basket
                                 * */}
                                <div
                                    style={{ fontSize: 10 }}
                                    className={`${
                                        basketProducts?.length! > 0
                                            ? ""
                                            : "opacity-0"
                                    } ${
                                        Capacitor.platform === "ios" ||
                                        Capacitor.platform === "android"
                                            ? "translate-x-12!"
                                            : ""
                                    } p-2 transform translate-x-16 -translate-y-4 md:translate-x-18 md:-translate-y-80% rounded-full h-4 w-4 flex items-center justify-center bg-violet-DEFAULT! bg-opacity-50 text-white
                                     ${
                                         getBasketProductQuantity() <= 9
                                             ? "text-xs!"
                                             : ""
                                     }`}
                                >
                                    {getBasketProductQuantity() > 9
                                        ? "9+"
                                        : getBasketProductQuantity()}
                                </div>

                                <div className="flex flex-col flex-1 text-center h-16 justify-between py-2">
                                    <div className="mx-auto">
                                        {items[0]?.type ===
                                        OverlayType.BASKET_SIDE_PANEL ? (
                                            <route.iconActive />
                                        ) : (
                                            <route.icon />
                                        )}
                                    </div>

                                    <div
                                        className={`${
                                            items[0]?.type ===
                                            OverlayType.BASKET_SIDE_PANEL
                                                ? "text-yellow-DEFAULT font-poppins! font-semibold"
                                                : "text-gray-DEFAULT font-poppins!"
                                        } text-xs`}
                                    >
                                        {route.name}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                key={route?.name}
                                className="cursor-pointer h-full flex flex-1 items-center relative md:flex-grow-0"
                                onClick={() => handleOnClick(route.to)}
                            >
                                <Link
                                    replace
                                    key={route.to}
                                    to={route.to}
                                    className="flex flex-1 justify-center md:flex-initial md:w-32"
                                >
                                    <div className="flex flex-col flex-1 h-16 justify-between py-2 m-auto text-center">
                                        <div className="mx-auto">
                                            {(items.length === 0 &&
                                                history.location.pathname ===
                                                    route.to) ||
                                            (items.length === 0 &&
                                                history.location.pathname.includes(
                                                    "/profile"
                                                ) &&
                                                route.name === "Profile") ||
                                            (items.length === 0 &&
                                                history.location.pathname.includes(
                                                    "/events"
                                                ) &&
                                                route.name === "Event") ? (
                                                <route.iconActive />
                                            ) : (
                                                <route.icon />
                                            )}
                                        </div>

                                        <div
                                            className={`${
                                                (items.length === 0 &&
                                                    history.location
                                                        .pathname ===
                                                        route.to) ||
                                                (items.length === 0 &&
                                                    history.location.pathname.includes(
                                                        "/profile"
                                                    ) &&
                                                    route.name === "Profile") ||
                                                (items.length === 0 &&
                                                    history.location.pathname.includes(
                                                        "/events"
                                                    ) &&
                                                    route.name === "Event")
                                                    ? "text-yellow-DEFAULT font-poppins! font-semibold"
                                                    : "text-gray-DEFAULT font-poppins!"
                                            } text-xs`}
                                        >
                                            {route.name}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    )}
                </nav>

                <div className="hidden md:flex flex-col justify-end items-end">
                    <Paragraph className="font-semibold text-base  text-right">
                        {company?.name ?? ""}
                    </Paragraph>
                    <Paragraph className="font-normal text-sm  text-right ">
                        {formatAddress(company?.address)}
                    </Paragraph>
                </div>
            </div>
            {/* kitchen closed banner desktop */}
            <div
                style={{ height: "79px", backgroundColor: "#A63A50" }}
                className={` ${
                    isKitchenClose ? "md:flex hidden" : "hidden"
                } w-full items-center justify-start text-base font-semibold text-white px-4 py-2`}
            >
                <Clock className="w-12 mr-3" />
                <Paragraph className="text-base!">
                    {modifyTimeOfLateOrderError(company, translate)}
                </Paragraph>
            </div>
        </div>
    );
}

export default TabBar;
