import { ILatestOrdersCardProps } from "./props";
import { ReactComponent as Info } from "../assets/info.svg";
import Button from "../../../../../../../../components/button";
import { useOverlay } from "../../../../../../../../contexts/overlay/overlay-context";
import { useCallback } from "react";
import OrderDetail from "../../../../../orders/component/order-detail";
import { OrderDTO } from "../../../../../../../../types/dtos/order";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";

const LatestOrderCard = ({
    handleCreateReOrder,
    isReOrderLoading,
    order,
    displayCardFullWidth,
}: ILatestOrdersCardProps) => {
    //Attributes
    const { translate } = useTranslation();
    const { showOrderSidePanel } = useOverlay();
    const numberOfItemsInBasket = order?.basketDTO?.basketProducts?.length;
    const firstProductInList = order?.basketDTO?.basketProducts[0]?.product;

    //Functions
    const onClickOrder = useCallback(() => {
        if (!order) return;
        showOrderSidePanel({
            content: (
                <OrderDetail
                    key={order?.id}
                    isReOrderDisplayed
                    handleCreateReOrder={handleCreateReOrder}
                    order={order!}
                    priceToDisplay={calculateTotalPrice(order)}
                    isReOrderLoading={isReOrderLoading}
                />
            ),
            order: order,
            showBasicInfoOnly: true,
        });
    }, []);

    function calculateTotalPrice(order: OrderDTO) {
        // Initialize total price to zero
        let totalPrice = 0;

        // Check if basketDTO and basketProducts exist
        if (order.basketDTO && Array.isArray(order.basketDTO.basketProducts)) {
            // Iterate through each basketProduct
            order.basketDTO.basketProducts.forEach((basketProduct) => {
                // Calculate the total amount for each product
                const productTotal =
                    (basketProduct.quantity *
                        (basketProduct.product.unitPrice *
                            basketProduct.indexPrice)) /
                    100;
                // Add the product total to the total price
                totalPrice += productTotal;
            });
        }

        // Return the total price
        return totalPrice;
    }

    //Render
    return (
        <div
            className={`rounded-xl flex bg-white min-w-last-order-card-mobile ${
                displayCardFullWidth ? "md:w-full" : "md:w-last-order-card"
            } h-36 overflow-hidden no-scrollbar!`}
        >
            <img
                onClick={onClickOrder}
                src={firstProductInList?.pictureUrl}
                className="cursor-pointer w-27 md:h-36 md:w-36 object-cover"
            />
            <div className="w-full flex flex-col h-full px-2 py-3 justify-between">
                <p className="font-semibold font-poppins">
                    {firstProductInList?.name}
                    {numberOfItemsInBasket > 1 && "..."}
                </p>
                <div
                    className="cursor-pointer flex flex-row items-center gap-2 bg-new-beige w-fit px-2 rounded-lg"
                    onClick={onClickOrder}
                >
                    <Info />
                    <p className="w-fit text-xs underline font-poppins">
                        {translate("seeAllArticles")}
                    </p>
                </div>
                <div className="w-full">
                    <Button
                        disabled={isReOrderLoading}
                        className="w-full max-h-8 bg-event-banner-bg "
                        onClick={() => handleCreateReOrder(order?.id)}
                    >
                        <div className="flex justify-between items-center">
                            <p className="font-semibold text-xs">
                                {"Reorder " +
                                    calculateTotalPrice(order).toFixed(2) +
                                    "€"}
                            </p>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LatestOrderCard;
