import { format } from "date-fns";
import { OrderSidePanelOverlay } from "../../overlay-context";
import { ReactComponent as Close } from "../../assets/cross.svg";
import TabBar from "../../../../modules/company/components/tab-bar";
import Paragraph from "../../../../components/paragraph";
import { Capacitor } from "@capacitor/core";
import { EventTypeEnum } from "../../../../types/enums/event-type";
import EventTabBar from "../../../../modules/company/components/event-tab-bar";
import { useEvent } from "../../../event";

const plat = Capacitor.platform;

export default function OrderSidePanel({
    onClose,
    content,
    order,
    showBasicInfoOnly = false,
}: OrderSidePanelOverlay) {
    //Attributes
    const dateElements = order.modificationDate
        .split(/-|T/)
        .map((el: any) => parseInt(el));
    const year = dateElements[0];
    const month = dateElements[1];
    const day = dateElements[2];
    const { event } = useEvent();

    //Render
    return (
        <>
            <div
                role="dialog"
                aria-labelledby="side-panel-title"
                className="z-10 flex flex-col flex-grow bg-fifth overflow-y-auto md:min-w-side-panel md:m-8 md:ml-auto md:rounded-3xl md:w-1/4 md:max-w-side-panel md:max-h-product-overlay-panel"
            >
                <div
                    className="flex flex-grow-0 p-6 md:shadow-sm bg-white justify-between"
                    style={{ paddingTop: plat === "ios" ? 48 : 24 }}
                >
                    <div className="order-description flex flex-col content-start">
                        <Paragraph
                            className={`text-lg font-bold ${
                                !showBasicInfoOnly && "mb-4"
                            }`}
                        >
                            {`${day} ${format(
                                new Date(year, month - 1, day),
                                "MMMM"
                            )} ${year}`}
                        </Paragraph>
                        {!showBasicInfoOnly && (
                            <div className="text-base text-third leading-5">
                                {order.basketDTO.basketProducts && (
                                    <Paragraph>
                                        {`${
                                            order.basketDTO.basketProducts
                                                .length
                                        } ${
                                            order.basketDTO.basketProducts
                                                .length > 1
                                                ? "items"
                                                : "item"
                                        } -  ${order.totalPrice.toFixed(2)}€`}
                                    </Paragraph>
                                )}
                                <Paragraph>{`Ticket ID : ${order.ticketId}`}</Paragraph>
                            </div>
                        )}
                    </div>
                    <div className="cursor-pointer">
                        <button onClick={onClose}>
                            <Close />
                        </button>
                    </div>
                </div>

                <div className="flex flex-1 flex-col p-6 bg-fifth overflow-y-auto">
                    {content}
                </div>
                <div className="md:hidden">
                    {event.id && event.type === EventTypeEnum.Public ? (
                        <EventTabBar />
                    ) : (
                        <TabBar />
                    )}
                </div>
            </div>
        </>
    );
}
