import Title from "../../../../components/title";

import ConfirmBasketPanel from "../../../../modules/company/modules/basket/components/confirm-basket-panel";

import { ReactComponent as NewCross } from "../../assets/new-cross.svg";
import { BasketSidePanelOverlay } from "../../overlay-context";

export default function BasketSidePanel({
    onClose,
    content,
}: BasketSidePanelOverlay) {
    //Render
    return (
        <>
            <div
                role="dialog"
                aria-labelledby="side-panel-title"
                className="absolute md:relative rounded-t-3xl md:rounded-20px h-full w-full z-10 no-scrollbar flex flex-col flex-grow bg-beige overflow-y-auto md:min-w-side-panel md:m-8 md:ml-auto md:w-1/4 md:max-w-side-panel md:max-h-product-overlay-panel max-h-9/10 bottom-0"
            >
                <div className="flex items-center md:h-20 h-27 bg-white px-4">
                    <div
                        className={
                            "flex flex-col md:flex-row md:items-center md:space-x-5 space-y-3 md:space-y-0"
                        }
                    >
                        <button onClick={onClose} className={"cursor-pointer"}>
                            <NewCross width="1.5rem" height="1.5rem" />
                        </button>
                        <Title
                            className={`text-black font-semibold text-xl mb-0!`}
                        >
                            myBasket
                        </Title>
                    </div>
                </div>

                <div className="flex flex-1 flex-col px-4 py-6 bg-beige overflow-y-auto no-scrollbar">
                    {content}
                </div>

                <ConfirmBasketPanel />
            </div>
        </>
    );
}
