import { AxiosResponse } from "axios";
import { CompanyDTO } from "../types/dtos/company";
import Service from "./Service";

function get(companyId: string) {
    return Service.api.get<CompanyDTO>(`/v1/api/companies/${companyId}`);
}

function getAll() {
    return Service.api.get<CompanyDTO[]>("/v1/api/companies");
}

const exported = { get, getAll };

export default exported;
