import { OrderDTO } from "../types/dtos/order";
import { BasketStatus } from "../types/enums/basket-status";
import { OrderStatus } from "../types/enums/order-status";
import { OrderTimingType } from "../types/enums/order-timing-type";
import { PaymentMethod } from "../types/enums/payment-method";
import { ProductPriority } from "../types/enums/product-priority";
import { PromoType } from "../types/enums/promo-type";

export const initialOrder: OrderDTO = {
    addressDTO: {
        cityName: "",
        country: "",
        number: "",
        streetName: "",
        zipCode: "",
        creationDate: new Date().toISOString(),
        modificationDate: new Date().toISOString(),
        id: "",
    },
    deliveryAddressId: "",
    basketDTO: {
        basketStatus: BasketStatus.Unknown,
        employeeId: "",
        promoCodeId: "",
        totalPrice: 0,
        creationDate: new Date().toISOString(),
        modificationDate: new Date().toISOString(),
        id: "",
        basketProducts: [
            {
                basketId: "",
                productId: "",
                quantity: 0,
                id: "",
                indexPrice: 0,
                refundAmount: 0,
                totalAmount: 0,
                baseAmount: 0,
                creationDate: "0001-01-01",
                modificationDate: "0001-01-01",
                product: {
                    id: "",
                    creationDate: "0001-01-01",
                    modificationDate: "0001-01-01",
                    name: "",
                    unitPrice: 0,
                    isAvailable: false,
                    description: "",
                    pictureUrl: "",
                    promoDescription: "",
                    restaurantId: "",
                    priority: ProductPriority.Low,
                },
            },
        ],
    },
    basketProducts: [],
    basketId: "",
    comment: "",
    guidelines: "",
    deliveryDate: new Date(),
    promoCode: {
        amount: 0,
        companies: [],
        restaurants: [],
        companyRestaurantId: "",
        name: "",
        type: PromoType.Unknown,
        usage: 0,
        startDate: "",
        endDate: "",
        creationDate: new Date().toISOString(),
        modificationDate: new Date().toISOString(),
        id: "",
    },
    promoCodeId: undefined,
    creationDate: new Date().toISOString(),
    modificationDate: new Date().toISOString(),
    id: "",
    totalPrice: 0,
    status: OrderStatus.Open,
    sessionId: "",
    paymentMethodId: "",
    orderTimingType: OrderTimingType.Unknown,
    paymentMethod: PaymentMethod.Unknown,
    ticketId: "",
};

export interface OrderActionsReducer {
    type: "serverChanged" | "userChanged";
    target: string;
    payload: any;
}

export const OrderReduer = (state: OrderDTO, action: OrderActionsReducer) => {
    switch (action.type) {
        case "userChanged":
            return {
                ...state,
                [action.target]: action.payload,
            };

        case "serverChanged":
            return {
                ...state,
                ...action.payload,
            };

        default:
            break;
    }
};
