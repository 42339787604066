import { forwardRef, Fragment, Ref } from "react";
import { useEvent } from "../../../../contexts/event";

import TabBar from "../tab-bar";
import EventBanner from "./components/event-banner";
import { PageProps } from "./props";
import { ReactComponent as Foodiz } from "../event-tab-bar/assets/foodiz.svg";

import "./style.css";
import Paragraph from "../../../../components/paragraph";
import { useHistory, useParams } from "react-router-dom";
import { CompanyRouteParams } from "../../../../types/params/company-route";
import { useGetCompanyById } from "../../../../go-services/company/company";
import { formatAddress } from "../../../../utils/formatAddress";
import BasketPanel from "../../../../components/basket-panel";
import { useSafeArea } from "../../../../contexts/safe-area";
import useMedia from "../../../../hooks/useMedia";
import PublicTabBar from "../public-tab-bar";
import { Capacitor } from "@capacitor/core";
import { EventTypeEnum } from "../../../../types/enums/event-type";
import EventTabBar from "../event-tab-bar";
import Button from "../../../../components/button";
import {
    AuthRoute,
    FindCompanyRouteParams,
} from "../../../../types/params/find-company-route";
import { useTranslation } from "../../../../contexts/translation/TranslationContext";

const Page = forwardRef((props: PageProps, ref: Ref<HTMLDivElement>) => {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const { statusBarHeight } = useSafeArea();
    const {
        tabBarVisible = true,
        isBasketPanelVisible = true,
        eventBannerVisible = true,
        isPublic,
        className = "",
        children,
        onScroll,
        stickyContent,
        backgroundColor,
        ...rest
    } = props;

    // Queries

    const { event } = useEvent();
    const { companyId: paramCompanyId } = useParams<CompanyRouteParams>();

    const { data: company } = useGetCompanyById(paramCompanyId, {
        query: { enabled: !isPublic && !!paramCompanyId },
    });

    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Functions
    function handleOnClick(redirectRoute: AuthRoute) {
        history.push({
            pathname: `/find-company`,
            state: {
                redirectRoute,
            } as FindCompanyRouteParams,
        });
    }

    // Render
    return (
        <Fragment>
            {/* Mobile header */}
            <div
                className="md:hidden flex justify-between items-center h-16 bg-white px-3 border-b border-line"
                style={{
                    marginTop:
                        Capacitor.getPlatform() === "ios"
                            ? statusBarHeight
                            : undefined,
                }}
            >
                <div className="flex flex-col flex-1">
                    <Foodiz />
                </div>
                {!isPublic ? (
                    <div className="md:flex flex-1 flex-col justify-end items-end truncate">
                        <Paragraph className="font-semibold text-sm text-right truncate">
                            {company?.name ?? ""}
                        </Paragraph>

                        <Paragraph className="font-normal text-tag truncate text-right">
                            {formatAddress(company?.address)}
                        </Paragraph>
                    </div>
                ) : (
                    <div className="flex justify-end items-center space-x-4">
                        <div
                            onClick={() => {
                                handleOnClick(AuthRoute.SIGN_UP);
                            }}
                            className="cursor-pointer"
                        >
                            <Paragraph className="text-sign-in-button text-xs font-bold">
                                {translate("Sign-up")}
                            </Paragraph>
                        </div>
                        <Button
                            onClick={() => {
                                handleOnClick(AuthRoute.SIGN_IN);
                            }}
                            className="bg-sign-in-button text-white text-xs! font-bold rounded-lg h-8!"
                        >
                            {translate("Sign-in")}
                        </Button>
                    </div>
                )}
            </div>

            {isPublic &&
                !tabBarVisible &&
                !eventBannerVisible &&
                currentMediaQuery === 1 && (
                    <div className="relative">
                        <div>
                            <PublicTabBar />
                        </div>
                    </div>
                )}

            {tabBarVisible && (
                <div className="relative">
                    <div className="hidden md:block">
                        {event.id && event.type === EventTypeEnum.Public ? (
                            <EventTabBar />
                        ) : (
                            <TabBar />
                        )}
                    </div>

                    {stickyContent}

                    <BasketPanel
                        className={`hidden md:absolute md:flex right-0 mr-4 rounded-basket-panel-bottom! shadow-none mt-0! cursor-pointer md:max-w-md! md:min-w-custom! ${
                            isBasketPanelVisible ? "" : "md:hidden"
                        }`}
                    />
                </div>
            )}

            {eventBannerVisible && event.id && <EventBanner />}

            <main
                {...rest}
                className="flex flex-1 flex-col md:mb-0 pb-0 overflow-y-hidden bg-fifth"
                style={{ backgroundColor }}
            >
                <div
                    className={
                        "page-children flex flex-col flex-1 overflow-y-scroll scrolling-touch " +
                        className
                    }
                    onScroll={onScroll}
                    ref={ref}
                >
                    {children}
                </div>

                {tabBarVisible && (
                    <>
                        <div
                            className={`md:hidden ${
                                isBasketPanelVisible ? "flex" : "hidden"
                            }`}
                        >
                            <BasketPanel className="min-w-full" />
                        </div>
                        <div className="flex flex-row md:hidden">
                            {event.id && event.type === EventTypeEnum.Public ? (
                                <EventTabBar />
                            ) : (
                                <TabBar />
                            )}
                        </div>
                    </>
                )}
            </main>
        </Fragment>
    );
});

export default Page;
