import React from "react";
import { Route, Switch } from "react-router";
import LoadingOrder from "./modules/loading";
import OrderOverview from "./modules/overview";
import OrderSuccess from "./modules/success";

export default function OrderOverviewRouter() {
    //Attributes

    //Render
    return (
        <Switch>
            <Route
                path="/:companyId/order-overview/success"
                component={OrderSuccess}
            />

            <Route
                path="/:companyId/order-overview/:orderId/loading"
                component={LoadingOrder}
            />

            <Route
                exact
                path="/:companyId/order-overview"
                component={OrderOverview}
            />
        </Switch>
    );
}
