import { Capacitor } from "@capacitor/core";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../../../../../components/button";
import Input from "../../../../../../../../components/input";
import Title from "../../../../../../../../components/title";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import ForgotPasswordService from "../../../../../../../../services/ForgotEmailService";
import { ForgetPasswordDTO } from "../../../../../../../../types/dtos/forgetPassword";
import { CompanyRouteParams } from "../../../../../../../../types/params/company-route";
import * as Sentry from "@sentry/react";
import { errorMapper } from "../../../../../../../../mappers/message-errors";
import { ErrorType } from "../../../../../../../../types/enums/error-type";

function Email() {
    // Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const [email, setEmail] = useState("");
    const { companyId }: any = useParams<CompanyRouteParams>();
    const [disabled, setDisabled] = useState(false);
    const plat = Capacitor.getPlatform();
    const isMobile = plat === "ios" || plat === "android";
    const location = isMobile
        ? "https://app.foodiz.be"
        : window.location.origin;

    // Functions
    async function handleForgotClick() {
        setDisabled(true);
        const forgetPasswordDTO: ForgetPasswordDTO = {
            email,
            resetPasswordUrl: `${location}/reset-password/new-password`,
        };
        const response = await ForgotPasswordService.post(
            forgetPasswordDTO
        ).catch((error) => {
            Sentry.captureException(
                `An Error happened with the following email : ${email}`,
                error
            );
            errorMapper(ErrorType.RESET_PASSWORD, error.response.data);
            setDisabled(false);
            return;
        });

        if (response) {
            history.replace(`/${companyId}/forgot-password/confirmation`);
        }
        setDisabled(false);
    }

    function handleKeyDown(key: any) {
        if (key.key === "Enter" && email) {
            handleForgotClick();
        }
    }

    // Rendering
    return (
        <>
            <div className="mt-6">
                <Title className="text-3xl! leading-8 w-56">
                    WhatSYourEmail
                </Title>
            </div>

            <div className="mt-4">
                <div className="my-6">
                    <Input
                        placeholder={translate("email")}
                        type="email"
                        value={email}
                        label={translate("email")}
                        onChange={setEmail}
                        onKeyDown={(key) => handleKeyDown(key)}
                    />
                </div>
            </div>

            <div
                onClick={handleForgotClick}
                className="flex justify-start mt-12"
            >
                <Button disabled={disabled} className="min-w-full">
                    Confirm
                </Button>
            </div>
        </>
    );
}

export default Email;
