import { ForgetPasswordDTO } from "../types/dtos/forgetPassword";
import Service from "./Service";

async function post(forgetPassword: ForgetPasswordDTO) {
    return await Service.iam.post(`/v1/api/passwords`, forgetPassword);
}

const ForgotPasswordService = {
    post,
};

export default ForgotPasswordService;
