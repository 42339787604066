import { createContext, useContext } from "react";

export interface TranslationContextValue {
    language: string;
    translate: (key: string) => string;
    changeLanguage: (language: string) => void;
}

export const TranslationContext = createContext<TranslationContextValue>({
    language: "en",
    translate() {
        throw new Error("Could not find a provider for TranslationContext.");
    },
    changeLanguage() {
        throw new Error("Could not find a provider for TranslationContext.");
    },
});

export function useTranslation() {
    return useContext(TranslationContext);
}
