import React from "react";
import DishQuantityProps from "./props";

const DishQuantity = ({ quantity }: DishQuantityProps) => {
    return (
        <div
            style={{ minWidth: 40, color: "#222B45" }}
            className="bg-fourth rounded-lg text-center py-2 align-middle w-10 h-10 font-semibold"
        >
            {quantity}
        </div>
    );
};

export default DishQuantity;
