import { ProductResDTO, PublicRestaurantResDTO } from "../../../../new-types";
import { RestaurantSectionEntity } from "./types";

export function mapProductsToRestaurantSectionEntity(
    products: ProductResDTO[]
): RestaurantSectionEntity {
    return {
        restaurantId: products[0]?.restaurantId ?? "",
        products: products,
    };
}

export function mapPublicRestaurantToRestaurantSectionEntity(
    publicRestaurant: PublicRestaurantResDTO
): RestaurantSectionEntity {
    return {
        restaurantId: publicRestaurant?.id ?? "",
        publicRestaurant,
        products: publicRestaurant?.product ?? [],
    };
}

export function mapPublicRestaurantsToRestaurantSectionEntities(
    publicRestaurants: PublicRestaurantResDTO[]
): RestaurantSectionEntity[] {
    return publicRestaurants?.map(mapPublicRestaurantToRestaurantSectionEntity);
}
