import React from "react";
import { useHistory, useParams } from "react-router";
import Button from "../../../../../../components/button";
import Paragraph from "../../../../../../components/paragraph";
import Title from "../../../../../../components/title";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import Page from "../../../../components/page";
import { ReactComponent as PAYMENTCONFIRMED } from "./assets/payment-confirmed.svg";

export default function OrderSuccess() {
    //Attributes
    const { companyId } = useParams<CompanyRouteParams>();
    const history = useHistory();

    //Render
    return (
        <Page className="flex flex-col justify-between flex-1">
            <div className="flex flex-col items-center! flex-1 justify-center">
                <PAYMENTCONFIRMED />
                <Title className="mt-12">PaymentConfirmation</Title>
                <Paragraph>
                    Your order has been successfully sent to foodiz
                </Paragraph>
            </div>
            <Button
                className="mx-8 mb-8 md:w-64! md:self-center"
                onClick={() => history.push(`/${companyId}`)}
            >
                Back to homepage
            </Button>
        </Page>
    );
}
