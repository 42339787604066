import { ReactComponent as NightbornLogo } from "./assets/nightborn-logo.svg";

const NightbornBrand = () => {
    return (
        <div className="flex flex-col items-center space-y-1">
            <NightbornLogo />
            <div className="font-normal text-dark-blue text-sm">
                {"Designed & developed by Nightborn"}
            </div>
        </div>
    );
};

export default NightbornBrand;
