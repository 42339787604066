import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { RestaurantType } from "../../../../../../types/enums/restaurant-type";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import RestaurantCardProps from "./props";

function RestaurantCard(props: RestaurantCardProps) {
    // Attributes
    const { companyRestaurant, index } = props;
    const { companyId } = useParams<CompanyRouteParams>();
    const history = useHistory();

    //Functions
    function handleClick() {
        if (
            companyRestaurant.restaurant.restaurantType === RestaurantType.B2B
        ) {
            if (companyRestaurant.restaurant.paperformURL) {
                window.open(
                    companyRestaurant.restaurant.paperformURL as string,
                    "_blank"
                );
            }
        } else {
            history.push(
                `/${companyId}/restaurant/${companyRestaurant.restaurant.id}`
            );
        }
    }

    return (
        <div
            className={
                index === 0
                    ? "max-w-home-restaurant-card min-w-home-restaurant-card flex-none relative mt-5 md:ml-8 hover:animate-zoom focus:animate-dezoom cursor-pointer"
                    : "max-w-home-restaurant-card min-w-home-restaurant-card flex-none relative mt-5 hover:animate-zoom focus:animate-dezoom cursor-pointer"
            }
            onClick={handleClick}
        >
            <img
                className="object-fill w-full"
                src={companyRestaurant.restaurant.pictureUrl}
                style={{ height: 175, borderRadius: 8 }}
                onMouseOver={(e) =>
                    (e.currentTarget.src =
                        companyRestaurant.restaurant.hoverPictureUrl)
                }
                onMouseLeave={(e) =>
                    (e.currentTarget.src =
                        companyRestaurant.restaurant.pictureUrl)
                }
                data-cy={companyRestaurant.restaurant.name}
            />
        </div>
    );
}

export default RestaurantCard;
