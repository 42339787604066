import { useState } from "react";
import {
    useGetCompanyRestaurantsByCompanyId,
    useGetPublicCompanyRestaurants,
} from "../../../../go-services/company-restaurant/company-restaurant";
import { getRestaurantProducts } from "../../../../go-services/product/product";
import { getCompanyRestaurantProducts } from "../../../../go-services/product/product";
import { useGetPublicRestaurantsWithProducts } from "../../../../go-services/restaurant/restaurant";
import {
    CompanyRestaurantResDTO,
    PublicRestaurantPagingResDTO,
    PublicRestaurantResDTO,
    RestaurantType,
} from "../../../../new-types";
import {
    mapProductsToRestaurantSectionEntity,
    mapPublicRestaurantsToRestaurantSectionEntities,
} from "./mappers";
import {
    IHomeRestaurantsLogicInput,
    IHomeRestaurantsLogicOutput,
    RestaurantSectionEntity,
} from "./types";

const useHomeRestaurantsLogic = ({
    isPublic,
    company,
}: IHomeRestaurantsLogicInput): IHomeRestaurantsLogicOutput => {
    // Attributes
    const [isProductsLoading, setProductsLoading] = useState<boolean>(true);
    const [restaurantSectionEntities, setRestaurantSectionEntities] = useState<
        RestaurantSectionEntity[]
    >([]);
    const [companyRestaurants, setCompanyRestaurants] = useState<
        CompanyRestaurantResDTO[]
    >([]);

    // Queries: isPublic = false
    const { isLoading: isCompanyRestaurantsLoading } =
        useGetCompanyRestaurantsByCompanyId(
            company?.id ?? "",
            { isAssigned: true },
            {
                query: {
                    enabled: !isPublic && !!company?.id,
                    onSuccess: (data: CompanyRestaurantResDTO[]) => {
                        const restaurantsB2C = filterRestaurantsB2C(data);
                        setCompanyRestaurants(restaurantsB2C);
                        void fetchRestaurantProducts(restaurantsB2C);
                    },
                },
            }
        );

    // Queries: isPublic = true
    const { isLoading: isPublicCompanyRestaurantsLoading } =
        useGetPublicCompanyRestaurants(undefined, {
            query: {
                enabled: isPublic,
                onSuccess: (data: CompanyRestaurantResDTO[]) => {
                    const restaurantsB2C = filterRestaurantsB2C(data);
                    setCompanyRestaurants(restaurantsB2C);
                },
            },
        });

    const { isLoading: isPublicRestaurantProductsLoading } =
        useGetPublicRestaurantsWithProducts(undefined, {
            query: {
                enabled: isPublic,
                onSuccess: (resp: PublicRestaurantPagingResDTO) => {
                    const filteredPublicRestaurant = filterRestaurantsB2C(
                        resp?.data ?? []
                    );
                    setRestaurantSectionEntities(
                        mapPublicRestaurantsToRestaurantSectionEntities(
                            filteredPublicRestaurant as PublicRestaurantResDTO[]
                        )
                    );
                },
            },
        });

    // Functions
    async function fetchRestaurantProducts(
        companyRestaurants: CompanyRestaurantResDTO[]
    ) {
        setProductsLoading(true);

        try {
            const restaurantSections = await Promise.all(
                companyRestaurants?.map(async (company) => {
                    // GetRestaurantProducts Query
                    const productsPaging = await getCompanyRestaurantProducts(
                        company.companyId as string,
                        company.restaurantId as string,
                        { isAvailable: true }
                    );

                    return mapProductsToRestaurantSectionEntity(
                        productsPaging?.data ?? []
                    );
                }) ?? []
            );

            setRestaurantSectionEntities(restaurantSections);
        } catch (error) {
            console.warn(error);
        } finally {
            setProductsLoading(false);
        }
    }

    function filterRestaurantsB2C(
        data: (CompanyRestaurantResDTO | PublicRestaurantResDTO)[]
    ) {
        return data?.filter(
            (c) => c?.restaurantType !== RestaurantType[RestaurantType.B2B]
        );
    }

    // Return
    return {
        companyRestaurants,
        isCompanyRestaurantsLoading: isPublic
            ? isPublicCompanyRestaurantsLoading
            : isCompanyRestaurantsLoading,
        isProductsLoading: isPublic
            ? isPublicRestaurantProductsLoading
            : isProductsLoading,
        restaurantSectionEntities,
    };
};

export default useHomeRestaurantsLogic;
