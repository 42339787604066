import React, { createContext, useContext, useReducer } from "react";
import {
    BasketActionReducer,
    basketReducer,
    initialBasket,
} from "../../reducers/basketReducer";
import { BasketDTO } from "../../types/dtos/basket";

interface BasketContextInterface {
    basket: BasketDTO;
    dispatch: (action: any) => void;
}

const BasketContext = createContext<BasketContextInterface>({
    basket: initialBasket,
    dispatch: function (action: BasketActionReducer) {},
});

export function useBasket() {
    return useContext(BasketContext);
}

const BasketContextProvider = ({ children }: any) => {
    //Attributes
    const BasketProvider = BasketContext.Provider;
    const [basket, dispatch] = useReducer(basketReducer, initialBasket);

    return (
        <BasketProvider value={{ basket, dispatch }}>{children}</BasketProvider>
    );
};

export default BasketContextProvider;
