import { Capacitor } from "@capacitor/core";
import { forwardRef, Ref, useEffect, useRef } from "react";
import BasketPanel from "../../../../../../components/basket-panel";
import StickyHeaderMenuItem from "./menu-item";
import { StickyRestaurantsHeaderProps } from "./props";

const StickyRestaurantsHeader = forwardRef(
    (
        {
            companyRestaurants,
            onRestaurantPress,
            activeRestaurantId,
            disableAutoScroll,
            isPublic,
        }: StickyRestaurantsHeaderProps,
        ref: Ref<HTMLDivElement>
    ) => {
        // Attributes
        const menuItems = useRef<HTMLDivElement[]>([]);

        // Effects
        useEffect(() => {
            if (activeRestaurantId && !disableAutoScroll) {
                const selectedTab = menuItems.current.find(
                    (tab) => tab.id === activeRestaurantId
                );

                selectedTab?.scrollIntoView({
                    behavior:
                        // Behavior "smooth" for horizontal scroll doesn't work on android
                        // or popular web browsers such as chrome (works fine on safari tho)
                        Capacitor.getPlatform() === "android" ||
                        Capacitor.getPlatform() === "web"
                            ? "auto"
                            : "smooth",
                    block: "nearest",
                    inline: "center",
                });
            }
        }, [activeRestaurantId]);

        //Render
        return (
            <div
                ref={ref}
                className="sticky top-0  min-h-14! h-14! z-40! flex-col bg-white -mt-2!"
            >
                <div className="sticky mt-0! shadow-lg w-full min-h-14! h-14! flex md:justify-center items-center md:overflow-x-hidden  overflow-x-scroll overflow-y-hidden  px-3 md:px-0 z-40! no-scrollbar">
                    {companyRestaurants?.map((companyRestaurant, index) => (
                        <StickyHeaderMenuItem
                            activeRestaurantId={activeRestaurantId}
                            key={companyRestaurant?.restaurantId}
                            restaurantId={companyRestaurant?.restaurantId}
                            companyRestaurant={companyRestaurants[index]}
                            onMenuItemClick={() =>
                                onRestaurantPress(
                                    companyRestaurant?.restaurantId ?? ""
                                )
                            }
                            ref={(element) => {
                                if (element) menuItems.current.push(element);
                            }}
                        />
                    ))}
                </div>
                <div className="flex w-full justify-end">
                    {!isPublic && (
                        <BasketPanel
                            className={`hidden md:flex mr-4 mt-0! rounded-basket-panel-bottom! cursor-pointer md:max-w-md! md:min-w-custom! z-30!`}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default StickyRestaurantsHeader;
