import { Fragment, useMemo } from "react";
import Lottie from "../lottie";

import { BusyIndicatorProps } from "./props";
// style
import "./style.css";

const DEFAULT_OPTIONS = {
    loop: true,
    autoplay: true,
    animationData: require("./assets/lottie-loading.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function BusyIndicator({
    children,
    isLoading,
    ...rest
}: BusyIndicatorProps) {
    const queries = useMemo(() => {
        return "query" in rest ? [rest.query] : rest.queries;
    }, [rest]);

    if (
        isLoading ||
        queries.some((q: { status: string }) => q.status === "loading")
    ) {
        return (
            <div className="busy-indicator-div-container my-auto">
                <div className="busy-indicator-div">
                    <Lottie
                        isClickToPauseDisabled={true}
                        options={DEFAULT_OPTIONS}
                        height={400}
                        width={400}
                    />
                </div>
            </div>
        );
    }

    if ("query" in rest && rest.query.status !== "success") {
        return <div>{rest.query.status}</div>;
    }

    return <Fragment>{children}</Fragment>;
}
