import React, { useState } from "react";
import { PromoCodeSidePanelOverlay } from "../../overlay-context";
import { ReactComponent as Close } from "../../assets/cross.svg";
import TabBar from "../../../../modules/company/components/tab-bar";
import { useEvent } from "../../../event";
import { EventTypeEnum } from "../../../../types/enums/event-type";
import EventTabBar from "../../../../modules/company/components/event-tab-bar";
import { Capacitor } from "@capacitor/core";

const plat = Capacitor.platform;

export default function PromoCodeSidePanel({
    onClose,
    content,
}: PromoCodeSidePanelOverlay) {
    //Attributes
    const { event } = useEvent();

    //Render
    return (
        <>
            <div
                role="dialog"
                aria-labelledby="side-panel-title"
                className="z-10 no-scrollbar flex flex-col flex-grow bg-fifth overflow-y-auto md:min-w-side-panel md:m-8 md:ml-auto md:rounded-3xl md:w-1/4 md:max-w-side-panel md:max-h-product-overlay-panel"
            >
                <div
                    style={{ paddingTop: plat === "ios" ? 40 : 15 }}
                    className="md:shadow-sm md:bg-white"
                >
                    <div className="flex justify-end cursor-pointer mr-4 ">
                        <button
                            className="flex justify-center w-6 h-6"
                            onClick={onClose}
                        >
                            <Close className="my-auto" />
                        </button>
                    </div>

                    <h1
                        id="side-panel-title"
                        style={{ marginTop: plat === "ios" ? 0 : 16 }}
                        className="text-2xl font-semibold md:mb-10 ml-6 text-custom-green"
                    >
                        Add Promo code
                    </h1>
                </div>

                <div className="flex flex-1 flex-col p-6 bg-fifth overflow-y-auto">
                    {content}
                </div>

                <div className="hidden">
                    {event.id && event.type === EventTypeEnum.Public ? (
                        <EventTabBar />
                    ) : (
                        <TabBar />
                    )}
                </div>
            </div>
        </>
    );
}
