import React from "react";
import { CardPictureTitleProps } from "./props";

const CardPictureTitle = ({ children }: CardPictureTitleProps) => {
    return (
        <div className="text-secondary font-semibold font-poppins text-base leading-12">
            {children}
        </div>
    );
};

export default CardPictureTitle;
