import Button from "../../../../components/button";
import { SidePanelOverlay } from "../../overlay-context";
import { ReactComponent as NewClose } from "../../assets/new-cross.svg";
import { useTranslation } from "../../../translation/TranslationContext";
import { useSafeArea } from "../../../safe-area";

export default function SidePanel({
    title,
    buttonText,
    onButtonClick,
    onClose,
    content,
    onlyContent,
    isRounded,
    isSafeAreaActive = true,
}: SidePanelOverlay) {
    const { statusBarHeight } = useSafeArea();

    const { translate } = useTranslation();

    //Render
    return (
        <div
            role="dialog"
            aria-labelledby="side-panel-title"
            className={`z-10 flex flex-1 flex-grow bg-white md:m-auto md:rounded-20px md:w-1/4 md:min-w-side-panel md:max-w-side-panel md:max-h-product-overlay-panel md:h-product-overlay-panel md:my-15! overflow-hidden no-scrollbar bottom-0 absolute md:relative h-full w-full`}
            style={{
                paddingTop: statusBarHeight ? -statusBarHeight : undefined,
                borderRadius: isRounded ? 20 : undefined,
            }}
        >
            <div
                className={`${
                    onlyContent ? "hidden" : "flex"
                } h-min flex-col p-6 shadow-sm bg-white`}
            >
                <div onClick={onClose} className="cursor-pointer bg-white">
                    <Button back />
                </div>

                <h1
                    id="side-panel-title"
                    className="text-2xl font-semibold mt-4"
                >
                    {title}
                </h1>
            </div>

            <div
                style={{ borderRadius: isRounded ? 20 : 0 }}
                className="flex relative flex-1 flex-col bg-white"
            >
                <div className={`${onlyContent ? "flex" : "hidden"} w-full`}>
                    <div
                        className={`cursor-pointer flex flex-col w-full bg-white`}
                    >
                        <div
                            onClick={onClose}
                            className={`absolute left-0 top-0 md:m-5 m-4`}
                            style={{
                                marginTop: isSafeAreaActive
                                    ? statusBarHeight
                                    : undefined,
                            }}
                        >
                            <NewClose />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        borderRadius: isRounded ? 20 : 0,
                    }}
                    className="flex flex-col flex-1 no-scrollbar overflow-y-auto"
                >
                    {content}
                </div>
            </div>

            {onlyContent && (
                <div
                    className={`m-6 ${onlyContent ? "hidden" : "flex"}`}
                    onClick={onButtonClick}
                >
                    <Button className="w-full bg-green-dark">
                        <span className="text-lg">
                            {buttonText
                                ? translate(buttonText)
                                : translate("confirm")}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}
