import { useEffect } from "react";
import { useHistory } from "react-router";
import NightbornBrand from "../../components/branding";
import Lottie from "../../components/lottie";
import { useProfile } from "../../contexts/profile";
import useQueryString from "../../hooks/useQueryString";
import ProfileService from "../../services/ProfileService";
import * as Sentry from "@sentry/react";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./assets/loading-foodiz.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function Loading() {
    // Attributes
    const history = useHistory();
    const { dispatch } = useProfile();
    const provider = useQueryString().get("provider");
    const code = useQueryString().get("code");
    const redirect = useQueryString().get("redirect");
    const companyName = window.localStorage.getItem("COMPANY_NAME") ?? "";

    // Effects
    useEffect(() => {
        const t = setTimeout(() => {
            refreshUser();
        }, 2500);

        return () => clearTimeout(t);
    }, []);

    // Functions
    async function refreshUser() {
        if (
            redirect?.includes(`/sign`) ||
            redirect?.includes(`/reset-password/new-password`)
        ) {
            history.replace(redirect);
            return;
        }

        try {
            const profile = await ProfileService.get();
            if (profile) {
                dispatch({
                    type: "serverChanged",
                    payload: profile,
                    target: "_self",
                });
            }
        } catch (error: any) {
            console.warn("User wasn't authenticated.");
            Sentry.captureException(error, {
                extra: {
                    "Provider Query String": provider,
                    "Authorization Code": code,
                    "Redirect URL": redirect,
                },
                tags: { errorType: "Authentication" },
            });
            history.replace("/home", { isPublic: true });
            return;
        }
        // If the current URL path is not '/loading', return and do nothing
        if (window.location.pathname !== "/loading") return;

        // If the 'redirect' variable is truthy and not equal to '/', replace the current page with the value of 'redirect' in the history
        try {
            if (redirect && redirect !== "/") {
                // if there is a company name in the localStorage and the redirect didn't include the company name
                if (companyName && !redirect.includes(companyName)) {
                    history.replace(`/${companyName}${redirect}`);
                } else {
                    history.replace(redirect);
                }
            }
            // If the 'code' variable is not null, store its value in local storage and replace the current page with '/{companyName}/order-overview' in the history
            else if (code !== null) {
                window.localStorage.setItem("auth-code", code);
                window.localStorage.setItem("provider", provider ?? "");
                history.replace(`/${companyName}/order-overview`);
            }
            // If none of the above conditions are met, check for a value in local storage for the key 'COMPANY_NAME'
            // If it exists, push '/{company}' onto the history
            // If it does not exist, replace the current page with '/home' in the history
            else {
                if (companyName) {
                    history.push(`/${companyName}`);
                } else {
                    history.replace("/home");
                }
            }
        } catch {
            Sentry.captureException({
                extra: {
                    "Provider Query String": provider,
                    "Authorization Code": code,
                    "Redirect URL": redirect,
                },
                tags: { errorType: "Redirection" },
            });
        }
    }

    return (
        <div className="flex flex-1 flex-col items-center">
            <div className="flex m-auto">
                <Lottie
                    isClickToPauseDisabled={true}
                    options={defaultOptions}
                    height={400}
                    width={400}
                />
            </div>
            <div className="mb-6">
                <NightbornBrand />
            </div>
        </div>
    );
}
