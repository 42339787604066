import { useOverlay } from "../../../../../../../../contexts/overlay/overlay-context";
import { useTranslation } from "../../../../../../../../contexts/translation/TranslationContext";
import { ReactComponent as NewClose } from "./assets/new-cross.svg";

const CrossSellingHeader = () => {
    //Attributes
    const { removeCurrentOverlay } = useOverlay();
    const { translate } = useTranslation();

    //Render
    return (
        <div
            className={`cursor-pointer flex flex-col w-full bg-white py-6 px-4 space-y-5`}
        >
            <div onClick={() => removeCurrentOverlay()}>
                <NewClose />
            </div>

            <text className=" justify-center font-poppins font-semibold text-xl text-dark-blue">
                {translate("cross_selling.overlay.header")}
            </text>
        </div>
    );
};
export default CrossSellingHeader;
