import { useParams } from "react-router-dom";
import { useProfile } from "../../contexts/profile";
import {
    useCreateBasketProduct,
    useGetBasketProducts,
    useUpdateBasketProduct,
} from "../../go-services/basket-product/basket-product";

import { useOneBasketQuery } from "../../queries/basket";
import { useOneCompanyQuery } from "../../queries/company";
import { CompanyRouteParams } from "../../types/params/company-route";

interface AddProductToBasketParams {
    productId: string;
    quantity: number;
    onSuccess?: () => void | Promise<void>;
}

function useBasketLogic() {
    // Attributes
    const { user } = useProfile();

    // Queries
    const { mutateAsync: createBasketProduct } = useCreateBasketProduct();
    const {
        mutateAsync: updateBasketProduct,
        isLoading: isUpdateBasketLoading,
    } = useUpdateBasketProduct();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const { data: companyQuery } = useOneCompanyQuery(companyName);
    const companyId = companyQuery?.id;
    const {
        data: currentBasket,
        refetch,
        isLoading: isBasketLoading,
    } = useOneBasketQuery(companyId!, user.employeeId);
    const { data: basketProducts } = useGetBasketProducts(
        user?.employeeId,
        currentBasket?.id ?? "",
        { query: { enabled: !!user?.employeeId && !!currentBasket?.id } }
    );

    // Functions
    function refetchCurrentBasket() {
        refetch();
    }

    function addProductToBasket({
        productId,
        quantity,
        onSuccess,
    }: AddProductToBasketParams) {
        if (quantity <= 0) return;

        const basketProduct = basketProducts?.find(
            (basketProduct) => basketProduct?.product?.id === productId
        );

        if (basketProduct) {
            // Update Quantity of product in basket
            updateBasketProduct(
                {
                    basketId: currentBasket?.id ?? "",
                    employeeId: user?.employeeId ?? "",
                    productId,
                    data: {
                        quantity: (basketProduct?.quantity ?? 0) + quantity,
                    },
                },
                {
                    onSuccess,
                }
            );
            return;
        }

        // Else create new basket product
        createBasketProduct(
            {
                basketId: currentBasket?.id ?? "",
                employeeId: user?.employeeId ?? "",
                data: {
                    productId,
                    quantity,
                },
            },
            {
                onSuccess,
            }
        );
    }

    return {
        addProductToBasket,
        isUpdateBasketLoading,
        currentBasket,
        refetchCurrentBasket,
        isBasketLoading,
    };
}

export default useBasketLogic;
