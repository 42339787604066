import { useHistory, useParams } from "react-router-dom";
import NightbornBrand from "../../../../../../components/branding";
import Button from "../../../../../../components/button";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import useMedia from "../../../../../../hooks/useMedia";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { ReactComponent as FoodizLogo } from "../../assets/foodiz.svg";

const spanStyle = "text-white inline-flex font-semibold";

export default function Auth() {
    //Attributes
    const history = useHistory();
    const { translate } = useTranslation();
    const { companyId } = useParams<CompanyRouteParams>();

    //UseMedia
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Render
    return (
        <div
            className={
                "bg-mobile-sign-background sm:bg-sign-background bg-cover flex flex-1 flex-col items-center justify-center overflow-hidden"
            }
        >
            <div className="bg-white rounded-2xl flex flex-1 md:max-h-auth-panel max-h-mobile-auth-modals md:my-16 flex-col pt-0 overflow-x-hidden md:px-20 px-4 pb md:pb-6 relative overflow-hidden">
                {/* IMAGE */}
                {currentMediaQuery === 1 && (
                    <div
                        style={{
                            width: "14.5625rem",
                            height: "10.3125rem",
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    >
                        <img src="/assets/grattin.png" />
                    </div>
                )}
                {currentMediaQuery === 1 && (
                    <div
                        style={{
                            width: "8.125rem",
                            height: "9.0625rem",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <img src="/assets/toffu-v2.png" />
                    </div>
                )}
                <div className="md:mt-48 my-auto z-50">
                    {/* LOGO */}
                    <div className="md:mt-0 mt-16 flex justify-center">
                        <FoodizLogo width={140} height={83} />
                    </div>
                    {/* BUTTON */}
                    <div className="flex flex-col space-y-2 mt-12">
                        <Button
                            className="w-full bg-add-button"
                            onClick={() =>
                                history.push(`/${companyId}/sign-up`)
                            }
                        >
                            <p className="text-white">
                                {translate("First time?")} &nbsp;
                                <span className={spanStyle}>
                                    {translate("Sign-up")}
                                </span>
                            </p>
                        </Button>
                        <Button
                            className="w-full bg-add-button"
                            onClick={() =>
                                history.push(`/${companyId}/sign-in`)
                            }
                        >
                            <p className="text-white">
                                {translate("Already have an account?")} &nbsp;
                                <span className={spanStyle}>
                                    {translate("Sign-in")}
                                </span>
                            </p>
                        </Button>
                    </div>
                </div>
                <div className="mb-6 md:mb-0">
                    <NightbornBrand />
                </div>
            </div>
        </div>
    );
}
