import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Button from "../../../../../../components/button";
import Title from "../../../../../../components/title";
import { useOrder } from "../../../../../../contexts/order";
import { useOverlay } from "../../../../../../contexts/overlay/overlay-context";
import { useProfile } from "../../../../../../contexts/profile";
import useBasketLogic from "../../../../../../hooks/useBasketLogic";
import useMedia from "../../../../../../hooks/useMedia";
import { useOneCompanyQuery } from "../../../../../../queries/company";
import { useCurrentOrderByBasketQuery } from "../../../../../../queries/order";
import { useOnePromoCodeQuery } from "../../../../../../queries/promocode";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import Page from "../../../../components/page";
import DeliveryAddressCard from "../../components/delivery-address-card";
import OrderBreakdownCard from "../../components/order-breakdown-card";
import OrderTiming from "../../components/order-timing";
import PaymentMethodCard from "../../components/payment-method-card";
import PromoCodeCard from "../../components/promo-code-card";
import { useAnalytics } from "@segment/analytics-react";

export default function OrderOverview() {
    //Attributes
    const history = useHistory();
    const { user } = useProfile();
    const { removeCurrentOverlay } = useOverlay();
    const { companyId: companyName } = useParams<CompanyRouteParams>();
    const companyQuery = useOneCompanyQuery(companyName);
    const company = companyQuery.data;
    const companyId = company?.id;
    const { track } = useAnalytics();

    // New Queries
    const { currentBasket, refetchCurrentBasket, isBasketLoading } =
        useBasketLogic();

    // Old Query
    const promoCodeQuery = useOnePromoCodeQuery(
        currentBasket?.promoCodeId ?? ""
    );
    const orderQuery = useCurrentOrderByBasketQuery(
        companyId!,
        user.employeeId,
        currentBasket?.id ?? ""
    );
    const { dispatch } = useOrder();

    history.listen(() => {
        removeCurrentOverlay();
    });

    //Media Query
    const currentMediaQuery = useMedia(
        // Media queries
        ["(min-width: 600px)", "(max-width: 599px)"],
        // Column counts (relates to above media queries by array index)
        [1, 2],
        // Default column count
        1
    );

    //Effects
    useEffect(() => {
        dispatch({
            payload: orderQuery.data,
            target: "self",
            type: "serverChanged",
        });
    }, [orderQuery.data]);

    useEffect(() => {
        if (
            !!window.localStorage.getItem("auth-code") &&
            !!window.localStorage.getItem("provider")
        ) {
            track("Init voucher payment", {
                email: user.email,
                firstname: user.firstName,
                lastname: user.lastName,
                provider: window.localStorage.getItem("provider"),
            });
        }
    }, []);

    //Render
    return (
        <BusyIndicator queries={[orderQuery, companyQuery]}>
            <Page
                isBasketPanelVisible={false}
                tabBarVisible={currentMediaQuery === 1 ? true : false}
                className="px-4 pt-4 md:mb-0 md:mx-auto"
            >
                <div className={`md:hidden flex w-full items-center mb-4`}>
                    <Button className="mr-4" onClick={history.goBack} back />
                    <Title className="mb-0!">orderOverview</Title>
                </div>

                <div style={{ maxWidth: "780px" }} className="md:flex ">
                    <div className="md:flex-1">
                        <DeliveryAddressCard />

                        <PaymentMethodCard />

                        <OrderTiming />

                        <PromoCodeCard
                            promoCode={promoCodeQuery.data}
                            currentBasket={currentBasket}
                        />
                    </div>

                    <div className="md:flex-1 md:ml-8">
                        <OrderBreakdownCard
                            currency="EUR"
                            currentBasket={currentBasket}
                            refetchCurrentBasket={refetchCurrentBasket}
                            isBasketLoading={isBasketLoading}
                        />
                    </div>
                </div>
            </Page>
        </BusyIndicator>
    );
}
