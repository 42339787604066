import { format } from "date-fns";
import React from "react";
import CardPictureTitle from "../../../../../../components/card-picture-title";
import { useTranslation } from "../../../../../../contexts/translation/TranslationContext";
import { OrderStatus } from "../../../../../../types/enums/order-status";
import { OrderDescriptionProps } from "./props";

const OrderDescription = ({ order }: OrderDescriptionProps) => {
    // Attributes
    const { translate } = useTranslation();
    const dateElements = order.modificationDate
        .split(/-|T/)
        .map((el: any) => parseInt(el));
    const year = dateElements[0];
    const month = dateElements[1];
    const day = dateElements[2];

    return (
        <>
            <CardPictureTitle>
                {`${dateElements[2]} ${format(
                    new Date(year, month - 1, day),
                    "MMMM"
                )} ${year}`}
            </CardPictureTitle>
            <div className="flex flex-col text-third text-xs leading-11 mt-1 justify-center">
                <div>{`${
                    order.basketDTO.basketProducts &&
                    order.basketDTO.basketProducts.length
                } ${translate("items")} - ${order.totalPrice.toFixed(
                    2
                )}€`}</div>
                {/* <div className={`mt-1 ${order.status === OrderStatus.Open ? "text-custom-green font-bold" : ""}`}>{OrderStatus[order.status]}</div> */}
            </div>
        </>
    );
};

export default OrderDescription;
