import React, { useState } from "react";
import BasketSidePanel from "./components/basket-side-panel";
import CommentSidePanel from "./components/comment-side-panel";
import OrderSidePanel from "./components/order-side-panel";
import PromoCodeSidePanel from "./components/promo-code-side-panel";
import SidePanel from "./components/side-panel";
import EditProductPanel from "./components/edit-product-panel";
import ContactSidePanel from "./components/contact-side-panel";
import { HTMLMotionProps, motion } from "framer-motion";
import {
    CommentSidePanelOverlay,
    PromoCodeSidePanelOverlay,
    OrderSidePanelOverlay,
    BasketSidePanelOverlay,
    InternalOverlay,
    ModalOverlay,
    OverlayContext,
    OverlayContextValue,
    SidePanelOverlay,
    EditProductPanelOverlay,
    ContactSidePanelOverlay,
    BasicPanelOverlay,
    OverlayType,
    Overlay,
} from "./overlay-context";
import BasicPanel from "./components/basic-panel";
interface OverlayProviderState {
    items: OverlayContextValue["items"];
}

interface OverlayProviderProps {
    children: any;
}

const OverlayProvider = (props: OverlayProviderProps) => {
    const [items, setItems] = useState<Overlay[]>([]);

    // Effects
    // useEffect(() => {
    //     return () => {
    //         document.removeEventListener("keydown", removeCurrentOverlay);
    //     };
    // });

    /**
     * Adds or removes event listeners based on the current number of overlays.
     */
    function maybeUpdateEventListeners() {
        // if (items.length === 0) {
        //     document.removeEventListener("keydown", removeCurrentOverlay);
        // } else if (items.length === 1) {
        //     document.addEventListener("keydown", removeCurrentOverlay);
        // }
    }

    function getNextId() {
        return items.length;
    }

    /**
     * Adds an overlay to the state and potentially updates the event listeners.
     * @param overlay The overlay to show.
     */
    function showOverlay<
        TOverlay extends OverlayProviderState["items"][number]
    >(overlay: TOverlay) {
        setItems((previous) => {
            return [...previous, overlay];
        });

        maybeUpdateEventListeners();
    }

    /**
     * Removes the last overlay in the list.
     */
    function removeCurrentOverlay() {
        if (items.length === 0) {
            return;
        }

        remove(items[items.length - 1].id);
    }

    /**
     * Shows a modal overlay.
     * @param modal The modal to show.
     */
    function showModal(modal: InternalOverlay<ModalOverlay>) {
        const id = getNextId();

        const complete: ModalOverlay = {
            ...modal,
            id,
            type: OverlayType.MODAL,
            onClose: () => {
                remove(id);
                modal.onClose?.();
            },
        };

        showOverlay(complete);
    }

    /**
     * Shows a side panel overlay.
     * @param sidePanel The side panel to show.
     */
    function showSidePanel(sidePanel: InternalOverlay<SidePanelOverlay>) {
        const id = getNextId();

        const complete: SidePanelOverlay = {
            ...sidePanel,
            id,
            type: OverlayType.SIDE_PANEL,
            onClose: () => {
                remove(id);
                sidePanel.onClose?.();
            },
            onButtonClick: () => {
                remove(complete.id);
                sidePanel.onButtonClick?.();
            },
        };

        showOverlay(complete);
    }

    function showEditProductPanel(
        editProductPanel: InternalOverlay<SidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: EditProductPanelOverlay = {
            ...editProductPanel,
            id,
            type: OverlayType.EDIT_PRODUCT_PANEL,
            onClose: () => {
                remove(id);
                editProductPanel.onClose?.();
            },
            onButtonClick: () => {
                remove(complete.id);
                editProductPanel.onButtonClick?.();
            },
        };

        showOverlay(complete);
    }

    function showBasketSidePanel(
        basketSidePanel: InternalOverlay<BasketSidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: BasketSidePanelOverlay = {
            ...basketSidePanel,
            id,
            type: OverlayType.BASKET_SIDE_PANEL,
            onClose: () => {
                remove(id);
                basketSidePanel.onClose?.();
            },
            onButtonClick: () => {
                remove(complete.id);
                basketSidePanel.onButtonClick?.();
            },
        };

        showOverlay(complete);
    }

    function showOrderSidePanel(
        orderSidePanel: InternalOverlay<OrderSidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: OrderSidePanelOverlay = {
            ...orderSidePanel,
            id,
            type: OverlayType.ORDER_SIDE_PANEL,
            onClose: () => {
                remove(id);
                orderSidePanel.onClose?.();
            },
        };

        showOverlay(complete);
    }

    function showPromoCodeSidePanel(
        promoCodeSidePanel: InternalOverlay<PromoCodeSidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: PromoCodeSidePanelOverlay = {
            ...promoCodeSidePanel,
            id,
            type: OverlayType.PROMO_CODE_SIDE_PANEL,
            onClose: () => {
                remove(id);
                promoCodeSidePanel.onClose?.();
            },
        };

        showOverlay(complete);
    }

    function showCommentSidePanel(
        commentSidePanel: InternalOverlay<CommentSidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: CommentSidePanelOverlay = {
            ...commentSidePanel,
            id,
            type: OverlayType.COMMENT_SIDE_PANEL,
            onClose: () => {
                remove(id);
                commentSidePanel.onClose?.();
            },
        };

        showOverlay(complete);
    }

    function showBasicPanel(basicPanel: InternalOverlay<BasicPanelOverlay>) {
        const id = getNextId();

        const complete: BasicPanelOverlay = {
            ...basicPanel,
            id,
            type: OverlayType.BASIC_PANEL,
            onClose: () => {
                remove(id);
                basicPanel.onClose?.();
            },
        };

        showOverlay(complete);
    }

    function showContactSidePanel(
        contactSidePanel: InternalOverlay<ContactSidePanelOverlay>
    ) {
        const id = getNextId();

        const complete: CommentSidePanelOverlay = {
            ...contactSidePanel,
            id,
            type: OverlayType.CONTACT_SIDE_PANEL,
            onClose: () => {
                remove(id);
                contactSidePanel.onClose?.();
            },
        };

        showOverlay(complete);
    }

    /**
     * Removes an overlay with the provided id.
     * @param id The id to remove.
     */
    function remove(id: number) {
        setItems((previous) => {
            return previous.filter((i) => i.id !== id);
        });

        maybeUpdateEventListeners();
    }

    const value: OverlayContextValue = {
        items,
        showModal: showModal,
        showSidePanel: showSidePanel,
        showEditProductPanel: showEditProductPanel,
        showBasketSidePanel: showBasketSidePanel,
        showOrderSidePanel: showOrderSidePanel,
        showPromoCodeSidePanel: showPromoCodeSidePanel,
        showCommentSidePanel: showCommentSidePanel,
        showContactSidePanel: showContactSidePanel,
        showBasicPanel: showBasicPanel,
        removeCurrentOverlay: removeCurrentOverlay,
    };

    const renderPanel = (overlay: Overlay) => {
        switch (overlay?.type) {
            case OverlayType.SIDE_PANEL:
                return <SidePanel {...(overlay as SidePanelOverlay)} />;
            case OverlayType.ORDER_SIDE_PANEL:
                return (
                    <OrderSidePanel {...(overlay as OrderSidePanelOverlay)} />
                );
            case OverlayType.PROMO_CODE_SIDE_PANEL:
                return (
                    <PromoCodeSidePanel
                        {...(overlay as PromoCodeSidePanelOverlay)}
                    />
                );
            case OverlayType.COMMENT_SIDE_PANEL:
                return (
                    <CommentSidePanel
                        {...(overlay as CommentSidePanelOverlay)}
                    />
                );
            case OverlayType.EDIT_PRODUCT_PANEL:
                return (
                    <EditProductPanel
                        {...(overlay as EditProductPanelOverlay)}
                    />
                );
            case OverlayType.CONTACT_SIDE_PANEL:
                return (
                    <ContactSidePanel
                        {...(overlay as ContactSidePanelOverlay)}
                    />
                );
            case OverlayType.BASKET_SIDE_PANEL:
                return (
                    <BasketSidePanel {...(overlay as BasketSidePanelOverlay)} />
                );
            case OverlayType.BASIC_PANEL:
            default:
                return <BasicPanel {...(overlay as BasicPanelOverlay)} />;
        }
    };

    const getAnimationProps = (overlay: Overlay): HTMLMotionProps<"div"> => {
        switch (overlay.animationDirection) {
            case "left":
                return {
                    initial: { x: 1000, opacity: 0.65 },
                    animate: { x: 0, opacity: 1 },
                };
            case "up":
            default:
                return {
                    initial: { y: 1000, opacity: 0.65 },
                    animate: { y: 0, opacity: 1 },
                };
        }
    };

    return (
        <OverlayContext.Provider value={value}>
            {items.map((overlay) => (
                <div
                    key={overlay.id}
                    className={`fixed inset-0 z-50  ${
                        overlay?.popup
                            ? " mx-4 my-1/4 rounded-2xl overflow-hidden"
                            : ""
                    }`}
                >
                    <motion.div
                        {...getAnimationProps(overlay)}
                        transition={{
                            duration: overlay?.animate ? 0.5 : 0,
                            type: "spring",
                            mass: 0.1,
                        }}
                        className="flex flex-col h-full"
                    >
                        {renderPanel(overlay)}
                    </motion.div>
                    <div
                        onClick={removeCurrentOverlay}
                        className="fixed inset-0 flex flex-col cursor-pointer"
                    />
                    <div
                        className="fixed inset-0 bg-black flex flex-col"
                        style={{ opacity: 0.4, zIndex: -10 }}
                    />
                </div>
            ))}

            {props.children}
        </OverlayContext.Provider>
    );
};

export default OverlayProvider;
