import { SodexoOrderCreatedDTO } from "./../types/dtos/sodexoOrderCreatedDTO";
import { paymentOrderDTO } from "./../types/dtos/paymentOrder";
import Service from "./Service";

function createSodexoOrder(paymentOrderDTO: paymentOrderDTO) {
    return Service.api.post<SodexoOrderCreatedDTO>(
        "/v1/api/sodexo-order",
        paymentOrderDTO
    );
}

const exported = { createSodexoOrder };

export default exported;
