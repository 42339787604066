import axios, { AxiosRequestConfig } from "axios";
import RefreshTokenService from "./RefreshTokenService";

const errors: string[] = [];
const success: string[] = [];

// @ts-ignore
const IAM_URL = process.env.REACT_APP_IAM_SERVER;
// @ts-ignore
const API_URL = process.env.REACT_APP_RESOURCE_SERVER;
// @ts-ignore
const GO_API_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({ baseURL: API_URL });
const goapi = axios.create({ baseURL: GO_API_URL });
const iam = axios.create({ baseURL: IAM_URL });

[api].forEach((instance) => {
    instance.interceptors.request.use(async (baseConfiguration: any) => {
        return {
            ...baseConfiguration,
            ...(await config()),
        };
    });

    instance.interceptors.response.use(
        (res) => res,
        async (error) => {
            if (error?.response?.status == 401) {
                const refreshToken = localStorage.getItem("REFRESH_TOKEN");
                if (refreshToken) {
                    localStorage.removeItem("ACCESS_TOKEN");
                    localStorage.removeItem("REFRESH_TOKEN");

                    const token = await RefreshTokenService.post(refreshToken);

                    if (token) {
                        const { access_token, refresh_token } = token;

                        try {
                            localStorage.setItem("ACCESS_TOKEN", access_token);
                            localStorage.setItem(
                                "REFRESH_TOKEN",
                                refresh_token
                            );

                            error.config.headers[
                                "Authorization"
                            ] = `Bearer ${access_token}`;

                            return axios.request(error.config);
                        } catch {
                            localStorage.removeItem("ACCESS_TOKEN");
                            localStorage.removeItem("REFRESH_TOKEN");
                        }
                    } else {
                        localStorage.removeItem("ACCESS_TOKEN");
                        localStorage.removeItem("REFRESH_TOKEN");
                    }
                }
            }
            return Promise.reject(error);
        }
    );
});

[goapi].forEach((instance) => {
    instance.interceptors.request.use(async (baseConfiguration: any) => {
        return {
            ...baseConfiguration,
            ...(await config()),
        };
    });

    instance.interceptors.response.use(
        (res) => res,
        async (error) => {
            if (error?.response?.status == 401) {
                const refreshToken = localStorage.getItem("REFRESH_TOKEN");
                if (refreshToken) {
                    localStorage.removeItem("ACCESS_TOKEN");
                    localStorage.removeItem("REFRESH_TOKEN");

                    const token = await RefreshTokenService.post(refreshToken);

                    if (token) {
                        const { access_token, refresh_token } = token;

                        try {
                            localStorage.setItem("ACCESS_TOKEN", access_token);
                            localStorage.setItem(
                                "REFRESH_TOKEN",
                                refresh_token
                            );

                            error.config.headers[
                                "Authorization"
                            ] = `Bearer ${access_token}`;

                            return axios.request(error.config);
                        } catch {
                            localStorage.removeItem("ACCESS_TOKEN");
                            localStorage.removeItem("REFRESH_TOKEN");
                        }
                    } else {
                        localStorage.removeItem("ACCESS_TOKEN");
                        localStorage.removeItem("REFRESH_TOKEN");
                    }
                }
            }
            return Promise.reject(error);
        }
    );
});

async function config(): Promise<Partial<AxiosRequestConfig>> {
    const access_token = localStorage.getItem("ACCESS_TOKEN");

    return {
        headers: {
            Authorization: `bearer ${access_token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
}

const Service = {
    iam,
    api,
    goapi,
    errors,
    success,
    config,
    IAM_URL,
    API_URL,
};

export default Service;
