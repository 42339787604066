import { Capacitor } from "@capacitor/core";
import React from "react";
import { useHistory, useParams } from "react-router";
import Box from "../../../../../../components/box";
import Button from "../../../../../../components/button";
import { CompanyRouteParams } from "../../../../../../types/params/company-route";
import { ReactComponent as FoodizLogo } from "../../assets/foodiz.svg";
import ProgressBar from "../../../../components/progress-bar";
import "./style.css";
import { SignPageProps } from "./props";

const plat = Capacitor.platform;

const SignPage = ({
    children,
    className = "",
    showBackButton = true,
    showProgressBar = true,
}: SignPageProps) => {
    //Attributes
    const history = useHistory();
    const { companyId: paramCompanyId } = useParams<CompanyRouteParams>();
    let completed = 0;

    switch (history.location.pathname) {
        case `/${paramCompanyId}/sign-up/name`:
            completed = 33;
            break;
        case `/${paramCompanyId}/sign-up/phone`:
            completed = 66;
            break;
        case `/${paramCompanyId}/sign-up/password`:
            completed = 100;
            break;
        case `/${paramCompanyId}/forgot-password/email`:
            completed = 50;
            break;
        case `/${paramCompanyId}/forgot-password/confirmation`:
            completed = 100;
            break;
        default:
            break;
    }

    //Render
    return (
        <div
            className={`bg-light-beige sm:bg-sign-background bg-cover flex flex-1 flex-col sm:items-center sm:justify-center overflow-hidden ${className}`}
        >
            <Box className="flex flex-col pt-0 overflow-x-hidden">
                {showProgressBar && (
                    <ProgressBar
                        completed={completed}
                        className={`sm:mb-auto transform -translate-x-1 sm:-mx-8 rounded-2xl  ${
                            history.location.pathname ===
                            (`/${paramCompanyId}/sign-in` ||
                                `/${paramCompanyId}/forgot-password`)
                                ? "hidden"
                                : ""
                        }`}
                        style={{ paddingTop: plat === "ios" ? 30 : 0 }}
                    />
                )}
                <Button
                    className={`${
                        showBackButton
                            ? "fixed z-50 ml-4 md:ml-0 mt-4 outline-none md:mt-18"
                            : "hidden"
                    }`}
                    back
                    onClick={history.goBack}
                    style={{ marginTop: plat === "ios" ? 46 : 32 }}
                />
                <div className="page-children w-full p-4 pt-0 overflow-y-scroll scrolling-touch md:mt-16">
                    <div className="md:mt-0 mt-16 pb-12 flex justify-center">
                        <FoodizLogo />
                    </div>
                    {children}
                </div>
                {/* Adding payments*/}
            </Box>
        </div>
    );
};

export default SignPage;
