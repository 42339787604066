/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    ForgotPasswordReqDTO,
    UserResDTO,
    UserPagingResDTO,
    GetUsersParams,
    UserReqCreateDTO,
    UserReqUpdateDTO,
    ConfirmEmailDTO,
    ResendEmailDTO,
} from "../../new-types";
import { customInstance } from ".././config";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createForgotPassword = (
    forgotPasswordReqDTO: ForgotPasswordReqDTO
) => {
    return customInstance<void>({
        url: `/v1/api/forgot-password`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: forgotPasswordReqDTO,
    });
};

export type CreateForgotPasswordMutationResult = NonNullable<
    Awaited<ReturnType<typeof createForgotPassword>>
>;
export type CreateForgotPasswordMutationBody = ForgotPasswordReqDTO;
export type CreateForgotPasswordMutationError = unknown;

export const useCreateForgotPassword = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createForgotPassword>>,
        TError,
        { data: ForgotPasswordReqDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createForgotPassword>>,
        { data: ForgotPasswordReqDTO }
    > = (props) => {
        const { data } = props ?? {};

        return createForgotPassword(data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createForgotPassword>>,
        TError,
        { data: ForgotPasswordReqDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const getProfile = (signal?: AbortSignal) => {
    return customInstance<UserResDTO>({
        url: `/v1/api/profile`,
        method: "get",
        signal,
    });
};

export const getGetProfileQueryKey = () => [`/v1/api/profile`];

export type GetProfileQueryResult = NonNullable<
    Awaited<ReturnType<typeof getProfile>>
>;
export type GetProfileQueryError = unknown;

export const useGetProfile = <
    TData = Awaited<ReturnType<typeof getProfile>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<typeof getProfile>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetProfileQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfile>>> = ({
        signal,
    }) => getProfile(signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getProfile>>,
        TError,
        TData
    >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<
        TData,
        TError
    > & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const getUsers = (params?: GetUsersParams, signal?: AbortSignal) => {
    return customInstance<UserPagingResDTO>({
        url: `/v1/api/users`,
        method: "get",
        params,
        signal,
    });
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => [
    `/v1/api/users`,
    ...(params ? [params] : []),
];

export type GetUsersQueryResult = NonNullable<
    Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = unknown;

export const useGetUsers = <
    TData = Awaited<ReturnType<typeof getUsers>>,
    TError = unknown
>(
    params?: GetUsersParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getUsers>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
        signal,
    }) => getUsers(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof getUsers>>, TError, TData>(
        { queryKey, queryFn, ...queryOptions }
    ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const createUser = (userReqCreateDTO: UserReqCreateDTO) => {
    return customInstance<UserResDTO>({
        url: `/v1/api/users`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: userReqCreateDTO,
    });
};

export type CreateUserMutationResult = NonNullable<
    Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = UserReqCreateDTO;
export type CreateUserMutationError = unknown;

export const useCreateUser = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createUser>>,
        TError,
        { data: UserReqCreateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createUser>>,
        { data: UserReqCreateDTO }
    > = (props) => {
        const { data } = props ?? {};

        return createUser(data);
    };

    return useMutation<
        Awaited<ReturnType<typeof createUser>>,
        TError,
        { data: UserReqCreateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const getUserById = (userId: string, signal?: AbortSignal) => {
    return customInstance<UserResDTO>({
        url: `/v1/api/users/${userId}`,
        method: "get",
        signal,
    });
};

export const getGetUserByIdQueryKey = (userId: string) => [
    `/v1/api/users/${userId}`,
];

export type GetUserByIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getUserById>>
>;
export type GetUserByIdQueryError = unknown;

export const useGetUserById = <
    TData = Awaited<ReturnType<typeof getUserById>>,
    TError = unknown
>(
    userId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getUserById>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({
        signal,
    }) => getUserById(userId, signal);

    const query = useQuery<
        Awaited<ReturnType<typeof getUserById>>,
        TError,
        TData
    >({
        queryKey,
        queryFn,
        enabled: !!userId,
        ...queryOptions,
    }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryKey;

    return query;
};

export const updateUserById = (
    userId: string,
    userReqUpdateDTO: UserReqUpdateDTO
) => {
    return customInstance<UserResDTO>({
        url: `/v1/api/users/${userId}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: userReqUpdateDTO,
    });
};

export type UpdateUserByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateUserById>>
>;
export type UpdateUserByIdMutationBody = UserReqUpdateDTO;
export type UpdateUserByIdMutationError = unknown;

export const useUpdateUserById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateUserById>>,
        TError,
        { userId: string; data: UserReqUpdateDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateUserById>>,
        { userId: string; data: UserReqUpdateDTO }
    > = (props) => {
        const { userId, data } = props ?? {};

        return updateUserById(userId, data);
    };

    return useMutation<
        Awaited<ReturnType<typeof updateUserById>>,
        TError,
        { userId: string; data: UserReqUpdateDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const deleteUserById = (userId: string) => {
    return customInstance<UserResDTO>({
        url: `/v1/api/users/${userId}`,
        method: "delete",
    });
};

export type DeleteUserByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteUserById>>
>;

export type DeleteUserByIdMutationError = unknown;

export const useDeleteUserById = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteUserById>>,
        TError,
        { userId: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteUserById>>,
        { userId: string }
    > = (props) => {
        const { userId } = props ?? {};

        return deleteUserById(userId);
    };

    return useMutation<
        Awaited<ReturnType<typeof deleteUserById>>,
        TError,
        { userId: string },
        TContext
    >(mutationFn, mutationOptions);
};
export const confirmEmail = (confirmEmailDTO: ConfirmEmailDTO) => {
    return customInstance<void>({
        url: `/v1/api/confirm-email`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: confirmEmailDTO,
    });
};

export type ConfirmEmailMutationResult = NonNullable<
    Awaited<ReturnType<typeof confirmEmail>>
>;
export type ConfirmEmailMutationBody = ConfirmEmailDTO;
export type ConfirmEmailMutationError = unknown;

export const useConfirmEmail = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof confirmEmail>>,
        TError,
        { data: ConfirmEmailDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof confirmEmail>>,
        { data: ConfirmEmailDTO }
    > = (props) => {
        const { data } = props ?? {};

        return confirmEmail(data);
    };

    return useMutation<
        Awaited<ReturnType<typeof confirmEmail>>,
        TError,
        { data: ConfirmEmailDTO },
        TContext
    >(mutationFn, mutationOptions);
};
export const resendEmailConfirmation = (resendEmailDTO: ResendEmailDTO) => {
    return customInstance<void>({
        url: `/v1/api/resend-email`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: resendEmailDTO,
    });
};

export type ResendEmailConfirmationMutationResult = NonNullable<
    Awaited<ReturnType<typeof resendEmailConfirmation>>
>;
export type ResendEmailConfirmationMutationBody = ResendEmailDTO;
export type ResendEmailConfirmationMutationError = unknown;

export const useResendEmailConfirmation = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof resendEmailConfirmation>>,
        TError,
        { data: ResendEmailDTO },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof resendEmailConfirmation>>,
        { data: ResendEmailDTO }
    > = (props) => {
        const { data } = props ?? {};

        return resendEmailConfirmation(data);
    };

    return useMutation<
        Awaited<ReturnType<typeof resendEmailConfirmation>>,
        TError,
        { data: ResendEmailDTO },
        TContext
    >(mutationFn, mutationOptions);
};
